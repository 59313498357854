import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Headers from "../Components/HeaderPrivate";
import API_URL from "../const";
import Toast from "../Components/Toast/Toast";
import { Modal } from "flowbite-react";
import Spinner from "../Components/Spinner/Spinner";
import ToastError from "../Components/Toast/ToastError";
import { PDFDocument } from 'pdf-lib';
import Modalcomponent from "./NewsLetter/Modal";
import { ModalConfirmComponent } from "../Components/ModalConfirm";










function DetailPage({ empresaData }) {

  const navigate = useNavigate();




  console.log(empresaData.length);

  if (empresaData.length == 'undefined') {
    localStorage.removeItem("nombre");
    localStorage.removeItem("idRol_fk");
    localStorage.removeItem("id_usuario");
    localStorage.removeItem("id_empresasFk");
    localStorage.removeItem("token");
    navigate("/login");
  }


  const id_empresasFk = localStorage.getItem("id_empresasFk");
  const token = localStorage.getItem("token");
  console.log(token);
  console.log(id_empresasFk);
  const nombreUsuario = localStorage.getItem("nombre");
  const [isOwner, setIsOwner] = useState(false);
  const [empresas, setEmpresas] = useState(empresaData);
  const [image, setImage] = useState(empresaData);
  const [comentarios, setcomentarios] = useState('');
  const [openModalPdf, setOpenModalPdf] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [url, setUrl] = useState('');
  const [review, setReview] = useState({
    autor: '',
    descripcion: '',
    idEmpresa: '',
  });

  const [error, setError] = useState(null);
  const [IsOpenSpinner, setIsOpenSpinner] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [msg, setMsg] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalPerson, setOpenModalPerson] = useState(false);
  const [person, setPerson] = useState(false);
  const [id, setId] = useState('');
  const [active, setActive] = useState('aboutus')
  const [userData, setUserData] = useState('')


  const [formData, setFormData] = useState({
    id_empresa_fk: null,
    imagen_url: null, // Changed to null to hold the file object
  });
  const [formDataPerson, setFormDataPerson] = useState({
    idEmpresa: null,
    nombre: null,
    apellidoMaterno: null,
    apellidoPaterno: null,
    email: null,
    cargo: null,
    numero: null,
    empresa: null,
    imagen: null

  });
  const handleChangePerson = (e) => {
    const { name, value } = e.target;
    setFormDataPerson(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setReview(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleLogout = () => {
    localStorage.removeItem("nombre");
    localStorage.removeItem("idRol_fk");
    navigate("/");
  };
  const handleStatus = async (e) => {


    e.preventDefault();

    const formDataToSend = {
      nombre: "Primer Contacto con" + empresas[0].nombre,
      descripcion: "Creacion de la primera instancia de contacto",
      idempresa1: parseInt(id_empresasFk),
      idempresa2: empresas[0].id_empresa,
      typeConexion: 2
    }

    console.log(formDataToSend)
    try {
      const response = await fetch(`${API_URL}insertMatch.php`, {
        method: "POST",
        body: JSON.stringify(formDataToSend),
      });
      const data = await response.json();

      if (response.ok) {

        // Handle success
        console.log("Status registrado exitosamente.");
        window.open(empresas[0].whatsapp, '_blank')
      } else {
        // Handle error
        console.error("Error al registrar el newsletter:", data.error);
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
    }
  };
 
  const getImagenEmpresa = () => {
    console.log("Entro a obtener datos")
    console.log(empresas)
    fetch(`${API_URL}getImagesDetail.php?id_empresa=${empresas[0].id_empresa}`)
    .then((response) => {
      if (response.status === 401) {
        // Si obtenemos un 401, redirigimos al login
        localStorage.removeItem("token");
        navigate('/login');

        return; // Salimos de la función para evitar procesar la respuesta
      }

      // Continuamos si el estado no es 401
      return response.text();
    })
      .then((text) => {
        const startIndex = text.indexOf("[{");
        const jsonResponse =
          startIndex !== -1 ? text.substring(startIndex) : text;

        try {
          const responseData = JSON.parse(jsonResponse);
          console.log("Respuesta del servidor:", empresas);
          console.log("Respuesta del servidor:", responseData);

          setImage(responseData);
        } catch (error) {
          console.error("Error al analizar JSON:", error);


        }
      })
      .catch((error) => {
        console.error("Error del servidor:", error);


      });
  }
  const getReviewsEmpresa = () => {
    console.log("Entro a obtener datos")

    fetch(`${API_URL}getReviewsEnterprice.php?id_empresa=${empresas[0].id_empresa}`)
    .then((response) => {
      if (response.status === 401) {
        // Si obtenemos un 401, redirigimos al login
        localStorage.removeItem("token");
        navigate('/login');

        return; // Salimos de la función para evitar procesar la respuesta
      }

      // Continuamos si el estado no es 401
      return response.text();
    })
      .then((text) => {
        const startIndex = text.indexOf("[{");
        const jsonResponse =
          startIndex !== -1 ? text.substring(startIndex) : text;
        try {
          
          const responseData = JSON.parse(jsonResponse);
          setcomentarios(responseData);
          console.log(comentarios)
        } catch (error) {
          console.error("Error al analizar JSON:", error);

        }
      })
      .catch((error) => {
        console.error("Error del servidor:", error);
      });
  }
  const insertReview = async (e) => {
    e.preventDefault();
    
   


    const reviews ={
      autor: nombreUsuario,
      idEmpresa: empresas[0].id_empresa,
      descripcion:review.descripcion
    }
  
    console.log(reviews);

    setIsOpenSpinner(true);
    try {
      const response = await fetch(`${API_URL}insertReview.php`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${token}`,
          

        },
        body: new URLSearchParams({ ...reviews }),
      });
      console.log(response)
      const text = await 
      console.log("Respuesta del servidor (texto):", text);
      console.log(JSON.stringify(review))
      const startIndex = text.indexOf("[{");
      const jsonResponse =
        startIndex !== -1 ? text.substring(startIndex) : text;
      try {
        if (response.status === 401) {
          // Si obtenemos un 401, redirigimos al login
          localStorage.removeItem("token");
          navigate('/login');
        
          return; // Salimos de la función para evitar procesar la respuesta
        }
        const responseData = JSON.parse(jsonResponse);
        console.log("Respuesta del servidor:", responseData);
        if (response.ok) {
          console.log(response);
          setIsOpenSpinner(false);

          setShowToast(true);
          setReview({
            autor: '',
            descripcion: '',
            idEmpresa: '',
          })
          getReviewsEmpresa();

          setTimeout(() => {
            setShowToast(false);
          }, 5000);
        } else {
          console.error("Error del servidor:", response.statusText);
          setError(response.statusText || "Error desconocido");
          setIsOpenSpinner(false);
        }
      } catch (error) {
        console.error("Error al analizar JSON:", error);
        setError("Error de formato en la respuesta del servidor");
        setIsOpenSpinner(false);
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
      setError("Error de red: " + error.message);
    }
  };
  const deleteImageDetail = (id) => {
    fetch(`${API_URL}deleteImageDetail.php?id=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,

      },
    })
      .then(response => {
        if (response.status === 401) {
          // Si obtenemos un 401, redirigimos al login
          localStorage.removeItem("token");
          navigate('/login');
        
          return; // Salimos de la función para evitar procesar la respuesta
        }
        if (response.ok) {
          getImagenEmpresa();
          console.log('Imagen eliminado correctamente');
        } else {
          console.error('Error al eliminar el boletín');

        }
      })
      .catch(error => console.error('Error de red:', error));
  };
  const deleteColaborador = (id) => {
    fetch(`${API_URL}deleteColaborador.php?id=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,

      },
    })
      .then(response => {
    
          if (response.status === 401) {
            // Si obtenemos un 401, redirigimos al login
            localStorage.removeItem("token");
            navigate('/login');
          
            return; // Salimos de la función para evitar procesar la respuesta
          }
    
         
        if (response.ok) {
          getColaboradores();
          setOpenModalConfirm(false);
          console.log('Colaborador eliminado correctamente');
        } else {
          setMsg(response.error);
        setShowToastError(true);
        setTimeout(() => {
          setShowToastError(false);
        }, 5000);
        setOpenModal(false);
          console.error('Error al eliminar el colaborador');

        }
      })
      .catch(error => console.error('Error de red:', error));
  };
  const openModals = () => {
    setOpenModal(true);
  };
  const handleSubmit = async (e) => {
    setOpenModal(false);
    setFormData({
      id_empresa_fk: null,
      imagen_url: null, // Changed to null to hold the file object
    });
    e.preventDefault();
    setIsOpenSpinner(true);


    const formDataToSend = new FormData();
    formDataToSend.append("id_empresa_fk", empresas[0].id_empresa);
    formDataToSend.append("imagen_url", formData.imagen_url);
    /*console.log(formDataToSend)*/
    try {
      const response = await fetch(`${API_URL}insertImageDetail.php`, {
        method: "POST",
        body: formDataToSend,
      });
      const data = await response.json();
      if (response.status === 401) {
        // Si obtenemos un 401, redirigimos al login
        localStorage.removeItem("token");
        navigate('/login');
      
        return; // Salimos de la función para evitar procesar la respuesta
      }
      if (response.ok) {
        setIsOpenSpinner(false);
        setShowToast(true);

        setTimeout(() => {
          setShowToast(false);
        }, 5000);
        // Handle success
        console.log("Newsletter registrado exitosamente.");
      } else {
        // Handle error
        console.error("Error al registrar el newsletter:", data.error);
        setMsg(data.error);
        setShowToastError(true);
        setTimeout(() => {
          setShowToastError(false);
        }, 5000);
        setOpenModal(false);
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
      setOpenModal(false);
    }
  };




  const getColaboradores = () => {
    console.log("Entro a obtener datos")

    fetch(`${API_URL}getColaboradores.php?idEmpresa=${empresas[0].id_empresa}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,

      },
    })
    .then((response) => {
      if (response.status === 401) {
        // Si obtenemos un 401, redirigimos al login
        localStorage.removeItem("token");
        navigate('/login');

        return; // Salimos de la función para evitar procesar la respuesta
      }

      // Continuamos si el estado no es 401
      return response.text();
    })
      .then((text) => {
        const startIndex = text.indexOf("[{");
        const jsonResponse =
          startIndex !== -1 ? text.substring(startIndex) : text;
        try {
          const responseData = JSON.parse(jsonResponse);

          setUserData(responseData);
          console.log(userData)
        } catch (error) {
          console.error("Error al analizar JSON:", error);

        }
      })
      .catch((error) => {
        console.error("Error del servidor:", error);
      });
  }




  const handleFilesChange = (e) => {
    const { name, files } = e.target;
    setFormDataPerson({ ...formDataPerson, [name]: files[0] }); // Assuming only single file selection
  };
  const handleSubmitPerson = async (e) => {
    
    e.preventDefault();
    setIsOpenSpinner(true);
    setFormDataPerson(prevState => ({
      ...prevState,
      idEmpresa: empresas[0].id_empresa,
      empresa: empresas[0].nombre
    }));
    const formDataToSend = new FormData();
    formDataToSend.append('idEmpresa', formDataPerson.idEmpresa);
    formDataToSend.append('imagen', formDataPerson.imagen);
    formDataToSend.append('nombre', formDataPerson.nombre);
    formDataToSend.append('apellidoMaterno', formDataPerson.apellidoMaterno);
    formDataToSend.append('apellidoPaterno', formDataPerson.apellidoPaterno);
    formDataToSend.append('email', formDataPerson.email);
    formDataToSend.append('cargo', formDataPerson.cargo);
    formDataToSend.append('numero', formDataPerson.numero);
    formDataToSend.append('empresa', formDataPerson.empresa);

    try {
      const response = await fetch(`${API_URL}insertColaborador.php`, {
        method: "POST",
        body: formDataToSend,

      });
  
        if (response.status === 401) {
          // Si obtenemos un 401, redirigimos al login
          localStorage.removeItem("token");
          navigate('/login');
  
          return; // Salimos de la función para evitar procesar la respuesta
        }
  
      
      console.log(response)
      if (response.ok) {
        setIsOpenSpinner(false);
        setShowToast(true);
        getColaboradores();
        setTimeout(() => {
          setShowToast(false);
        }, 5000);
        setFormDataPerson({
          idEmpresa: null,
          imagen: null, // Changed to null to hold the file object
          nombre: null,
          apellidoMaterno: null,
          apellidoPaterno: null,
          email: null,
          cargo: null,
          numero: null,
          empresa: null
        });
        setOpenModalPerson(false);
        // Handle success
        console.log("Personal registrado exitosamente.");
      } else {
        // Handle error
        console.error("Error al registrar el newsletter:");
        setMsg("error");
        setIsOpenSpinner(false);
        setShowToastError(true);
        setTimeout(() => {
          setShowToastError(false);
        }, 5000);
        setOpenModalPerson(false);
      }
    } catch (error) {
      console.error("Error en la solicitud:", error.message);

      setMsg(`Error: ${error.message}`);
      setIsOpenSpinner(false);
      setShowToastError(true);
      setTimeout(() => {
        setShowToastError(false);
      }, 5000);
      setOpenModalPerson(false);
    }

  };

  const handleUpdatePerson = async (e) => {
    
    e.preventDefault();
    setIsOpenSpinner(true);
    setFormDataPerson(prevState => ({
      ...prevState,
      idEmpresa: empresas[0].id_empresa,
      empresa: empresas[0].nombre
    }));
    const formDataToSend = new FormData();
    formDataToSend.append('idEmpresa', formDataPerson.idEmpresa);
    formDataToSend.append('imagen', formDataPerson.imagen);
    formDataToSend.append('nombre', formDataPerson.nombre);
    formDataToSend.append('apellidoMaterno', formDataPerson.apellidoMaterno);
    formDataToSend.append('apellidoPaterno', formDataPerson.apellidoPaterno);
    formDataToSend.append('email', formDataPerson.email);
    formDataToSend.append('cargo', formDataPerson.cargo);
    formDataToSend.append('numero', formDataPerson.numero);
    formDataToSend.append('empresa', formDataPerson.empresa);

    try {
      const response = await fetch(`${API_URL}updateColaborador.php`, {
        method: "POST",
        body: formDataToSend,

      });
      console.log(response)
      if (response.status === 401) {
        // Si obtenemos un 401, redirigimos al login
        localStorage.removeItem("token");
        navigate('/login');
      
        return; // Salimos de la función para evitar procesar la respuesta
      }
      if (response.ok) {
        setIsOpenSpinner(false);
        setShowToast(true);
        getColaboradores();
        setTimeout(() => {
          setShowToast(false);
        }, 5000);
        setFormDataPerson({
          idEmpresa: null,
          imagen: null, // Changed to null to hold the file object
          nombre: null,
          apellidoMaterno: null,
          apellidoPaterno: null,
          email: null,
          cargo: null,
          numero: null,
          empresa: null
        });
        setOpenModalPerson(false);
        // Handle success
        console.log("Personal registrado exitosamente.");
      } else {
        // Handle error
        console.error("Error al registrar el newsletter:");
        setMsg("error");
        setIsOpenSpinner(false);
        setShowToastError(true);
        setTimeout(() => {
          setShowToastError(false);
        }, 5000);
        setOpenModalPerson(false);
      }
    } catch (error) {
      console.error("Error en la solicitud:", error.message);

      setMsg(`Error: ${error.message}`);
      setIsOpenSpinner(false);
      setShowToastError(true);
      setTimeout(() => {
        setShowToastError(false);
      }, 5000);
      setOpenModalPerson(false);
    }

  };
  const handleFileChange = (e) => {
    // Handling input changes for file inputs
    const { name, files } = e.target;
    setFormData({ ...formData, [name]: files[0] }); // Assuming only single file selection
  };
  const idRol_fk = localStorage.getItem("idRol_fk");
  console.log(idRol_fk)
  useEffect(() => {
    if (id_empresasFk == null || id_empresasFk == undefined || id_empresasFk == "") {
      navigate("/login");
    } else {
      setEmpresas(empresaData);
      if ( parseInt(id_empresasFk) === parseInt(empresas[0].id_empresa) && parseInt(idRol_fk) !== 4  ) {
    
        setIsOwner(true)
      }
    }
  }, [empresaData]);
  const [searchTerm, setSearchTerm] = useState("");
  const [resultados, setResultados] = useState([]);
  const [idConfirm ,setIdConfirm] = useState("");
  const [save, setSave] = useState(false);

  
  const handleSave = () => {

    const inputElement = document.querySelector('.busqueda-input');
    const key = inputElement.getAttribute('key');

    setId(key);

    // Actualiza el estado con los nuevos productos

    setSearchTerm('');
    setSave(false);



  };


  return (
    <section className="fixed overflow-auto bg-gray-100  top-10 left-0 bottom-0 right-0">
         <Modalcomponent pdf={url} openModal={openModalPdf} setOpenModal={setOpenModalPdf} />
    <ModalConfirmComponent onSave={()=>deleteColaborador(idConfirm)} openModal={openModalConfirm} setOpenModal={setOpenModalConfirm}></ModalConfirmComponent>
      {" "}
      <Spinner isOpen={IsOpenSpinner}></Spinner>{" "}
      {showToast && (
        <Toast isOpen={true} title="Éxito" text="Agregado con exito" />
      )}{" "}
      {showToastError && <ToastError isOpen={true} title="Error" text={msg} />}{" "}
      <Modal
        show={openModal}
        onClose={() => {
          setOpenModal(false);
          setFormData({
            id_empresa_fk: null,
            imagen_url: null, // Changed to null to hold the file object
          });
        }}
      >
        <Modal.Header className="fondo-rojo text-white"><i class='bx bx-cloud-upload'></i>&nbsp;Carga tus recursos corporativos</Modal.Header>
        <Modal.Body>
          <div className=" w-full ">
            <p className="text-base text-sm mb-2">Puedes cargar aqui tus images ó presentación (Máximo 5 archivos)</p>
            <input
              onChange={handleFileChange}
              name="imagen_url"
              id="file-upload"
              accept="application/pdf, image/png, image/jpeg, image/jpg"
              type="file"
              class=""
            />
            <button
              onClick={
                formData.imagen_url !== null &&
                  formData.imagen_url !== undefined
                  ? handleSubmit
                  : null
              }
              className={`w-full bg-red-600 my-2 h-10 justify-center cursor-pointer items-center flex ${formData.imagen_url !== null &&
                formData.imagen_url !== undefined
                ? "opacity-100"
                : "opacity-40"
                } rounded-md text-white`}
            >
              <i class='bx bx-save'></i>&nbsp;Cargar
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={openModalPerson}
        onClose={() => {
          setOpenModalPerson(false);
          setPerson('');
        }}
      >
        <Modal.Header className="fondo-rojo text-white"><i class='bx bx-user'></i>&nbsp;Enrrola a tus colaboradores</Modal.Header>
        <Modal.Body>
          <div className=" w-full ">
            <div className="flex">
              <div className="w-full mr-2">
                <label htmlFor="nombre" className="block text-sm font-medium leading-6 text-gray-900">Nombre</label>
                <input
                  id="nombre"
                  value={formDataPerson.nombre}
                  onChange={handleChangePerson}
                  name="nombre"
                  placeholder="Agrega tu nombre"
                  type="text"
                  className="w-full border-gray-300 border rounded-md mb-1"
                  
                />
              </div>
              <div className="w-full">
                <label htmlFor="foto" className="block text-sm font-medium leading-6 text-gray-900">Foto</label>
                <input
                  onChange={handleFilesChange}

                  id="file-upload"
                  accept="application/pdf, image/png, image/jpeg, image/jpg"
                  name="imagen"
                  placeholder="Agrega tu url de imagen"
                  type="file"
                  className="w-full border-gray-300 border rounded-md mb-1"
                />
              </div>
            </div>

            <div className="flex">
              <div className="w-full mr-2">
                <label htmlFor="apellidoPaterno" className="block text-sm font-medium leading-6 text-gray-900">Apellido Paterno</label>
                <input
                  value={formDataPerson.apellidoPaterno}
                  onChange={handleChangePerson}
                  placeholder="Apellido paterno"
                  name="apellidoPaterno"
                  type="text"
                  className="w-full border-gray-300 border rounded-md mb-1"
                />
              </div>
              <div className="w-full">
                <label htmlFor="apellidoMaterno" className="block text-sm font-medium leading-6 text-gray-900">Apellido Materno</label>
                <input
                  value={formDataPerson.apellidoMaterno}
                  onChange={handleChangePerson}
                  placeholder="Apellido materno"
                  name="apellidoMaterno"
                  type="text"
                  className="w-full border-gray-300 border rounded-md mb-1"
                />
              </div>
            </div>
            <div className="flex">
              <div className="w-full mr-2">
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email</label>

                <input
                  value={formDataPerson.email}
                  onChange={handleChangePerson}
                  placeholder="Email"
                  name="email"
                  type="text"
                  className="w-full border-gray-300 border rounded-md mb-1"
                />
              </div>
              <div className="w-full">
                <label htmlFor="numero" className="block text-sm font-medium leading-6 text-gray-900">N&uacute;mero</label>

                <input
                  value={formDataPerson.numero}
                  onChange={handleChangePerson}
                  placeholder="Celular"
                  name="numero"
                  type="text"
                  className="w-full border-gray-300 border rounded-md mb-1"
                />
              </div>
            </div>
            <div>
              <label htmlFor="cargo" className="block text-sm font-medium leading-6 text-gray-900">Cargo</label>

              <input
                value={formDataPerson.cargo}
                onChange={handleChangePerson}
                placeholder="Cargo"
                name="cargo"
                type="text"
                className="w-full border-gray-300 border rounded-md mb-1"
              />
            </div>
            <div>
              <label htmlFor="Empresa" className="block text-sm font-medium leading-6 text-gray-900">Empresa</label>

              <input
                value={empresas[0].nombre}

                placeholder="Empresa"
                type="text"
                name="empresa"
                readOnly
                className="w-full border-gray-300 border rounded-md mb-1"
              />
            </div>
            {formDataPerson.estatus === 1 ?(
             <button
             onClick={
               Object.keys(formDataPerson).every(key => key === 'idEmpresa' || key === 'imagen' || key === 'empresa' || formDataPerson[key] !== '' && formDataPerson[key] !== null)
                 ? handleUpdatePerson
                 : null
             }
             className={`w-full bg-red-600 my-2 h-10 justify-center cursor-pointer items-center flex ${Object.keys(formDataPerson).every(key => key === 'idEmpresa' || key === 'empresa' || key === 'imagen' || formDataPerson[key] !== '' && formDataPerson[key] !== null)
               ? "opacity-100"
               : "opacity-40"
               } rounded-md text-white`}
           >
             <i class='bx bx-save'></i>&nbsp;Actualizar
           </button>
            ):(
              <button
             onClick={
               Object.keys(formDataPerson).every(key => key === 'idEmpresa' || key === 'empresa' || formDataPerson[key] !== '' && formDataPerson[key] !== null)
                 ? handleSubmitPerson
                 : null
             }
             className={`w-full bg-red-600 my-2 h-10 justify-center cursor-pointer items-center flex ${Object.keys(formDataPerson).every(key => key === 'idEmpresa' || key === 'empresa' || formDataPerson[key] !== '' && formDataPerson[key] !== null)
               ? "opacity-100"
               : "opacity-40"
               } rounded-md text-white`}
           >
             <i class='bx bx-save'></i>&nbsp;Enviar
           </button>
            )}
           
          </div>
        </Modal.Body>
      </Modal>
      <Headers />
      {typeof empresas !== 'undefined' && empresas.length !== 0 && empresas.length !== 0 ? (

        <div className="pb-10 ">
          <div className="md:p-4 p-1 md:mt-12 mt-10 relative">

            <img className="w-full h-[30vh] rounded-lg object-cover" src={`${API_URL}${empresas[0]?.portada}`} ></img>
            <div className="absolute bottom-[-20px] bg-white rounded-full shadow-md left-10">
              <img className="w-28 rounded-full" src={`${API_URL}${empresas[0]?.logo}`}></img>
            </div>
          </div>
          <div className="flex justify-between flex-wrap text-left mt-14 mb-4 xl:mb-1 mx-2 ">
            <div className=" w-full flex flex-wrap justify-between p-2">
              <h3 style={{ color: empresas[0].colorHex }} className={`text-[${empresas[0].colorHex}]  font-bold  text-2xl`}>{empresas[0].nombre}</h3>
              <p className="flex items-center text-sm text-gray-500"><i class='bx text-2xl bx-current-location mr-2' ></i>{empresas[0].direccion}</p>
            </div>

          </div>
          <div className="block md:flex items-center md:justify-between bg-white border border-gray-300 mx-2">

            <div className="md:w-3/4 w-full mt-2 md:mt-0 flex flex-wrap items-center md:justify-start justify-center">
              <div style={{ borderBottomColor: empresas[0].colorHex, color: active === 'aboutus' ? empresas[0].colorHex : '' }} onClick={() => setActive('aboutus')} className={`${active === 'aboutus' ? `text-[${empresas[0].colorHex}] border-"#24ac0c" border-b-2` : 'text-gray-500'} font-bold   h-full w-1/8 md:p-4 p-2  mx-4  cursor-pointer text-sm `}>Nosotros</div>
              <div style={{ borderBottomColor: empresas[0].colorHex, color: active === 'contact' ? empresas[0].colorHex : '' }} onClick={() => { setActive('contact'); getColaboradores(); }} className={`${active === 'contact' ? `text-[${empresas[0].colorHex}] border-"#24ac0c" border-b-2` : 'text-gray-500'} font-bold   h-full w-1/8  md:p-4 p-2 mx-4  cursor-pointer text-sm`}>Contacto</div>
              <div style={{ borderBottomColor: empresas[0].colorHex, color: active === 'objectsAtachments' ? empresas[0].colorHex : '' }} onClick={() => { setActive('objectsAtachments'); getImagenEmpresa(); }} className={`${active === 'objectsAtachments' ? `text-[${empresas[0].colorHex}] border-"#24ac0c" border-b-2` : 'text-gray-500'} font-bold h-full w-1/8  md:p-4 p-2 mx-4 cursor-pointer text-sm`}>Documentación</div>
              <div style={{ borderBottomColor: empresas[0].colorHex, color: active === 'reviews' ? empresas[0].colorHex : '' }} onClick={() => { setActive('reviews'); getReviewsEmpresa(); }} className={`${active === 'reviews' ? `text-[${empresas[0].colorHex}] border-"#24ac0c" border-b-2` : 'text-gray-500'} font-bold h-full w-1/8  md:p-4 p-2 mx-4 cursor-pointer text-sm`}>Reviews</div>

            </div>

            <div className="flex justify-end  m-2 p-2 md:m-0">
              <button Link={empresas[0].whatsapp} style={{ background: empresas[0].colorHex }} className={`flex items-center justify-center text-sm w-full text-white p-2 md:w-10 md:h-10 rounded-full mr-1`}>
                <a target="_blank" href={empresas[0].whatsapp}><i class='bx bxl-whatsapp text-3xl'></i></a>
              </button>
              <button Link={empresas[0].linkedin} style={{ background: empresas[0].colorHex }} className={`flex items-center justify-center text-sm w-full text-white p-2 md:w-10 md:h-10 rounded-full mr-1`}>
                <a target="_blank" href={empresas[0].linkedin ?? ''}><i class='bx bxl-linkedin text-3xl'></i></a>
              </button>
              <button Link={empresas[0].instagram} style={{ background: empresas[0].colorHex }} className={`flex items-center justify-center text-sm w-full text-white p-2 md:w-10 md:h-10 rounded-full mr-1`}>
                <a target="_blank" href={empresas[0].instagram}><i class='bx bxl-instagram text-3xl'></i></a>
              </button>
              <button Link={empresas[0].facebook} style={{ background: empresas[0].colorHex }} className={`flex items-center justify-center text-sm w-full text-white p-2 md:w-10 md:h-10 rounded-full mr-1`}>
                <a target="_blank" href={empresas[0].facebook}><i class='bx bxl-facebook text-3xl'></i></a>
              </button>
            </div>

          </div>
          <div className="text-sm p-2 ">
            {active === 'aboutus' && (
              <div className="text-left bg-white border border-gray-300 mt-1 p-5 rounded-md p-5">

                <div className="text-left bg-white border border-gray-300 mt-4 rounded-md p-5">
                  <label className="font-semibold">Mision</label>
                  <p className="mt-5 text-center font-semibold text-gray-500">"{empresas[0].mision}"</p>
                </div>
                <div className="text-left bg-white border border-gray-300 mt-4 rounded-md p-5">
                  <label className="font-semibold">Vision</label>
                  <p className="mt-5 text-center font-semibold text-gray-500">"{empresas[0].vision}"</p>
                </div>


              </div>
            )}
            {active === 'contact' && (
              <div className="xl:flex block w-full ">

                <div className="text-left xl:w-2/3 w-full bg-white border border-gray-300 mt-1 rounded-md md:p-5 p-0">

                  <div className="md:flex block justify-evenly w-full border-b border-gray-200">
                    <div className="text-left md:w-1/2 w-full m-2">
                      <h5 style={{ color: empresas[0].colorHex }} className={`text-[${empresas[0].colorHex}] `}>N&uacute;mero de tel&eacute;fono</h5>
                      <h5>{empresas[0].telefono}</h5>
                    </div>
                    <div className="text-left  md:w-1/2 w-full m-2">
                      <h5 style={{ color: empresas[0].colorHex }} className={`text-[${empresas[0].colorHex}] `}>Email</h5>
                      <h5>{empresas[0].email}</h5>
                    </div>
                  </div>
                  <div className="md:flex block justify-evenly w-full border-b border-gray-200">
                    <div className="text-left md:w-1/2 w-full m-2">
                      <h5 style={{ color: empresas[0].colorHex }} className={`text-[${empresas[0].colorHex}] `}>Encargado</h5>
                      <h5>{empresas[0].especialidad}</h5>
                    </div>
                    <div className="text-left md:w-1/2 w-full m-2 ">
                      <h5 style={{ color: empresas[0].colorHex }} className={`text-[${empresas[0].colorHex}] `}>Sitio web</h5>
                      <h5><a target="_blank" href={empresas[0].sitioWeb}>{empresas[0].sitioWeb}</a></h5>
                    </div>
                  </div>
                  <div className="md:flex block justify-evenly w-full border-b border-gray-200">
                    <div className="text-left md:w-1/2 w-full m-2">
                      <h5 style={{ color: empresas[0].colorHex }} className={`text-[${empresas[0].colorHex}] `}>Giro</h5>
                      <h5>{empresas[0].giro}</h5>
                    </div>
                    <div className="text-left md:w-1/2 w-full m-2">
                      <h5 style={{ color: empresas[0].colorHex }} className={`text-[${empresas[0].colorHex}] `}>Sede</h5>
                      <h5>{empresas[0].sede}</h5>
                    </div>
                  </div>
                  <div className="md:flex block justify-evenly w-full border-b border-gray-200">
                    <div className="text-left md:w-1/2 w-full m-2">
                      <h5 style={{ color: empresas[0].colorHex }} className={`text-[${empresas[0].colorHex}] `}>Especialidad</h5>
                      <h5>{empresas[0].especialidad}</h5>
                    </div>
                    <div className="text-left md:w-1/2 w-full m-2">
                      <h5 style={{ color: empresas[0].colorHex }} className={`text-[${empresas[0].colorHex}] `}>Tamaño</h5>
                      <h5>{empresas[0].tamanio}</h5>
                    </div>
                  </div>
                  <div className="md:flex block justify-evenly w-full border-b border-gray-200">

                    <div className="flex justify-start w-full my-10">
                      {Array.isArray(userData) && typeof userData !== 'undefined' && userData.length !== 0 && userData.length > 0 ? (
                        userData.map((user, index) => (
                          <>
                          <div className="relative">

                               {isOwner && (
                                <button onClick={()=>{setOpenModalConfirm(true); setIdConfirm(user.id)}} className="absolute m-2 bg-black flex justify-center text-white z-10 items-center h-7 w-7  rounded-full left-0 top-0">x</button>
                                 )}
                            <div key={user.id} onClick={() => { 
                              if(isOwner){
                                setOpenModalPerson(true);
                              }
                             
                              setFormDataPerson(prevState => ({
                                ...prevState,
                                
                                idEmpresa: empresas[0].id_empresa,
                                empresa: empresas[0].nombre,
                                nombre: user.nombre,
                                apellidoMaterno: user.apellidoMaterno,
                                apellidoPaterno: user.apellidoPaterno,
                                email: user.email,
                                cargo: user.cargo,
                                numero: user.numeroTelefonico,
                                imagen: user.urlPhoto,
                                estatus:1
                              }));
                            }} className="flex m-1 flex-col items-center my-3 p-2 transition-colors duration-300 transform border cursor-pointer rounded-xl hover:border-transparent group hover:bg-gray-100 dark:border-gray-700 dark:hover:border-transparent" >
                             {user.urlPhoto !== null && typeof user.urlPhoto !== 'undefined' &&  user.urlPhoto !== '' ? (
  <img
    className="object-cover w-32 h-32 rounded-full ring-4 ring-gray-300"
    src={`${API_URL}${user.urlPhoto}`}
    alt=""
  />
) : (
  <div style={{ backgroundColor: empresas[0].colorHex }} className="flex items-center justify-center w-32 h-32 bg-gray-300 rounded-full ring-4 ring-gray-300" >
    <span className="text-2xl font-semibold text-white uppercase">
      {user.nombre.charAt(0)}{user.apellidoPaterno.charAt(0)}
    </span>
  </div>
)}
                              <h2 className="mt-2 font-semibold text-gray-700 capitalize dark:text-white "> {user.nombre}&nbsp;{user.apellidoPaterno}&nbsp;{user.apellidoMaterno}</h2>
                              <h3 className="mt-2 text-gray-500 dark:text-gray-300 "> {user.cargo} </h3>
                              <h3 className="mt-2 text-gray-500 dark:text-gray-300 "> {user.numeroTelefonico} </h3>
                           </div>

 </div>
                            {index === userData.length - 1 && (
                              <>
                                {isOwner && (
                                  <div key={user.id} onClick={() => {setOpenModalPerson(true);
                                    setFormDataPerson(prevState => ({
                                      ...prevState,
                                      
                                      idEmpresa: empresas[0].id_empresa,
                                      empresa: '',
                                      nombre: '',
                                      apellidoMaterno: '',
                                      apellidoPaterno: '',
                                      email: '',
                                      cargo: '',
                                      numero: '',
                                      imagen: '',
                                      estatus:0
                                    }));
                                  }} className="flex m-1 flex-col justify-center items-center my-3 p-2 transition-colors duration-300 transform border cursor-pointer rounded-xl hover:border-transparent group hover:bg-gray-100 dark:border-gray-700 dark:hover:border-transparent">
                                    <i className='bx bx-plus bg-gray-100 p-3 rounded-full h-20 w-20 flex justify-center items-center text-3xl'></i>
                                    <h2 className="mt-2 font-semibold text-gray-700 capitalize dark:text-white">Añadir nuevo socio</h2>
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        ))) : (
                        <div className="w-full ">
                          <h2 className="text-center text-black item-center "><i class='bx text-3xl bx-search'></i> No se han enrolado colaboradores</h2>

                          {isOwner && (
                            <div onClick={() => setOpenModalPerson(true)} className="flex m-1 flex-col justify-center items-center my-3 p-2 transition-colors duration-300 transform border cursor-pointer rounded-xl hover:border-transparent group hover:bg-gray-100 dark:border-gray-700 dark:hover:border-transparent">
                              <i className='bx bx-plus bg-gray-100 p-3 rounded-full h-20 w-20 flex justify-center items-center text-3xl'></i>
                              <h2 className="mt-2 font-semibold text-gray-700 capitalize dark:text-white">Añadir nuevo socio</h2>
                            </div>
                          )}
                        </div>)}
                    </div>
                  </div>
                </div>
                <div className="text-left bg-white border xl:w-1/3 w-full border-gray-300 mt-1 rounded-md xl:ml-2 ml-0">
                  <iframe
                    className="rounded-md border-2"
                    title="Google Maps"
                    width="100%"
                    height="100%"
                    loading="lazy"
                    allowFullScreen
                    src={empresas[0].googleUrl}
                  ></iframe>
                </div>
              </div>
            )}
            {active === 'objectsAtachments' && (
              <div className="text-left flex flex-wrap bg-white border border-gray-300 mt-1 rounded-md p-5">
                {Array.isArray(image) && image.length > 0 ? (
                  <div className="text-left flex flex-wrap rounded-md ">
                     {image.map((item) => (
        <div className="relative m-2 " key={item.id}>
          {item.imagen_url ? (
  item.imagen_url.endsWith('.pdf') ? (
    <div className="w-60 h-60 rounded-md relative shadow-xl">
    <iframe 
    src={`${API_URL}/detalles/image/${item.imagen_url}`}
    className="object-cover w-60 h-60 rounded-md"
    style={{ width: "100%", height: "100%" // Escala el contenido para ajustarlo al contenedor
    }} // Asegura que la escala se aplique desde la esquina superior izquierda  }}
    frameborder="0">

    
  </iframe>
  <button style={{ backgroundColor: empresas[0].colorHex }} onClick={()=>{setOpenModalPdf(true); setUrl(`${API_URL}/detalles/image/${item.imagen_url}`)}} className=" absolute bottom-1 left-1 right-1 text-white rounded-md px-4 py-2">Visualizar</button>
  </div>
  ) : (
    <img className="w-full h-60 border border-gray-300 shadow-xl rounded-md" src={`${API_URL}/detalles/image/${item.imagen_url}`} alt={`imagen-${item.id}`} />
  )
) : (
  <div>No image available</div>
)}

          {isOwner && (
            <button onClick={() => deleteImageDetail(item.id)} className="absolute bg-gray-300 h-6 w-6 flex items-center rounded-full justify-center right-2 top-2 text-black">
              <i className="bx bx-x"></i>
            </button>
          )}
        </div>
      ))}

                  </div>
                ) : (
                  <div className="text-left bg-white border border-gray-300 mt-4 rounded-md p-5">
                    No hay imágenes
                  </div>
                )}

                {isOwner === true && (
                  <button onClick={() => { openModals(); }} className={`bg-[#5b0d0d] flex items-center justify-center text-sm w-full text-white p-2 md:w-10 md:h-10 rounded-full mr-1`}><i class='bx bx-plus text-3xl'></i></button>
                )}

              </div>
            )}
            {active === 'reviews' && (
              <div class="xl:max-w-[50%] md:max-w-md xs:max-w-full max-w-lg mx-auto mt-1 border-red-900">
                <div className="" onSubmit={insertReview}>
                  <form class=" ">
                    <div class="mb-2 md:mb-6">
                      <div class="px-4 md:px-1 pt-1 bg-white">
                        <div class="flex flex-wrap">
                          <div className="w-full px-4">
                            <div className="mb-8">
                              <label htmlFor="descripcion" className="block text-sm font-medium text-body-color mb-3">
                                Escribe aqui tu reseña
                              </label>

                              <textarea
                                name="descripcion"
                                value={review.descripcion ?? ''}
                                onChange={handleChange}
                                defaultValue=" "
                                maxLength={500}
                                rows={5}
                                cols={20}
                                className="w-full border border-[#DEE3F7] rounded-sm py-3 px-6 text-body-color text-base resize-none"
                                required
                              ></textarea>
                              <button type="submit" class="mt-2 flex flex-wrap justify-center w-full md:w-auto md:ml-auto px-4 py-2 bg-green-500 hover:bg-green-600 font-medium text-sm text-white border border-green-500 rounded-md shadow-button">
                                Publicar
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

                {Array.isArray(comentarios) && typeof comentarios !== 'undefined' && comentarios !== null && comentarios !== '' && comentarios.length !== 0 && comentarios.length > 0 ? (
                  <div className="text-left flex flex-wrap rounded-md">
                    {typeof comentarios !== 'undefined' && comentarios !== null && comentarios !== '' && comentarios.length !== 0 && comentarios.map((item) => (
                      <div class=" md:max-w-full  w-full">
                        <div class="mb-6 md:mb-6 shadow-sm rounded-t-8xl  w-full rounded-b-5xl overflow-hidden">
                          <div class="pt-1 pb-1 md:pb-1 px-4 md:px-16 w-full bg-white bg-opacity-40">
                            <div class="flex flex-wrap items-center">
                              <h4 class="w-full md:w-auto text-lg font-heading font-medium">{item.autor}</h4>
                              <div class="w-full md:w-px h-2 md:h-8 mx-8 bg-transparent md:bg-gray-200"></div>
                       
                              <div class="flex">
                                <a class="inline-block mr-1" href="#">
                                  <svg width="20" height="20" viewbox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20 7.91677H12.4167L10 0.416763L7.58333 7.91677H0L6.18335 12.3168L3.81668 19.5834L10 15.0834L16.1834 19.5834L13.8167 12.3168L20 7.91677Z" fill="#FFCB00"></path>
                                  </svg>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="px-4 md:px-16 pt-1 pb-2 bg-white">
                            <div class="flex flex-wrap">
                              <div class="w-full md:w-2/3 mb-6 md:mb-0">
                                <p class="mb-2 max-w-2xl text-darkBlueGray-400 leading-loose">{item.descripcion}</p>
                                <div class="-mb-2">
                                  <div class="inline-flex w-full md:w-auto md:mr-2 mb-2">
                                    <div class="flex items-center h-10 pl-2 pr-6 bg-green-100 border-2 border-green-500 rounded-full">
                                      <div class="flex mr-2 w-8 h-8 items-center justify-center bg-white rounded-full text-green-500">
                                        <svg width="11" height="11" viewbox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M10.016 6.366H6.38V10.092H4.472V6.366H0.836V4.638H4.472V0.911999H6.38V4.638H10.016V6.366Z" fill="currentColor"></path>
                                        </svg>
                                      </div>
                                      <span class="text-green-500 font-heading font-medium">Tegnologia creativa</span>
                                    </div>
                                  </div>

                                </div>
                              </div>
                              <div class="w-full md:w-1/3 text-right">
                                <p class="mb-8 text-sm text-gray-300">{item.fechaRegistro}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                  </div>
                ) : (
                  <div className="text-left bg-white border border-gray-300 mt-4 rounded-md p-5">
                    Aún no hay comentarios
                  </div>
                )}


              </div>
            )}
          </div>

        </div>

      ) : (
        <div>
          Cargando...
        </div>
      )}

    </section>
  );

}
export default DetailPage;