import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "../index.css";

import CreateNewsLetter from '../Pages/CreateNewsLetter/CreateNewsLetter.jsx';
import Login from '../Pages/Login/Auth';
import Account from '../Pages/ChangePassword/Password';
import Home from '../Pages/Home/Home';
import Dashboard from '../Pages/Dashboard/Dashborad';
import Information from '../Pages/Empresas/Information';
import Configuracion from '../Pages/Configuracion/Admin';
import FormEmpresas from '../Pages/Forms/RegistraEmpresa';
import Registrate from '../Pages/Forms/Registrate.jsx';
import CatalogoLogin from '../Pages/Catalogo/CatalogoPrivate';
import Catalogo from '../Pages/Catalogo/CatalogoPublic';
import DetailPage from '../Pages/DetailPage';
import AnalyticsPage from '../Pages/AnalyticsPage.jsx';
import Newsletter from '../Pages/NewsLetter/NewsLetter.jsx';
import InfoPage from '../Pages/InfoMatches.jsx';
import InfoTerminosyCondiciones from '../Pages/Condiciones/terminos';
import InfoAvisoPrivacidad from '../Pages/Condiciones/aviso';
import Recovery from '../Pages/RecuperaContrasenia/Recovery';





function Router() {
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/account" element={<Account />} />
        <Route path="/404" element={<Dashboard />} />
        <Route path="/socios" element={<Catalogo />} />
        <Route path="/configuracion" element={<Configuracion />} />
        <Route path="/nuestros-socios" element={<CatalogoLogin />} />
        <Route path="/estadisticas" element={<AnalyticsPage />} />
        <Route path="/boletin" element={<Newsletter />} />
        <Route path="/information" element={<Information />} />
        <Route path="/empresas" element={<FormEmpresas />} />
        <Route path="/detail" element={<DetailPage />} />
        <Route path="/registrate" element={<Registrate />} />
        <Route path="/crearboletin" element={<CreateNewsLetter />} />
        <Route path="/aviso-de-privacidad" element={<InfoAvisoPrivacidad />} />
        <Route path="/terminos-y-condiciones" element={<InfoTerminosyCondiciones />} />
        <Route path="/conexiones" element={<InfoPage />} />
        <Route path="/recovery" element={<Recovery />} />
      </Routes>

      
    </BrowserRouter>
  );
}

export default Router;
