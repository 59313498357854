import imagen1 from "../assets/img/imagen 1.png"
import imagen2 from "../assets/img/imagen2.jpg"
import imagen3 from "../assets/img/imagen3.png"
import icon1 from "../assets/img/perfil.png"
import icon2 from "../assets/img/user.png"
import icon3 from "../assets/img/user1.png"
import icon4 from "../assets/img/event.png"
import icon5 from "../assets/img/calendar.png"
import icon6 from "../assets/img/chat.png"
import { scrollToAboutUs } from "./functions"


function About (){
    return(
     <>
      <button className="bg-gradient-to-tr  from-red-800 to-red-500 text-white px-10 py-2 mt-10 rounded-3xl">Necesitas proveedores certificados, conoce nuestro m&eacute;todo</button>
  <section id="aboutus" className="flex mt-20 justify-center">
    <div className="md:flex block  mx-10 justify-between p-2">
      <div className="md:w-1/2 w-full h-[50vh] m-2 flex items-center ">
        <div>
          <h1 className="text-black text-4xl font-bold text-left">¡TE CONECTAMOS CON LA INDUSTRIA!</h1>
          <p className="text-left mt-10 text-gray-400">Tenemos más de 13 años posicionando marcas y servicios dentro de la industria, esto significa que podrás dar a conocer tus productos y servicios, ya que te generamos el contacto directo del tomador de decisiones dentro del ramo industrial, asegurando a tu empresa exclusividad de giro junto con dinámicas que te generarán referencias para ampliar tu red de contactos.</p>
          <button href="#contact" onClick={(e)=> { e.preventDefault(); scrollToAboutUs("contact"); }} className="bg-gradient-to-tr from-red-800 to-red-500 text-white px-10 py-2 mt-10 rounded-3xl"> Empezar ahora</button>
        </div>
      </div>
      <div className="md:w-[40%] w-full md:h-[50vh] h-[30vh] m-2 md:mt-0 mt-10 rounded-xl bg-gray-200 shadow-2xl">
        <img class="w-full h-full object-cover rounded-xl border border-gray-300 shadow-xl" src={imagen1} alt="Conectividad Industrial" />
      </div>
    </div>
  </section>
  <div className="bg-gray-800 border border-gray-100 mt-20 mx-10"></div>
  <section id="aboutus" className="flex mt-20 justify-center">
    <div className="md:flex block mx-10 justify-between p-2">
      <div className="md:w-[40%] w-full md:h-[50vh] h-[30vh] m-2 mb-20 rounded-xl bg-gray-200 shadow-xl">
        <img className="w-full h-full object-cover border border-gray-300 rounded-xl" src={imagen2}></img>
      </div>
      <div className="md:w-1/2 w-full h-[50vh] m-2 flex items-center ">
        <div>
          <div className="flex justify-end"></div>
          <h1 className="text-black text-right text-4xl font-bold">Construimos relaciones de alto impacto.</h1>
          <p className=" mt-10 text-right text-gray-400">Con nosotros, experimentarás un enfoque integral que incluye networking estratégico, alta en nuestra aplicación exclusiva de proveedores y un impulso significativo en las ventas, todo diseñado para maximizar el potencial de tu negocio. Únete a nosotros para elevar tu crecimiento empresarial a nuevos niveles. Descubre las oportunidades que te esperan y avanza hacia el éxito empresarial. ¡Conecta y crece con nosotros! </p>
        </div>
      </div>
    </div>
  </section>
  <section id="alianz" class="mt-40 bg-gradient-to-tr from-red-800 to-red-500 dark:bg-gray-900">
    <div class="items-center max-w-screen-xl px-4 py-8 mx-auto lg:grid lg:grid-cols-4 lg:gap-16 xl:gap-24 lg:py-24 lg:px-6">
      <div class="col-span-2 mb-8">
        <h2 class="mt-3 mb-4 text-3xl font-extrabold tracking-tight text-white md:text-3xl dark:text-white">Somos más de 150 afiliados</h2>
        <p class="font-light text-white sm:text-xl dark:text-gray-400 border-t border-gray-200 dark:border-gray-700 pt-5">"Somos tu Marketplace industrial, tu puerta de entrada a un mundo de oportunidades. Aquí, te ofrecemos un conjunto diverso de herramientas para conectarte con la industria de manera efectiva. Desde networking estratégico hasta soluciones de proveedores con solo un clic, nuestro Marketplace está diseñado para facilitar el camino de entrada de tus ventas a la industria. Únete a nosotros y descubre cómo nuestras herramientas pueden impulsar tu presencia en la industria. Conecta, colabora y haz crecer tu negocio con nosotros."</p> {/* <div class="pt-6 mt-6 space-y-4 border-t border-gray-200 dark:border-gray-700">
          <div>
            <a href="#" class="inline-flex items-center text-base font-medium text-white "> Explore Legality Guide <svg class="w-5 h-5 ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path>
              </svg>
            </a>
          </div>
          <div>
            <a href="#" class="inline-flex items-center text-base font-medium text-white hover:text-white"> Visit the Trust Center <svg class="w-5 h-5 ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path>
              </svg>
            </a>
          </div>
        </div>*/}
      </div>
      <div className="w-full col-span-2 space-y-8">
        <h3 className="text-white text-2xl font-bold">Herramientas a tu disposición</h3>
        <div class="col-span-2 space-y-8 md:grid md:grid-cols-2 md:gap-12 md:space-y-0">
          <div className="flex items-center">
            <div className="flex justify-center">
              <img src={icon1} class='bx bxs-file-pdf text-4xl text-white w-24 dark:text-white'></img>
            </div>
            <div>
              <h3 class="ml-2 text-md font-light  text-white dark:text-white">Portafolio digital</h3>
            </div>
          </div>
          <div className="flex items-center">
            <div className="flex justify-center">
              <img src={icon2} class='bx bx-conversation  text-white w-24  dark:text-white'></img>
            </div>
            <div>
              <h3 class="ml-2 text-md font-light text-center    text-white dark:text-white">Chat industrial</h3>
            </div>
          </div>
          <div className="flex items-center">
            <div className="flex justify-center">
              <img src={icon6} class='bx bxl-zoom text-4xl w-32 text-white   dark:text-white'></img>
            </div>
            <div>
              <h3 class="ml-2 text-md font-light text-left   text-white dark:text-white">Reuniones calendarizadas, virtuales y presenciales</h3>
            </div>
          </div>
          <div className="flex items-center">
            <div className="flex justify-center">
              <img src={icon3} class='bx bxs-calendar-event text-4xl w-24  text-white  dark:text-white'></img>
            </div>
            <div>
              <h3 class="ml-2 text-md font-light text-left   text-white dark:text-white">Din&aacute;micas de referencias</h3>
            </div>
          </div>
          <div className="flex items-center">
            <div className="flex justify-center">
              <img src={icon5} class='bx bxs-network-chart text-4xl w-24 text-white  dark:text-white'></img>
            </div>
            <div>
              <h3 class="ml-2 text-md font-light text-left   text-white dark:text-white">Microc&eacute;lulas de trabajo de interacci&oacute;n</h3>
            </div>
          </div>
          <div className="flex items-center">
            <div className="flex justify-center ">
              <img src={icon4} class='bx bx-share-alt text-4xl w-24 text-white  dark:text-white'></img>
            </div>
            <div>
              <h3 class="ml-2 text-md font-light text-center  text-white dark:text-white">Difusi&oacute;n de eventos y expos</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="events" class="bg-[#151923]">
    <div class="md:flex block   items-center">
      <div class="md:m-8 m-0 md:p-0 p-4 md:w-2/3 w-full bg-[#151923] ">
        <p class="text-lg font-medium text-white ">Acerca de nosotros</p>
        <h2 class="mt-3 mb-4 text-3xl font-extrabold tracking-tight text-white md:text-3xl dark:text-white">El club es de todos</h2>
        <p class="font-light text-white sm:text-xl dark:text-gray-400">Impulsa la presencia de tu marca personal y tu empresa, participando en los comités del club Vinculación Desarrollo de Proveedores Productividad</p>
        <div class="pt-6 mt-6 space-y-4 border-t border-gray-200 dark:border-gray-700">
          <div>
            <a href="#contact" class="inline-flex items-center text-base font-medium text-white "> Quiero pertenecer al comité. <svg class="w-5 h-5 ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div class="md:w-2/3 w-full md:h-[70vh] h-[50vh] bg-black opacity-50">
        <img className="h-full w-full object-cover" src={imagen3}></img>
      </div>
    </div>
  </section>
     </>
    );
}
export default About
