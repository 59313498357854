import { useState } from "react";
import logo from "../assets/img/logotipo.png";
import { useNavigate } from "react-router-dom";
import { scrollToAboutUs } from "./functions";
import { Link } from "react-router-dom";
import "boxicons";
import Toast from "./Toast/Toast";
import { Modal } from "flowbite-react";
import Spinner from "./Spinner/Spinner";
import ToastError from "./Toast/ToastError";
import API_URL from "../const";
function Headers() {
  const navigate = useNavigate();
  const [IsOpenSpinner, setIsOpenSpinner] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [msg, setMsg] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState({
    id_empresa_fk: null,
    imagen_url: null, // Changed to null to hold the file object
  });
  
  const openModals = () => {
    setOpenModal(true);
  };
  const handleLogout = () => {
    localStorage.removeItem("nombre");
    localStorage.removeItem("idRol_fk");
    localStorage.removeItem("id_usuario");
    localStorage.removeItem("id_empresasFk");
    localStorage.removeItem("token");
    
    navigate("/");
  };

  const handleSubmit = async (e) => {
    setOpenModal(false);
    setFormData({
      id_empresa_fk: null,
      imagen_url: null, // Changed to null to hold the file object
    });
    e.preventDefault();
    setIsOpenSpinner(true);
    const id_empresasFk = localStorage.getItem("id_empresasFk");

    const formDataToSend = new FormData();
    formDataToSend.append("id_empresa_fk", id_empresasFk);
    formDataToSend.append("imagen_url", formData.imagen_url);
    /*console.log(formDataToSend)*/
    try {
      const response = await fetch(`${API_URL}insertImageDetail.php`, {
        method: "POST",
        body: formDataToSend,
      });
      const data = await response.json();

      if (response.ok) {
        setIsOpenSpinner(false);
        setShowToast(true);

        setTimeout(() => {
          setShowToast(false);
        }, 5000);
        // Handle success
        console.log("Newsletter registrado exitosamente.");
      } else {
        // Handle error
        console.error("Error al registrar el newsletter:", data.error);
        setMsg(data.error);
        setShowToastError(true);
        setTimeout(() => {
          setShowToastError(false);
        }, 5000);
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
    }
  };
  const handleFileChange = (e) => {
    // Handling input changes for file inputs
    const { name, files } = e.target;
    setFormData({ ...formData, [name]: files[0] }); // Assuming only single file selection
  };
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(true);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  const idRol_fk = localStorage.getItem("idRol_fk");

  const nombre = localStorage.getItem("nombre");
  const handleCatalogClick = () => {
    if (window.location.pathname === "/nuestros-socios") {
      window.location.reload();
    }
  };
  return (
    <>
      {" "}
      <Spinner isOpen={IsOpenSpinner}></Spinner>{" "}
      {showToast && (
        <Toast isOpen={true} title="Éxito" text="Imagen agregada" />
      )}{" "}
      {showToastError && <ToastError isOpen={true} title="Error" text={msg} />}{" "}
      <Modal
        show={openModal}
        onClose={() => {
          setOpenModal(false);
          setFormData({
            id_empresa_fk: null,
            imagen_url: null, // Changed to null to hold the file object
          });
        }}
      >
        <Modal.Header>Cargar imagen</Modal.Header>
        <Modal.Body>
          <div className=" w-full ">
            <input
              onChange={handleFileChange}
              name="imagen_url"
              id="file-upload"
              type="file"
              class=""
            />
            <button
              onClick={
                formData.imagen_url !== null &&
                formData.imagen_url !== undefined
                  ? handleSubmit
                  : null
              }
              className={`w-full bg-red-600 my-2 h-10 ${
                formData.imagen_url !== null &&
                formData.imagen_url !== undefined
                  ? "opacity-100"
                  : "opacity-40"
              } rounded-md text-white`}
            >
              Cargar imagen
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <header className="header fixed top-0 shadow-md bg-white z-40 w-full  flex items-center transition">
        <div className=" w-full">
          <div className="flex items-center justify-between relative">
            <div className="px-4  ">
              <Link to="/" className="header-logo w-full block py-1">
                <img src={logo} alt="logo" className="w-[8rem]" />
              </Link>
            </div>
            <div className="flex px-4 justify-center items-center w-full">
              <div>
                <button
                  onClick={toggleMobileMenu}
                  id="navbarToggler"
                  className="focus:ring-transparent dark:bg-red-600 dark:hover:bg-red-700  absolute right-11 top-1/2  translate-y-[-50%] lg:hidden focus:ring-2 ring-primary px-3 py-[6px] rounded-lg flex items-center"
                >
                  <i className="bx bx-menu-alt-right text-3xl"></i>
                </button>
                <nav
                  id="navbarCollapse"
                  className={`absolute py-3 lg:py-0 lg:px-4 xl:px-6  bg-white  lg:bg-transparent shadow-lg rounded-sm max-w-[250px] w-full lg:max-w-full lg:w-full right-2 top-full ${
                    isMobileMenuOpen === true ? "hidden" : ""
                  } lg:block lg:static lg:shadow-none`}
                >
                  <ul className="block lg:flex">
                    <li className="relative group">
                      <Link
                        to="/"
                        className=" text-sm text-black hover:text-red-800 group-hover:text-primary py-2 lg:py-6 lg:inline-flex lg:px-0 flex mx-8 lg:mr-0 lg:ml-8 xl:ml-12"
                      >
                        Inicio
                      </Link>
                    </li>
                    <li className="relative group">
                      <Link
                        to="/nuestros-socios"
                        onClick={handleCatalogClick}
                        className="text-sm text-black hover:text-red-800 group-hover:text-primary py-2 lg:py-6 lg:inline-flex lg:px-0 flex mx-8 lg:mr-0"
                      >
                        Nuestros socios
                      </Link>
                    </li>
                    {idRol_fk !== "4" && (
                    <li className="relative group">
                      <Link
                        to="/empresas"
                        className="text-sm text-black hover:text-red-800 group-hover:text-primary py-2 lg:py-6 lg:inline-flex lg:px-0 flex mx-8 lg:mr-0"
                      >
                        {" "}
                        Mi empresa{" "}
                      </Link>
                    </li>
                    )}

                    

                    {/*idRol_fk === "1" && (
                      <li className="relative group">
                        <Link
                          to="/estadisticas"
                          className="text-base text-black hover:text-red-800 group-hover:text-primary py-2 lg:py-6 lg:inline-flex lg:px-0 flex mx-8 lg:mr-0"
                        >
                          {" "}
                          Estadisticas{" "}
                        </Link>
                      </li>
                    )*/}
                    {idRol_fk !== "" && idRol_fk !== "4" && (
                      <li className="relative group">
                        <Link
                          to="/boletin"
                          className="text-sm text-black hover:text-red-800 group-hover:text-primary py-2 lg:py-6 lg:inline-flex lg:px-0 flex mx-8 lg:mr-0"
                        >
                          {" "}
                          Novedades{" "}
                        </Link>
                      </li>
                    )}
                    {idRol_fk == "1" && (
                      <li className="relative group">
                        <Link
                          to="/configuracion"
                          className="text-sm text-black hover:text-red-800 group-hover:text-primary py-2 lg:py-6 lg:inline-flex lg:px-0 flex mx-8 lg:mr-0"
                        >
                          {" "}
                          Panel administrativo{" "}
                        </Link>
                      </li>
                    )}
                     {idRol_fk !== "" && (
                      <li className="relative group">
                        <Link
                          to="/account"
                          className="text-sm text-black hover:text-red-800 group-hover:text-primary py-2 lg:py-6 lg:inline-flex lg:px-0 flex mx-8 lg:mr-0"
                        >
                          {" "}
                          Configuraci&oacute;n{" "}
                        </Link>
                      </li>
                    )}
                    {/*{idRol_fk == "1" && (
                      <li className="relative group">
                        <Link
                          to="/crearboletin"
                          className="text-base text-black hover:text-red-800 group-hover:text-primary py-2 lg:py-6 lg:inline-flex lg:px-0 flex mx-8 lg:mr-0"
                        >
                          {" "}
                          Crear boletin{" "}
                        </Link>
                      </li>
                    )}
                    idRol_fk === "2" && (
                      <li class="relative group">
                        <label
                          onClick={openModals}
                          class="text-base text-black hover:text-red-800 group-hover:text-primary py-2 lg:py-6 lg:inline-flex lg:px-0 flex mx-8 lg:mr-0 cursor-pointer"
                        >
                          Cargar imagen
                        </label>
                      </li>
                    )*/}
                    <li onClick={handleLogout} className="relative group">
                      <span className="text-sm cursor-pointer text-black hover:text-red-800 py-2 lg:py-6 lg:inline-flex lg:px-0 flex mx-8 lg:mr-0">
                      Salir
                      </span>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="md:flex items-center  cursor-pointer hidden  flex-wrap justify-end  pr-16 lg:pr-0">
              <label className="text-sm font-semibold text-body-color hover:text-primary py-3 px-3 sm:px-3 sm:py-1 transition">
                {" "}
                {nombre}{" "}
              </label>
            </div>
            <div class="flex mr-3 items-center md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
              <div
                type="button"
                class="flex items-center justify-center text-sm bg-red-900 rounded-full md:me-0 focus:ring-4 focus:ring-red-300 dark:focus:ring-red-600"
                id="user-menu-button"
                aria-expanded="false"
                data-dropdown-toggle="user-dropdown"
                data-dropdown-placement="bottom"
              >
                <span class="sr-only">Open user menu</span>
                <div class="w-0 h-0 flex justify-center items-center md:w-10 md:h-10 rounded-full">
                  <box-icon name="user" color="white" size="md"></box-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
export default Headers;
