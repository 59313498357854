import React, { useState, useEffect } from "react";
import API_URL from "../../const";
//import Information from "./Information";
import 'boxicons'
export default function Cards({ Buscar, userDataa, isAuthorized }) {
  const [userData, setUserData] = useState([]);

  const [error, setError] = useState();
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    fetch(`${API_URL}getEmpresas.php`)
      .then((response) => response.text())
      .then((text) => {
        const startIndex = text.indexOf("[{");
        const jsonResponse =
          startIndex !== -1 ? text.substring(startIndex) : text;

        try {
          const responseData = JSON.parse(jsonResponse);
          console.log("Respuesta del servidor:", responseData);
          setSuccessMessage(responseData.mensaje);
          setUserData(responseData);

        } catch (error) {
          console.error("Error al analizar JSON:", error);
          setError("Error de formato en la respuesta del servidor");
        }
      })
      .catch((error) => {
        console.error("Error del servidor:", error);
        setError(error.statusText || "Error desconocido");
      });
  }, []);



  return (
    <div class="grid grid-cols-1 gap-8 mt-8 xl:mt-16 md:grid-cols-2 xl:grid-cols-4"> 
    {Array.isArray(userData) && typeof userData !== 'undefined' && userData.length !== 0 &&  userData.length > 0 ? (
      userData.map((user) => (
      <div key={user.id} onClick={() => {  Buscar && isAuthorized && Buscar(user.uuid); }} className="flex flex-col items-center p-8 transition-colors duration-300 transform border cursor-pointer rounded-xl hover:border-transparent group hover:bg-gray-100 dark:border-gray-700 dark:hover:border-transparent" > 
      <img class="object-cover w-32 h-32 rounded-full ring-4 ring-gray-300" src={`${API_URL}${user.logo}`} alt="" />
      
        <h2 className="mt-2 font-semibold text-gray-700 capitalize dark:text-white "> {user.nombre} </h2>
        <h3 className="mt-2 text-gray-500 dark:text-gray-300 "> {user.especialidad} </h3>
      </div>))) : (
      <div className="w-full ">
        <i class='bx text-3xl bx-search'></i><h2 className="text-center text-black ">Sin resultados</h2>
      </div>)} </div>
  );
}
