import { useNavigate } from "react-router-dom";


function Button(){
  const navigate = useNavigate();
  return(
   
    <button
    onClick={()=>window.open('https://api.whatsapp.com/send?phone=5215549769532&text=Est%C3%A1s%20a%20un%20paso%20de%20cambiar%20el%20rumbo%20de%20tu%20empresa.%0A%20Bienvenido%20a%20Club%20de%20Proveedores%20de%20la%20Industria%20del%20Estado%20de%20M%C3%A9xico.%0AConecta%20y%20crece%20con%20nosotros')}
    className="h-12 w-12 bg-green-600 fixed right-5 bottom-20 shadow-xl text-white rounded-md"
    ><i class='bx bxl-whatsapp text-3xl'></i></button>


  );
}


export default Button;