import { useEffect, useState } from "react";
import API_URL from "../const";
import { Accordion } from "flowbite-react";

import Headers from "../Components/HeaderPrivate";

function  InfoPage (){
    const [infoConexiones,setInfoConexiones] =  useState('')
    useEffect(() => {
    
        fetch(`${API_URL}getConexion.php`)
          .then((response) => response.text())
          .then((text) => {
            
            try {
              const responseData = JSON.parse(text);
              console.log("Respuesta del servidor:", responseData);
       
              setInfoConexiones(responseData);
         
            } catch (error) {
              console.error("Error al analizar JSON:", error);
             
    
            }
          })
          .catch((error) => {
            console.error("Error del servidor:", error);
            
          });
      }, []);
    return(
       
        <div className="fixed left-0 right-0">
            <Headers></Headers>
     <div className="mt-20 mx-10">
        {infoConexiones && (
          <Accordion>
            <Accordion.Panel>
              <Accordion.Title className="flex justify-end"><div className="flex  justify-between"><p className="mr-10">{infoConexiones.nombre_empresas}</p>  <p>{`${new Date(infoConexiones.datos[0].fecha).toLocaleDateString()} - ${new Date(infoConexiones.datos[0].fecha).toLocaleTimeString()}`}</p>
</div></Accordion.Title>
              <Accordion.Content>
                {infoConexiones.datos.map((dato, index) => (
                  <div className="w-full"  key={index}>
                   <div className="flex justify-between m-1">
                    <div className="flex"><h1 className="mr-3 font-semibold">ID</h1> <p>{dato.id}</p></div>
                    <div className="text-right"><h1 className="font-semibold">Nombre</h1> <p>{dato.nombre}</p></div>
                    </div>
                    <div className="flex justify-between m-1">
                    <div className="flex"><h1 className="font-bold mr-2">Fecha</h1> {dato.fecha}</div>
                    <div className="text-right"><h1 className="font-semibold">Descripción</h1> <p>{dato.descripcion}</p></div>
                   
                    </div>
                  </div>
                ))}
              </Accordion.Content>
            </Accordion.Panel>
          </Accordion>
        )}
        </div>
      </div>
      
  
    );

}
export default InfoPage;