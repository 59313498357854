function FooterTransparent (){
    const currentYear = new Date().getFullYear();

    return(
   
      <footer className="bg-gray-100 ">
     <div className="md:mx-10 flex md:flex-row flex-col mx-0 ">
       <div className="flex md:flex-row flex-col-reverse justify-between w-full  items-center text-white">
       <div className=" flex justify-center md:flex-row flex-col-reverse  w-full">
           <div className="">
             <p className="md:text-[12px]  text-[10px] text-body-color  font-medium text-black">Copyright © {currentYear} Todos los derechos reservados</p>
           </div>
         </div>
         <div className="flex justify-center md:justify-end w-full">
           <div className="mb-2 md:mb-2 mt-2 md:text-right">
             <div className="flex flex-wrap md:justify-end items-center">
               <a target="_blank" href="https://api.whatsapp.com/send?phone=5215549769532&text=🔔Est%C3%A1s%20a%20un%20paso%20de%20cambiar%20el%20rumbo%20de%20tu%20empresa.%0ABienvenido%20a%20Club%20de%20Proveedores%20de%20la%20Industria%20del%20Estado%20de%20M%C3%A9xico.%0AConecta%20y%20crece%20con%20nosotros" className="text-body-color hover:text-primary">
                 <i className='bx bxl-whatsapp text-black text-xl '></i>
               </a>
               <a target="_blank" href="https://open.spotify.com/show/6bVtYopY3j5oBvfYVgvITL?si=ecfaa6d2502b44fb" className="text-body-color hover:text-primary ml-6">
                 <i class='bx bxl-spotify text-xl text-black'></i>
               </a>
               <a target="_blank" href="https://www.youtube.com/channel/UCCJg0i_bSGC1oh3u-v_HjWA" className="text-body-color hover:text-primary ml-6">
                 <i class='bx bxl-youtube text-xl text-black'></i>
               </a>
               <a target="_blank" href="https://www.facebook.com/clubprestadodemexico/" className="text-body-color hover:text-primary ml-6">
                 <i className='bx bxl-facebook-circle text-xl text-black'></i>
               </a>
               <a target="_blank" href="https://www.instagram.com/cpiedomex/" className="text-body-color hover:text-primary ml-6">
                 <i className='bx bxl-instagram text-xl text-black'></i>
               </a>
               <a target="_blank" href="https://www.linkedin.com/company/club-de-proveedores-de-la-industria-del-estado-de-m%C3%A9xico/" className="text-body-color hover:text-primary ml-6">
                 <i class='bx bxl-linkedin-square text-xl text-black'></i>
               </a>
             </div>
           </div>
         </div>

       </div>
     </div>
   </footer>
 
   );
}
export default FooterTransparent;
