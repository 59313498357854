import { useEffect, useState } from "react";
import Headers from "../../Components/HeaderPrivate";
import API_URL from "../../const";
import Spinner from "../../Components/Spinner/Spinner";
import Toast from "../../Components/Toast/Toast";

function CreateNewsletter() {
    const [IsOpenSpinner, setIsOpenSpinner] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [tab, setTab] = useState(1);
    const [newsletter, setNewsletter] = useState('');
    const token = localStorage.getItem("token");
    const [formData, setFormData] = useState({
        nombre: '',
        descripcion: "",
        url_pdf: null, // Changed to null to hold the file object
        imagen: null, // Changed to null to hold the file object
    });

    const handleInputChange = (e) => {
        // Handling input changes for text fields
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        // Handling input changes for file inputs
        const { name, files } = e.target;
        setFormData({ ...formData, [name]: files[0] }); // Assuming only single file selection
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsOpenSpinner(true);

        const formDataToSend = new FormData();
        formDataToSend.append('nombre', formData.nombre);
        formDataToSend.append('descripcion', formData.descripcion);
        formDataToSend.append('imagen', formData.imagen);
        formDataToSend.append('url_pdf', formData.url_pdf);

        try {
            const response = await fetch(`${API_URL}insertBoletin.php`, {
                method: "POST",
                body: formDataToSend,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "POST",
                    "Access-Control-Allow-Credentials": true
                }
            });
            console.log(response)
            if (response.ok) {
                setIsOpenSpinner(false);
                setShowToast(true);

                setTimeout(() => {
                    setShowToast(false);
                }, 5000);

                console.log("Boletín registrado exitosamente.");

                setFormData({
                    nombre: '',
                    descripcion: "",
                    url_pdf: null,
                    imagen: null,
                });
            } else {
                console.error("Error al registrar el boletín:", response.statusText);
            }
        } catch (error) {
            console.error("Error en la solicitud:", error);
        }
    };

    const boletinGet = () => {
        fetch(`${API_URL}getBoletin.php`, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/x-www-form-urlencoded",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "GET",
                "Access-Control-Allow-Credentials": true

            }
        })

            .then((response) => response.text())
            .then((text) => {
                const startIndex = text.indexOf("[{");
                const jsonResponse =
                    startIndex !== -1 ? text.substring(startIndex) : text;

                try {
                    const responseData = JSON.parse(jsonResponse);
                    console.log("Respuesta del servidor:", responseData);

                    setNewsletter(responseData);
                    console.log(newsletter)
                } catch (error) {
                    console.error("Error al analizar JSON:", error);


                }
            })
            .catch((error) => {
                console.error("Error del servidor:", error);

            });
    }
    const handleDelete = (id) => {
        fetch(`${API_URL}deleteBoletin.php?id=${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    setIsOpenSpinner(false);
                    setShowToast(true);
                    setTimeout(() => {
                        setShowToast(false);
                    }, 5000);
                    boletinGet();
                    console.log('Boletín eliminado correctamente');
                    return response.json();
                } else {
                    throw new Error('Error al eliminar el boletín');
                }
            })
            .then(data => {
                console.log(data.message);

            })
            .catch(error => {
                console.log(error);

            });
    };

    useEffect(() => {
        boletinGet();
    }, []);

    return (
        <section className="fixed overflow-auto bg-gray-100 top-10 left-0 bottom-0 right-0">
            <Headers />
            <Spinner isOpen={IsOpenSpinner}></Spinner>{" "}
            {showToast && (
                <Toast isOpen={true} title="Éxito" text="Usuario registrado" />
            )}{" "}

            <div className="mt-[80px] ">
                <ul className="flex justify-center">
                    <li onClick={() => setTab(1)} className={`m-2 cursor-pointer border-b-2 text-${tab === 2 ? 'gray-400' : 'red-800'}  border-${tab === 2 ? 'gray-400' : 'red-800'}`}>Crear Boletin</li>
                    <li onClick={() => setTab(2)} className={`m-2 cursor-pointer border-b-2 text-${tab === 1 ? 'gray-400' : 'red-800'} border-${tab === 1 ? 'gray-400' : 'red-800'}`}>Boletines</li>
                </ul>
            </div>
            {tab === 1 ? (
                <form onSubmit={handleSubmit} className="md:max-w-md max-w-xs mx-auto mt-[20px] border-red-900">
                    <div className="">
                        <label className="md:text-3xl text-2xl justify-center items-center font-semibold text-red-900 flex">
                            <box-icon type="solid" name="user-plus" color="#7F1D1D"></box-icon> Registro de Newsletter
                        </label>
                        <div className="relative mt-10 z-0 w-full mb-5 group">
                            <input
                                type="text"
                                name="nombre"
                                value={formData.nombre}
                                onChange={handleInputChange}
                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-[1px] border-red-900 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-red-500 focus:outline-none focus:ring-0 focus:border-red-600 peer"
                                placeholder="Nombre"
                                required
                            />


                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <input
                                type="file"
                                name="url_pdf"
                                onChange={handleFileChange}
                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-[1px] border-red-900 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-red-500 focus:outline-none focus:ring-0 focus:border-red-600 peer"
                                placeholder="PDF"
                                required
                            />
                            <label
                                htmlFor="url_pdf"
                                className="peer-focus:font-medium flex absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-red-600 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Archivo PDF
                            </label>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <input
                                type="file"
                                name="imagen"
                                onChange={handleFileChange}
                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-[1px] border-red-900 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-red-500 focus:outline-none focus:ring-0 focus:border-red-600 peer"
                                placeholder="Imagen"
                                required
                            />
                            <label
                                htmlFor="imagen"
                                className="peer-focus:font-medium flex absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-red-600 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Imagen
                            </label>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <textarea
                                name="descripcion"
                                value={formData.descripcion}
                                onChange={handleInputChange}
                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-[1px] border-red-900 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-red-500 focus:outline-none focus:ring-0 focus:border-red-600 peer"
                                placeholder="Descripción"
                                required
                            />

                            <button type="button" className="absolute right-0 top-3 px-2">
                                <box-icon type="solid" color="gray"></box-icon>
                            </button>
                        </div>
                        <button
                            type="submit"
                            className="text-white w-full bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                        >
                            <div className="flex justify-center items-center w-full">
                                <box-icon name="save" color="white"></box-icon> Registrar
                            </div>
                        </button>
                    </div>
                </form>
            ) : (
                <div className="flex justify-center w-full ">
                    <table class="min-w-[80%] mt-10 bg-white shadow-md  rounded">
                        <thead class="bg-gray-800  text-white text-sm">
                            <tr>
                                <th class="w-1/8  text-left py-3 px-4  font-semibold">#</th>
                                <th class="w-1/8 text-left py-3 px-4  font-semibold">Imagen</th>
                                <th class="w-1/4 text-left py-3 px-4  font-semibold">Nombre</th>
                                <th class="w-1/3 text-left py-3 px-4  font-semibold">Descripcion</th>
                                <th class="w-10 text-left py-3 px-4  font-semibold">Acciones</th>
                            </tr>
                        </thead>
                        <tbody class="text-gray-700">
                            {typeof newsletter !== 'undefined' && newsletter.length !== 0 && newsletter.map((item) => (
                                <tr className="text-sm" key={item.id}>
                                    <td class="text-left py-3 px-4 ">{item.id}</td>
                                    <td class="text-left py-3 px-4"><img className="h-10" src={`${API_URL}${item.imagen}`}></img></td>

                                    <td class="text-left py-3 px-4">{item.nombre}</td>
                                    <td class="text-left py-3 px-4">{item.descripcion}</td>
                                    <td class="text-left py-3 px-4"><div><button onClick={() => handleDelete(item.id)} className="bg-red-700 rounded-md flex items-center justify-center text-white p-2 w-10 h-10"><i class='bx bx-trash text-xl'></i></button></div></td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
            )}
        </section>
    );
}

export default CreateNewsletter;
