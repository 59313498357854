import React, { useState } from "react";
import API_URL from "../const";
import Spinner from "./Spinner/Spinner";
import Toast from "./Toast/Toast";
function Contact() {

  const [IsOpenSpinner, setIsOpenSpinner] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    nombre: '',
    apellidos: '',
    email: '',
    numero: '',
    empresa: '',
    giro: '',
    puesto: '',
    mensaje: '',
    cargo:'',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsOpenSpinner(true);
    try {
      const response = await fetch(`${API_URL}insertContactanos.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",                
            "Access-Control-Allow-Origin": "http://localhost:3000",                
            "Access-Control-Allow-Headers": "POST",   
            "Access-Control-Allow-Credentials" : true
          },
          body: new URLSearchParams(formData),
        }
      );

      const text = await response.text();
      /*console.log("Respuesta del servidor (texto):", formData);*/

      const startIndex = text.indexOf("[{");
      const jsonResponse =
        startIndex !== -1 ? text.substring(startIndex) : text;

      try {
        const responseData = JSON.parse(jsonResponse);
        /*console.log("Respuesta del servidor:", responseData);*/
        setSuccessMessage(responseData.mensaje);

        if (response.ok) {
          /*console.log(response);*/
          setIsOpenSpinner(false);
          setFormData({
            nombre: '',
            apellidos: '',
            email: '',
            numero: '',
            empresa: '',
            giro: '',
            puesto: '',
            mensaje: '',
            cargo:'',
          });
          setShowToast(true);
          setIsOpenSpinner(false);
          setTimeout(() => {
            setShowToast(false);
          }, 5000);
        } else {
          console.error("Error del servidor:", response.statusText);
          setError(response.statusText || "Error desconocido");
          setIsOpenSpinner(false);
        }
      } catch (error) {
        console.error("Error al analizar JSON:", error);
        setError("Error de formato en la respuesta del servidor");
        setIsOpenSpinner(false);
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
      setError("Error de red: " + error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <section id="contact" className="py-[20px] mt-10">
  <Spinner isOpen={IsOpenSpinner}></Spinner> 
  {showToast && (
    <Toast isOpen={true} title="Éxito" text="Datos enviados" />
  )}
  <div className="mx-10">
    <div className="flex flex-wrap mx-[-16px]">
      <div className="w-full px-4">
        <div className="mx-auto max-w-[570px] text-center mb-12 wow fadeInUp" data-wow-delay=".1s">
          <h2 className="text-red-700 font-bold text-3xl sm:text-4xl md:text-[45px] mb-4">Contacta con nosotros</h2>
          <p className="text-body-color text-base md:text-lg leading-relaxed md:leading-relaxed">
            Ingresa tus datos en el formulario, te contactaremos a la brevedad posible.
          </p>
        </div>
      </div>
    </div>
    <div className="flex flex-wrap justify-center mx-[-16px]">
      <div className="w-full lg:w-10/12 xl:w-8/12 px-0 md:px-4">
        <div>
          <form onSubmit={handleSubmit}>
            <div className="flex flex-wrap mx-[-16px]">
              <div className="w-full md:w-1/2 px-4">
                <div className="mb-8">
                  <label htmlFor="fname" className="block text-sm font-medium text-body-color mb-3">
                    <span className="text-red-500">*</span> Nombre
                  </label>
                  <input
                    type="text"
                    name="nombre"
                    value={formData.nombre}
                    onChange={handleChange}
                    placeholder="Nombre"
                    className="w-full border border-[#DEE3F7] rounded-sm py-3 px-6 text-body-color text-base placeholder-body-color outline-none focus-visible:shadow-none focus:border-primary transition"
                  />
                </div>
              </div>
              <div className="w-full md:w-1/2 px-4">
                <div className="mb-8">
                  <label htmlFor="lname" className="block text-sm font-medium text-body-color mb-3">
                    <span className="text-red-500">*</span> Apellidos
                  </label>
                  <input
                    type="text"
                    name="apellidos"
                    value={formData.apellidos}
                    onChange={handleChange}
                    placeholder="Apellidos"
                    className="w-full border border-[#DEE3F7] rounded-sm py-3 px-6 text-body-color text-base placeholder-body-color outline-none focus-visible:shadow-none focus:border-primary transition"
                  />
                </div>
              </div>
              <div className="w-full md:w-1/2 px-4">
                <div className="mb-8">
                  <label htmlFor="email" className="block text-sm font-medium text-body-color mb-3">
                    <span className="text-red-500">*</span> Correo electrónico profesional
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Email"
                    className="w-full border border-[#DEE3F7] rounded-sm py-3 px-6 text-body-color text-base placeholder-body-color outline-none focus-visible:shadow-none focus:border-primary transition"
                    required
                  />
                </div>
              </div>
              <div className="w-full md:w-1/2 px-4">
                <div className="mb-8">
                  <label htmlFor="phone" className="block text-sm font-medium text-body-color mb-3">
                    <span className="text-red-500">*</span> Número
                  </label>
                  <input
                    type="number"
                    name="numero"
                    value={formData.numero}
                    onChange={handleChange}
                    placeholder="Código de país + Código de ciudad + Número de teléfono"
                    className="w-full border border-[#DEE3F7] rounded-sm py-3 px-6 text-body-color text-base placeholder-body-color outline-none focus-visible:shadow-none focus:border-primary transition"
                    required
                  />
                </div>
              </div>
              <div className="w-full md:w-1/2 px-4">
                <div className="mb-8">
                  <label htmlFor="empresa" className="block text-sm font-medium text-body-color mb-3">
                    <span className="text-red-500">*</span> Nombre de la empresa
                  </label>
                  <input
                    type="text"
                    name="empresa"
                    value={formData.empresa}
                    onChange={handleChange}
                    placeholder="Empresa"
                    className="w-full border border-[#DEE3F7] rounded-sm py-3 px-6 text-body-color text-base placeholder-body-color outline-none focus-visible:shadow-none focus:border-primary transition"
                    required
                  />
                </div>
              </div>
              <div className="w-full md:w-1/2 px-4">
                <div className="mb-8">
                  <label htmlFor="giro" className="block text-sm font-medium text-body-color mb-3">
                    <span className="text-red-500">*</span> Giro de la empresa
                  </label>
                  <input
                    type="text"
                    name="giro"
                    value={formData.giro}
                    onChange={handleChange}
                    placeholder="Giro de la empresa"
                    className="w-full border border-[#DEE3F7] rounded-sm py-3 px-6 text-body-color text-base placeholder-body-color outline-none focus-visible:shadow-none focus:border-primary transition"
                    required
                  />
                </div>
              </div>
              <div className="w-full md:w-1/2 px-4">
                <div className="mb-8">
                  <label htmlFor="puesto" className="block text-sm font-medium text-body-color mb-3">
                    <span className="text-red-500">*</span> Puesto
                  </label>
                  <input
                    type="text"
                    name="puesto"
                    value={formData.puesto}
                    onChange={handleChange}
                    placeholder="Puesto"
                    className="w-full border border-[#DEE3F7] rounded-sm py-3 px-6 text-body-color text-base placeholder-body-color outline-none focus-visible:shadow-none focus:border-primary transition"
                    required
                  />
                </div>
              </div>
              <div className="w-full md:w-1/2 px-4">
                <div className="mb-8">
                  <label htmlFor="cargo" className="block text-sm font-medium text-body-color mb-3">
                    <span className="text-red-500">*</span> Cargo actual
                  </label>
                  <input
                    type="text"
                    name="cargo"
                    value={formData.cargo}
                    onChange={handleChange}
                    placeholder="Cargo"
                    className="w-full border border-[#DEE3F7] rounded-sm py-3 px-6 text-body-color text-base placeholder-body-color outline-none focus-visible:shadow-none focus:border-primary transition"
                    required
                  />
                </div>
              </div>
              <div className="w-full px-4">
                <div className="mb-8">
                  <label htmlFor="message" className="block text-sm font-medium text-body-color mb-3">
                    <span className="text-red-500">*</span> Mensaje
                  </label>
                  <textarea
                    name="mensaje"
                    rows="5"
                    placeholder="Cuéntanos qué necesitas"
                    value={formData.mensaje}
                    onChange={handleChange}
                    className="w-full border border-[#DEE3F7] rounded-sm py-3 px-6 text-body-color text-base placeholder-body-color outline-none focus-visible:shadow-none focus:border-primary transition resize-none"
                    required
                  ></textarea>
                </div>
              </div>
              <div className="w-full mx-auto text-center px-4">
                <button
                  type="submit"
                  className="text-base font-medium text-white bg-red-700 py-4 w-full justify-center hover:shadow-signUp rounded-sm inline-flex mx-auto transition duration-300 ease-in-out"
                >
                  <i className='bx bxl-save text-2xl'></i> Registrar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

  );
}

export default Contact;
