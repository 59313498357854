import React, { useState, useEffect } from "react";
import "./Auth.css";
import imagen from "../../assets/img/logotipo.png";
import achurado from "../../assets/img/achurado-inferior.png";
import superior from "../../assets/img/achurado-superior.png";
import { useNavigate } from "react-router-dom";
import Spinner from "../../Components/Spinner/Spinner";
import API_URL from "../../const";
import { Link } from "react-router-dom";

function Login() {
  const [email, setEmail] = useState("");
  const [contrasenia, setContrasenia] = useState("");
  const [error, setError] = useState(null);
  const [showErrors, setShowErrors] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [IsOpenSpinner, setIsOpenSpinner] = useState(false);
  const [idRol, setIdRol] = useState(null);
  const validateForm = (e) => {
    e.preventDefault();
    if (email === "" || contrasenia === "") {
      setShowErrors(true);
    } else {
      setShowErrors(false);
      navigate("/empresas");
    }
  };

  const handleSubmit = async (e) => {
    setIsOpenSpinner(true);
    e.preventDefault();
    const requestBody = `email=${encodeURIComponent(email)}&contrasenia=${encodeURIComponent(contrasenia)}`;
    

    try {
      const response = await fetch(`${API_URL}auth.php`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
            email,
            contrasenia
        })
    });

      const text = await response.text();
      const startIndex = text.indexOf("[{");
      const jsonResponse = (startIndex !== -1) ? text.substring(startIndex) : text;


      const responseData = JSON.parse(jsonResponse);
      

      if (response.ok) {
        localStorage.setItem("idRol_fk", responseData[0].idRol_fk.toString());
        localStorage.setItem("nombre", responseData[0].nombre.toString());
        localStorage.setItem("id_usuario", responseData[0].id_usuario.toString());
        localStorage.setItem("id_empresasFk", responseData[0].id_empresasFk.toString());
        localStorage.setItem("token", responseData[0].token.toString());
        if (responseData[0].idRol_fk == 1) {
          navigate("/configuracion");
        } else if(responseData[0].idRol_fk == 4){
          navigate("/nuestros-socios");  
        } else {
          navigate("/boletin");
        }
        console.log(response);
        setIsOpenSpinner(false);
      } else {
        setMessage("Email y/o contraseña incorrectos");
        setError(response.statusText || "Error desconocido");
        setIsOpenSpinner(false);
      }

    } catch (error) {
      console.error("Error en la solicitud:", error);
      setMessage("Se ha producido un error, intente más tarde");
      setError("Error de red: " + error.message);
      setIsOpenSpinner(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "contrasenia") {
      setContrasenia(value);
    }
  };
  return (
    <>
      <React.Fragment>
        <Spinner isOpen={IsOpenSpinner}></Spinner>{" "}
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "start",
              justifyContent: "end",
              position: "fixed",
              top: 0,
            }}
          >
            <img src={superior} style={{ width: "30%" }}></img>
          </div>
          <div className="flex h-full absolute top-0 left-0 right-0 items-center justify-center">
            <div className="w-full card-login rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0  dark:border-gray-700 max-w-[70%] z-10">
              <a href="#" className="flex justify-center items-center ">
                <img className="w-60 h-50 " src={imagen} alt="logo" />
              </a>
              <div className="ml-5 mr-5 mb-5">
                <form onSubmit={validateForm}>
                  <div>
                    <label
                      htmlFor="email"
                      className="block mb-1 text-gray-500 text-sm font-medium dark:text-white text-left"
                    >
                      {" "}
                      Correo{" "}
                    </label>
                    <input
                      value={email}
                      onChange={handleChange}
                      type="email"
                      name="email"
                      id="email"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full  p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />{" "}
                    {showErrors && email === "" && (
                      <p className="text-red-500 text-xs">
                        {" "}
                        El campo es requerido{" "}
                      </p>
                    )}
                  </div>
                  <div>
                    <label
                      htmlFor="contrasenia"
                      className="block mb-2 text-gray-500 text-sm font-medium dark:text-white text-left"
                    >
                      {" "}
                      Contraseña{" "}
                    </label>
                    <input
                      value={contrasenia}
                      onChange={handleChange}
                      type="password"
                      name="contrasenia"
                      id="contrasenia"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />{" "}
                    {showErrors && contrasenia === "" && (
                      <p className="text-red-500 text-xs">
                        {" "}
                        El campo es requerido{" "}
                      </p>
                    )}
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="flex items-start ">
                      <div className=" text-sm">
                      <Link
                          to="/recovery"
                          className="text-sm text-black hover:text-red-800 group-hover:text-primary py-1 lg:py-2 lg:inline-flex lg:px-0 flex mx-1 lg:mr-0"
                        >
                          {" "}
                          Se me olvido mi contraseña{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                  {error && (
                    <p className="text-red-500 text-sm mt-2 mb-2">
                      {message}
                    </p>
                  )}{" "}
                  <button
                    onClick={handleSubmit}
                    type="submit"
                    className="w-full mt-2 text-white bg-[#494544] hover:bg-[#494544] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                  >
                    {" "}
                    Iniciar sesión{" "}
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div
            className="bg-white"
            style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "space-between",
              position: "fixed",
              bottom: 0,
              left: "0",
              right: "0",
            }}
          >
            <img src={achurado} style={{ width: "30%" }}></img>
            <h6 style={{ fontSize: "12px", color: "#6C757D", margin: "10px" }}>
              {" "}
              Club Proveedores de la Industria Estado de México{" "}
            </h6>
            <div style={{ width: "30%" }}></div>
          </div>
        </div>
      </React.Fragment>
    </>
  );
}


export default Login;
