import React, { useState } from "react";

export default function Search({ searchValue,setSearchValue }) {

  const handleInputChange = (event) => {
    console.info(event)
    setSearchValue(event.target.value);
  };

  

  return (
   
      <div className="relative w-full ">
        <input
          type="search"
          className="block w-full py-2.5 mr-1 px-2 p-4 ps-10 text-sm h-10 text-gray-900 border border-gray-300 rounded-lg bg-gray-50  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white focus:ring-red-100 focus:border-red-100"
          placeholder="Buscar..."
          value={searchValue}
          onChange={handleInputChange}
          required
        />
       
      </div>
  
  );
}
