import React, { useState, useEffect } from "react";

import Search from "../../Components/Search";

import Footer from "../../Components/Footer";
import Pagination from "../../Components/Pagination";
import { Link } from "react-router-dom";
import API_URL from "../../const";
import HeaderPrivate from "../../Components/HeaderPrivate";

import Cards from "../Empresas/Cards";

import Spinner from "../../Components/Spinner/Spinner";
import Card from "../Empresas/Card";
import { useNavigate } from "react-router-dom";
import Toast from "../../Components/Toast/Toast";
import Filter from "../../Components/filter/filter";
import ToastInfo from "../../Components/Toast/ToastInfo";
import DetailPage from "../DetailPage";
import FooterTransparent from "../../Components/footerTransparente";
export default function CatalogoLogin() {
  const [IsOpenSpinner, setIsOpenSpinner] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showToastInfo, setShowToastInfo] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [error, setError] = useState("");
  const [empresaData, setEmpresaData] = useState(null);
  const [searchCard, setSearchCard] = useState([]);
  const [searchEstado, setSearchEstado] = useState([]);
  const [searchMunicipio, setSearchMunicipio] = useState([]);
  const [showCards, setShowCards] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [especialidad, setEspecialidad] = useState("");
  const [estado, setEstado] = useState("");
  const [municipio, setMunicipio] = useState("");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const Buscar = (uuid) => {

    console.log("Nombre de la empresa recibido en CatalogoLogin:", uuid);
    setIsOpenSpinner(true);
    fetch(`${API_URL}getEmpresaDetalles.php?uuid=${uuid}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Headers": "GET",
        "Access-Control-Allow-Credentials": true
      }
    })
      .then((response) => response.text())
      .then((text) => {
        const startIndex = text.indexOf("[{");
        const jsonResponse = startIndex !== -1 ? text.substring(startIndex) : text;

        try {
          const responseData = JSON.parse(jsonResponse);

          console.log("Respuesta del servidor:", responseData);
          setSuccessMessage(responseData.mensaje);
          setEmpresaData(responseData);
          setIsOpenSpinner(false);

        } catch (error) {
          console.error("Error al analizar JSON:", error);
          setIsOpenSpinner(false);
          setError("Token de autenticación invalido");
        }
      })
      .catch((error) => {
        console.error("Error del servidor:", error);
        setIsOpenSpinner(false);
        setError(error.statusText || "Error desconocido");
      });
  };

  const BuscarEstado = (estado, municipio, nombre) => {
    console.log("Nombre de la empresa recibido en CatalogoLogin:", estado);
    setIsOpenSpinner(true);
    fetch(`${API_URL}getEmpresasEstados.php?estado=${estado}?municipio=`)
      .then((response) => response.text())
      .then((text) => {
        const startIndex = text.indexOf("[{");
        const jsonResponse =
          startIndex !== -1 ? text.substring(startIndex) : text;

        try {
          const responseData = JSON.parse(jsonResponse);
          console.log("Respuesta del servidor:", responseData);
          setSuccessMessage(responseData.mensaje);

          setSearchEstado(responseData);

          setIsOpenSpinner(false);
          setShowToast(true);

          setTimeout(() => {
            setShowToast(false);
          }, 3000);
          setIsOpenSpinner(false);
        } catch (error) {
          console.log("Error al analizar JSON:", error);
          setError("Error de formato en la respuesta del servidor");

          setShowToastInfo(true);

          setTimeout(() => {
            setShowToastInfo(false);
          }, 3000);
          setIsOpenSpinner(false);
        }
      })
      .catch((error) => {
        console.error("Error del servidor:", error);
        setError(error.statusText || "Error desconocido");
        setShowToast(true);
      });
  };
  const BuscarMunicipio = (municipio) => {
    console.log("Nombre de la empresa recibido en CatalogoLogin:", municipio);
    setIsOpenSpinner(true);
    fetch(`${API_URL}getEmpresasMunicipio.php?municipio=${municipio}`)
      .then((response) => response.text())
      .then((text) => {
        const startIndex = text.indexOf("[{");
        const jsonResponse =
          startIndex !== -1 ? text.substring(startIndex) : text;

        try {
          const responseData = JSON.parse(jsonResponse);
          console.log("Respuesta del servidor:", responseData);
          setSuccessMessage(responseData.mensaje);

          setSearchMunicipio(responseData);

          setIsOpenSpinner(false);
          setShowToast(true);

          setTimeout(() => {
            setShowToast(false);
          }, 3000);
          setIsOpenSpinner(false);
        } catch (error) {
          console.log("Error al analizar JSON:", error);
          setError("Error de formato en la respuesta del servidor");

          setShowToastInfo(true);

          setTimeout(() => {
            setShowToastInfo(false);
          }, 3000);
          setIsOpenSpinner(false);
        }
      })
      .catch((error) => {
        console.error("Error del servidor:", error);
        setError(error.statusText || "Error desconocido");
        setShowToast(true);
      });
  };
  const BuscarSearch = () => {

    console.log("Nombre de la empresa recibido en CatalogoLogin:", searchValue);
    setIsOpenSpinner(true);

    // Construir la URL con los parámetros que tienen valores
    let url = `${API_URL}getEmpresasSearch.php?`;
    if (searchValue) url += `nombre=${searchValue}&`;
    if (estado) url += `estado=${estado}&`;
    if (municipio) url += `municipio=${municipio}&`;
    if (especialidad) url += `giro=${especialidad}&`;

    // Eliminar el último '&' si existe
    url = url.replace(/&$/, '');

    fetch(url)
      .then((response) => response.text())
      .then((text) => {
        const startIndex = text.indexOf("[{");
        const jsonResponse =
          startIndex !== -1 ? text.substring(startIndex) : text;

        try {
          const responseData = JSON.parse(jsonResponse);

          console.log("Respuesta del servidor:", responseData);
          setSuccessMessage(responseData.mensaje);
          setSearchCard(responseData);
          setIsOpenSpinner(false);
          setShowToast(true);

          setTimeout(() => {
            setShowToast(false);
          }, 3000);
          setIsOpenSpinner(false);
        } catch (error) {
          console.log("Error al analizar JSON:", error);
          setError("Error de formato en la respuesta del servidor");

          setShowToastInfo(true);

          setTimeout(() => {
            setShowToastInfo(false);
          }, 3000);
          setIsOpenSpinner(false);
        }
      })
      .catch((error) => {
        console.error("Error del servidor:", error);
        setError(error.statusText || "Error desconocido");
        setShowToast(true);
      });
  };

  useEffect(() => {
    if (token == null || token == undefined || token == "") {
      setShowToastInfo(true);
      setSuccessMessage("Tu sesión ha expirado, autenticate nuevamente.");
      setTimeout(() => {
        setShowToastInfo(false);
      }, 5000);

      navigate("/login");
    } else {
      if (empresaData) {
        setShowCards(false);
      }
    }
  }, [empresaData]);


  const idRol_fk = localStorage.getItem("idRol_fk");
  const isAuthorized = idRol_fk === "1" || idRol_fk === "2" || idRol_fk === "4";
  return (
    <div className=" absolute left-0 right-0   h-screen justify-between">
      <Spinner isOpen={IsOpenSpinner}></Spinner>
      <HeaderPrivate />

      <Toast isOpen={showToast} title="Exito" text="Empresa encontrada" />
      <ToastInfo
        isOpen={showToastInfo}
        title="Búsqueda"
        text="Empresa no encontrada"
      />
      <section class="bg-white dark:bg-gray-900 mb-10">
        <div class="container px-6 py-12 mt-5 mx-auto">
          {" "}
          {showCards && (
            <div class=" items-center flex md:flex-row flex-col  ">
              <div className="md:w-2/5 w-full">
                <Search searchValue={searchValue} setSearchValue={setSearchValue}></Search>
              </div>
              <div className="xl:ml-3 ml-0 block md:flex w-full items-center">

                <Filter especialidad={especialidad} setEspecialidad={setEspecialidad} estado={estado} setEstado={setEstado} municipio={municipio} setMunicipio={setMunicipio} ></Filter>

                <div className="flex justify-end">
                  <button
                    onClick={BuscarSearch}
                    className="text-white h-10 w-12 px-3 mr-1  py-2 bg-red-800 hover:bg-red-900 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm  dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                  >
                    <i class='bx text-xl bx-search'></i>
                  </button>
                </div>
              </div>

            </div>
          )}{" "}
          {isAuthorized && showCards && searchCard.length === 0 && searchEstado.length === 0 && searchMunicipio.length === 0 && (
            <Cards isAuthorized={isAuthorized} Buscar={Buscar} />


          )}

          {empresaData && <DetailPage empresaData={empresaData} />}
          {empresaData ? null : (
            <Card isAuthorized={isAuthorized} searchCard={searchCard} Buscar={Buscar} searchMunicipio={searchMunicipio} searchEstado={searchEstado} BuscarEstado={BuscarEstado} />
          )}
        </div>
      </section>

      {empresaData ? (
        <div className="fixed bottom-0 left-0 right-0">
          <FooterTransparent></FooterTransparent>
        </div>
      ) : (
        <div className="fixed bottom-0 left-0 right-0">
          <FooterTransparent></FooterTransparent>
        </div>
      )}


    </div>
  );
}
