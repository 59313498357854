
import { useState } from "react";
import logo from "../assets/img/logotipo.png";
import { useNavigate,Link } from "react-router-dom";
import { scrollToAboutUs } from "./functions";

function Header ({isAuthorized}){
    const navigate = useNavigate();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(true);

    const toggleMobileMenu = () => {
      setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const idRol_fk = localStorage.getItem("idRol_fk");
    
 return(

  <header className="header fixed top-0 shadow-md bg-white z-40 w-full flex items-center transition">
  <div className=" w-full">
    <div className="flex items-center justify-between relative">
      <div className="px-4">
        <a href="/" className="header-logo w-full block py-2">
          <img src={logo} alt="logo" className="w-[8rem] py2" />
        </a>
      </div>
      <div className="flex px-4 justify-between items-center w-full">
        <div>
          <button onClick={toggleMobileMenu} id="navbarToggler" className="focus:ring-transparent dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800 absolute right-4 top-1/2  translate-y-[-50%] lg:hidden focus:ring-2 ring-primary px-3 py-[6px] rounded-lg flex items-center">
            <i className='bx bx-menu-alt-right text-3xl'></i>
          </button>
          <nav id="navbarCollapse" className={`absolute py-3 lg:py-0 lg:px-2 xl:px-2 bg-white lg:bg-transparent shadow-md rounded-sm max-w-[250px] w-full lg:max-w-full lg:w-full right-2 top-full ${isMobileMenuOpen===true ? 'hidden' :'' } lg:block lg:static lg:shadow-none`}>
            <ul className="block lg:flex">
              
             {!isAuthorized &&(
             <>
             <li className="relative group">
                <a href="#aboutus" onClick={(e)=> { e.preventDefault(); scrollToAboutUs("aboutus"); }} className="menu-scroll text-sm text-black hover:text-red-800 group-hover:text-primary py-2 lg:py-4 lg:inline-flex lg:px-0 flex mx-8 lg:mr-0 "> Quiénes somos{isAuthorized} </a>
              </li>
              <li className="relative group">
                <a href="#alianz" onClick={(e)=> { e.preventDefault(); scrollToAboutUs("alianz"); }} className="menu-scroll text-sm text-black hover:text-red-800 group-hover:text-primary py-2 lg:py-4 lg:inline-flex lg:px-0 flex mx-8 lg:mr-0 lg:ml-8 xl:ml-12" > Alianzas Valle de México </a>
              </li>
              <li className="relative group">
                <a href="#contact" onClick={(e)=> { e.preventDefault(); scrollToAboutUs("contact"); }} className="menu-scroll text-sm text-black hover:text-red-800 group-hover:text-primary py-2 lg:py-4 lg:inline-flex lg:px-0 flex mx-8 lg:mr-0 lg:ml-8 xl:ml-12" > Contáctanos </a>
              </li>
              <li className="relative group">                
                <Link to="/registrate" className="text-base cursor-pointer text-black hover:text-red-800 group-hover:text-primary text-sm  py-2 lg:py-4 lg:inline-flex lg:px-0 flex mx-8 lg:mr-0"> Regístrate </Link>
              </li>
              <li className="relative group">                
                <Link onClick={(e)=> { e.preventDefault(); scrollToAboutUs("accordion-flush"); }} to="/registrate" className="text-base cursor-pointer  text-black hover:text-red-800 group-hover:text-primary text-sm  py-2 lg:py-4 lg:inline-flex lg:px-0 flex mx-8 lg:mr-0"> Preguntas frecuentes </Link>
              </li>
                           
              </>
              )}
              <li className="relative group">
                <Link to="/socios" className="text-sm cursor-pointer text-black hover:text-red-800 group-hover:text-primary py-2 lg:py-4 lg:inline-flex lg:px-0 flex mx-8 lg:mr-0"> Nuestros socios </Link>
              </li>    
              <li className="relative cursor-pointer group md:hidden flex">
                <a onClick={()=>navigate('/login')} className="menu-scroll text-base pointer text-black hover:text-red-800 group-hover:text-primary py-2 lg:py-4 lg:inline-flex lg:px-0 flex mx-8 lg:mr-0 lg:ml-8 xl:ml-12" > Acceso a industrias </a>
              </li>          
              <li className="relative cursor-pointer group md:hidden flex">
                <a onClick={()=>navigate('/login')} className="menu-scroll text-base pointer text-black hover:text-red-800 group-hover:text-primary py-2 lg:py-4 lg:inline-flex lg:px-0 flex mx-8 lg:mr-0 lg:ml-8 xl:ml-12" > Acceso a miembros </a>
              </li>
            </ul>
          </nav>
        </div>
        
        <div className="md:flex items-center cursor-pointer hidden flex-wrap justify pr-16 lg:pr-0 py-1.5">
       
          {idRol_fk === "1" || idRol_fk === "2" || idRol_fk === "4" ? (
                    <a className=" btn-rojo cursor-pointer px-4 py-2 mt-2 text-sm text-white rounded-md md:mt-0 border-1 border-gray-200 hover: focus:outline-none focus:shadow-outline" onClick={()=>navigate('/empresas')} > Ver perfil </a>
                ) : (
<div className="flex flex-wrap  justify-end w-full">
                   <a className=" bg-black cursor-pointer xl:mb-0 mb-1 px-1 py-2 w-36 text-sm text-white rounded-sm  " onClick={()=>navigate('/login')} >Acceso a industria</a>
             
                    <a className=" bg-red-700  cursor-pointer px-1 py-2 w-36 ml-1 text-sm text-white rounded-sm  " onClick={()=>navigate('/login')} >Acceso a miembro</a>
                    </div>
                )}
        </div>
      </div>
    </div>
  </div>
</header>

 );
}
export default Header;
