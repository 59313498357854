import React, { useState, useEffect } from "react";
import Headers from "../../Components/HeaderPrivate";
import Footer from "../../Components/footerTransparente";
import Spinner from "../../Components/Spinner/Spinner";
import Toast from "../../Components/Toast/Toast";
import { useNavigate } from "react-router-dom";
import API_URL from "../../const";
import { linkClasses } from "@mui/material";

export default function FormEmpresas() {
  const [IsOpenSpinner, setIsOpenSpinner] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [permiso, setPermiso] = useState("");
  const [patch, setPatch] = useState(false);
  const token = localStorage.getItem("token");
  const [formData, setFormData] = useState({
    id_empresa: 0,
    nombre: "",
    giro: "",
    direccion: "",
    rfc: "",
    tamanio: "",
    estado: "",
    municipio: "",
    especialidad: "",
    sede: "",
    fundacion: "",
    sitioWeb: "",
    telefono: "",
    email: "",
    colorHex: "#0B0909",
    logo: "",
    googleUrl: "",
    portada: "",
    whatsapp: "",
    mision: "",
    vision: "",
    instagram: "",
    facebook: "",
    linkedin: ""
  });
  const Buscar = () => {
    const id_usuario = localStorage.getItem("id_usuario");

    if (!id_usuario) {
      console.error("No se encontró el id_usuario en el localStorage");
      return;
    }
    /*console.log("Nombre de la empresa recibido en CatalogoLogin:", id_usuario);*/
    setIsOpenSpinner(true);
    fetch(`${API_URL}getInfoDetail.php?id_usuario=${id_usuario}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Headers": "GET",
        "Access-Control-Allow-Credentials": true

      }
    })
      .then((response) => response.text())
      .then((text) => {
        const startIndex = text.indexOf("[{");
        const jsonResponse =
          startIndex !== -1 ? text.substring(startIndex) : text;

        try {
          const responseData = JSON.parse(jsonResponse);
          if (typeof responseData[0]?.nombre !== 'undefined') {
            console.log("Respuesta del servidor:", responseData);
            setSuccessMessage(responseData.mensaje);
            setFormData(responseData[0]);

            setPatch(true)
          }
          setIsOpenSpinner(false);

        } catch (error) {
          console.error("Error al analizar JSON:", error);
          setIsOpenSpinner(false);
          setError("Error de formato en la respuesta del servidor");
        }
      })
      .catch((error) => {
        console.error("Error del servidor:", error);
        setIsOpenSpinner(false);
        setError(error.statusText || "Error desconocido");
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

  };
  const handleFileChange = (e) => {
    // Handling input changes for file inputs
    const { name, files } = e.target;
    setFormData({ ...formData, [name]: files[0] }); // Assuming only single file selection
  };

  const handleSubmit = async (e) => {
    console.info("Aqui imprime el formulario a enviar", formData);

    e.preventDefault();

    setIsOpenSpinner(true);
    try {
      const id_usuario = localStorage.getItem("id_usuario");

      if (!id_usuario) {
        console.error("No se encontró el id_usuario en el localStorage");
        return;
      }

      const formDataToSend = new FormData();
      formDataToSend.append('id_empresa', formData.id_empresa);
      formDataToSend.append('nombre', formData.nombre);
      formDataToSend.append('giro', formData.giro);
      formDataToSend.append('direccion', formData.direccion);
      formDataToSend.append('rfc', formData.rfc);
      formDataToSend.append('tamanio', formData.tamanio);
      formDataToSend.append('estado', formData.estado);
      formDataToSend.append('municipio', formData.municipio);
      formDataToSend.append('especialidad', formData.especialidad);
      formDataToSend.append('sede', formData.sede);
      formDataToSend.append('fundacion', formData.fundacion);
      formDataToSend.append('sitioWeb', formData.sitioWeb);
      formDataToSend.append('telefono', formData.telefono);
      formDataToSend.append('email', formData.email);
      formDataToSend.append('colorHex', formData.colorHex);
      formDataToSend.append('logo', formData.logo);
      formDataToSend.append('portada', formData.portada);
      formDataToSend.append('googleUrl', formData.googleUrl);
      formDataToSend.append('whatsapp', formData.whatsapp);
      formDataToSend.append('mision', formData.mision);
      formDataToSend.append('vision', formData.vision);
      formDataToSend.append('instagram', formData.instagram);
      formDataToSend.append('facebook', formData.facebook);
      formDataToSend.append('linkedin', formData.linkedin);

      console.log(token)

      const response = await fetch(`${API_URL}insertEmpresa.php`, {
        method: "POST",
        body: formDataToSend,
        headers: {
          "Authorization": `Bearer ${token}`,
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Headers": "POST",
          "Access-Control-Allow-Credentials": true
        }
      }
      );

      const text = await response.text();
      console.log("Respuesta del servidor (texto):", text);

      const startIndex = text.indexOf("[{");
      const jsonResponse =
        startIndex !== -1 ? text.substring(startIndex) : text;

      try {
        const responseData = JSON.parse(jsonResponse);
        console.log("Respuesta del servidor:", responseData);
        setSuccessMessage(responseData.mensaje);
        if (response.status === 401) {
          // Si obtenemos un 401, redirigimos al login
          localStorage.removeItem("token");
          navigate('/login');
  
          return; // Salimos de la función para evitar procesar la respuesta
        }
        if (response.ok) {

          console.log(response);
          setIsOpenSpinner(false);
          setFormData({
            nombre: "",
            giro: "",
            direccion: "",
            rfc: "",
            tamanio: "",
            estado: "",
            municipio: "",
            especialidad: "",
            sede: "",
            fundacion: "",
            sitioWeb: "",
            telefono: "",
            email: "",
            colorHex: "",
            logo: "",
            googleUrl: "",
            portada: "",
            whatsapp: "",
            descripcion: ""
          });
          setShowToast(true);
          
          Buscar();

          setTimeout(() => {
            setShowToast(false);
          }, 5000);
        } else {

          console.error("Error del servidor:", response.statusText);
          setError(response.statusText || "Error desconocido");
          setIsOpenSpinner(false);
        }
      } catch (error) {
        console.error("Error al analizar JSON:", error);
        setError("Error de formato en la respuesta del servidor");
        setIsOpenSpinner(false);
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
      setError("Error de red: " + error.message);
    }
  };

  const handlePatch = async (e) => {
    e.preventDefault();
    setIsOpenSpinner(true);
    const formDataToSend = new FormData();
    formDataToSend.append('id_empresa', formData.id_empresa);
    formDataToSend.append('nombre', formData.nombre);
    formDataToSend.append('giro', formData.giro);
    formDataToSend.append('direccion', formData.direccion);
    formDataToSend.append('rfc', formData.rfc);
    formDataToSend.append('tamanio', formData.tamanio);
    formDataToSend.append('estado', formData.estado);
    formDataToSend.append('municipio', formData.municipio);
    formDataToSend.append('especialidad', formData.especialidad);
    formDataToSend.append('sede', formData.sede);
    formDataToSend.append('fundacion', formData.fundacion);
    formDataToSend.append('sitioWeb', formData.sitioWeb);
    formDataToSend.append('telefono', formData.telefono);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('colorHex', formData.colorHex);
    formDataToSend.append('logo', formData.logo);
    formDataToSend.append('portada', formData.portada);
    formDataToSend.append('googleUrl', formData.googleUrl);
    formDataToSend.append('whatsapp', formData.whatsapp);
    formDataToSend.append('mision', formData.mision);
    formDataToSend.append('vision', formData.vision);
    formDataToSend.append('instagram', formData.instagram);
    formDataToSend.append('facebook', formData.facebook);
    formDataToSend.append('linkedin', formData.linkedin);

    try {
      const response = await fetch(`${API_URL}updateEmpresas.php`, {
        method: 'POST',
        body: formDataToSend,
        headers: {
          "Authorization": `Bearer ${token}`,
          /*"Content-Type": "application/x-www-form-urlencoded",*/
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Headers": "POST",
          "Access-Control-Allow-Credentials": true
        }
      });


      console.log(response);
      console.log(formData);
      const responseData = await response.json();
      console.log(responseData); // Aquí puedes manejar la respuesta del servidor
      setIsOpenSpinner(false);
      setShowToast(true);

    } catch (error) {
      console.error('Error:', error);
      setIsOpenSpinner(false);
    }
  }

  const [empresas, setEmpresas] = useState([]);

  useEffect(() => {

    const idRol_fk = localStorage.getItem("idRol_fk");
    if (idRol_fk === "2") {
      
      Buscar();
    }else{
      setPermiso("* No cuenta con los permisos suficientes para editar")
    }

    if (token == null || token == undefined || token == "") {
      navigate("/login");
    } else {

      fetch(`${API_URL}getUsuarios.php`)
        .then((response) => response.text())
        .then((text) => {
          const startIndex = text.indexOf("[{");
          const jsonResponse =
            startIndex !== -1 ? text.substring(startIndex) : text;

          try {
            const responseData = JSON.parse(jsonResponse);

            setSuccessMessage(responseData.mensaje);
            setEmpresas(responseData);
          } catch (error) {
            console.error("Error al analizar JSON:", error);
            setError("Error de formato en la respuesta del servidor");
          }
        })
        .catch((error) => {
          console.error("Error del servidor:", error);
          setIsOpenSpinner(false);
          setError(error.statusText || "Error desconocido");
        });
    }
  }, []);

  return (
    <div className=" absolute left-0 right-0 h-screen justify-between">
      <Headers></Headers>
      <Spinner isOpen={IsOpenSpinner}></Spinner>{" "}
      {showToast && (
        <Toast isOpen={true} title="Éxito" text="Usuario registrado" />
      )}{" "}

      <form class=" mt-20 pb-20" autoComplete="off" onSubmit={patch === false ? handleSubmit : handlePatch}>
        <div class="xl:max-w-[85%] md:max-w-md xs:max-w-full max-w-lg mx-auto mt-20 border-red-900 p-5">
          <div class="grid grid-cols-1 gap-1">
            <label class="text-3xl justify-center items-center font-semibold text-red-900 flex ">
              <box-icon type="solid" name="business" color="#7F1D1D"></box-icon>{" "}
              {patch === false ? (<>Registra tu empresa</>) : (
                <>Actualizar los datos de tu empresa</>
              )}
            </label>
          </div>
          <div class="grid grid-cols-1 gap-1 mt-2">
            <div class="border-b border-gray-900/10 text-left">
              <h2 class="text-red-900 text-base font-semibold leading-7 text-gray-900">De la Empresa</h2>
            </div>
          </div>
          <div class="grid grid-cols-5 gap-2 mt-5 mb-5">
            <div class="sm:col-span-1 xs:col-span-5">
              <label for="floating_name-name" class="block text-sm font-medium leading-6 text-gray-900 xs:text-xs"> {" "}Nombre{" "}</label>
              <div class="">
                <input type="text" id="floating_name" value={formData.nombre} onChange={handleChange} name="nombre" class="sm:leading-6 block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
              </div>
            </div>
            <div class="sm:col-span-1">
              <label
                for="descripcion"
                class="block text-sm font-medium leading-6 text-gray-900"
              >
                {" "}
                Giro{" "}
              </label>
              <div>
                <select
                  name="giro"
                  id="giro"
                  value={formData.giro}
                  onChange={handleChange}
                  class="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder=" "
                  required
                >
                  <option value="">Giro</option>
                  <option value="tecnologia">Tecnología</option>
                  <option value="industrial">Industrial</option>
                  <option value="alimentos">Alimentos</option>
                  <option value="servicios">Servicios</option>
                  <option value="construccion">Construcción</option>
                  <option value="educacion">Educación</option>
                  <option value="salud">Salud</option>
                  <option value="entretenimiento">Entretenimiento</option>
                  <option value="comercio">Comercio</option>
                  <option value="otro">Otro</option>
                </select>
              </div>
            </div>
            <div class="sm:col-span-1">
              <label
                for="direccion"
                class="block text-sm font-medium leading-6 text-gray-900"
              >
                {" "}
                Dirección{" "}
              </label>
              <div>
                <input
                  type="text"
                  name="direccion"
                  id="direccion"
                  value={formData.direccion}
                  onChange={handleChange}
                  class="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder=" "
                  required
                />
              </div>
            </div>
            <div class="sm:col-span-1">
              <label
                for="rfc"
                class="block text-sm font-medium leading-6 text-gray-900"
              >
                {" "}
                RFC{" "}
              </label>
              <input
                type="text"
                name="rfc"
                id="rfc"
                value={formData.rfc}
                onChange={handleChange}

                class="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=" "
                required
              />

            </div>
            <div class="sm:col-span-1">
              <label for="tamanio" class="block text-sm font-medium leading-6 text-gray-900">
                {" "}
                Tamaño{" "}
              </label>
              <select
                name="tamanio"
                id="tamanio"
                value={formData.tamanio}
                onChange={handleChange}
                class="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                <option selected>Tamaño de la empresa</option>
                <option value="De 1 a 19 empleados">De 1 a 19 empleados</option>
                <option value="De 20 a 99 empleados">
                  De 20 a 99 empleados
                </option>
                <option value="De 100 a 499 empleados">
                  De 100 a 499 empleados
                </option>
              </select>
            </div>
          </div>
          <div class="grid grid-cols-5 gap-2 mt-5 mb-5">
            <div class="sm:col-span-1">
              <label
                for="estado"
                class="block text-sm font-medium leading-6 text-gray-900"
              >
                {" "}
                Estado{" "}
              </label>
              <select
                name="estado"
                id="estado"
                value={formData.estado}
                onChange={handleChange}
                class="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                required
              >
                <option selected>Estado</option>
                <option value="">Selecciona estado</option>
                <option value="AGUASCALIENTES">AGUASCALIENTES</option>
                <option value="BAJA CALIFORNIA">BAJA CALIFORNIA</option>
                <option value="BAJA CALIFORNIA SUR">BAJA CALIFORNIA SUR</option>
                <option value="CAMPECHE">CAMPECHE</option>
                <option value="COAHUILA DE ZARAGOZA">
                  COAHUILA DE ZARAGOZA
                </option>
                <option value="COLIMA">COLIMA</option>
                <option value="CHIAPAS">CHIAPAS</option>
                <option value="CHIHUAHUA">CHIHUAHUA</option>
                <option value="CIUDAD DE MÉXICO">CIUDAD DE MÉXICO</option>
                <option value="DURANGO">DURANGO</option>
                <option value="GUANAJUATO">GUANAJUATO</option>
                <option value="GUERRERO">GUERRERO</option>
                <option value="HIDALGO">HIDALGO</option>
                <option value="JALISCO">JALISCO</option>
                <option value="ESTADO DE MÉXICO">ESTADO DE MÉXICO</option>
                <option value="MICHOACÁN">MICHOACÁN</option>
                <option value="MORELOS">MORELOS</option>
                <option value="NAYARIT">NAYARIT</option>
                <option value="NUEVO LEÓN">NUEVO LEÓN</option>
                <option value="OAXACA">OAXACA</option>
                <option value="PUEBLA">PUEBLA</option>
                <option value="QUERÉTARO">QUERÉTARO</option>
                <option value="QUINTANA ROO">QUINTANA ROO</option>
                <option value="SAN LUIS POTOSÍ">SAN LUIS POTOSÍ</option>
                <option value="SINALOA">SINALOA</option>
                <option value="SONORA">SONORA</option>
                <option value="TABASCO">TABASCO</option>
                <option value="TAMAULIPAS">TAMAULIPAS</option>
                <option value="TLAXCALA">TLAXCALA</option>
                <option value="VERACRUZ">VERACRUZ</option>
                <option value="YUCATÁN">YUCATÁN</option>
                <option value="ZACATECAS">ZACATECAS</option>
              </select>
            </div>
            <div class="sm:col-span-1">
              <label
                for="municipio"
                class="block text-sm font-medium leading-6 text-gray-900"
              >
                {" "}
                Municipio{" "}
              </label>
              <input
                type="text"
                name="municipio"
                id="municipio"
                value={formData.municipio}
                onChange={handleChange}
                class="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=" "
                required
              />

            </div>
            <div class="sm:col-span-1">
              <label
                for="especialidad"
                class="block text-sm font-medium leading-6 text-gray-900"
              >
                {" "}
                Especialidad{" "}
              </label>

              <input
                type="text"
                name="especialidad"
                id="especialidad"
                value={formData.especialidad}
                onChange={handleChange}
                class="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=" "
                required
              />


            </div>
            <div class="sm:col-span-1">
              <label
                for="sede"
                class="block text-sm font-medium leading-6 text-gray-900"
              >
                {" "}
                Sede{" "}
              </label>

              <input
                type="text"
                name="sede"
                id="sede"
                value={formData.sede}
                onChange={handleChange}
                class="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=" "
                required
              />

            </div>
            <div class="sm:col-span-1">
              <label
                for="fundacion"
                class="block text-sm font-medium leading-6 text-gray-900"
              >
                {" "}
                Fundación{" "}
              </label>

              <input
                type="date"
                name="fundacion"
                id="fundacion"
                value={formData.fundacion}
                onChange={handleChange}
                class="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=" "
                required
              />


            </div>
          </div>

          <div class="grid grid-cols-1 gap-1 mt-2 ">
            <div class="border-b border-gray-900/10 text-left">
              <h2 class="text-red-900 text-base font-semibold leading-7 text-gray-900">De contacto</h2>
            </div>
          </div>
          <div class="grid grid-cols-5 gap-2 mt-5">

            <div class="sm:col-span-1">
              <label
                for="googleUrl"
                class="block text-sm font-medium leading-6 text-gray-900"
              >
                {" "}
                Dirección google maps{" "}
              </label>
              <input
                type="url"
                name="googleUrl"
                id="googleUrl"
                value={formData.googleUrl}
                onChange={handleChange}
                class="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=" "
                required
              />

            </div>

            <div class="sm:col-span-1">
              <label
                for="telefono"
                class="block text-sm font-medium leading-6 text-gray-900"
              >
                {" "}
                Tel&eacute;fono{" "}
              </label>
              <div class="relative z-0 w-full mb-5 group">
                <input
                  type="number"
                  name="telefono"
                  id="telefono"
                  value={formData.telefono}
                  onChange={handleChange}
                  class="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder=" "
                  required
                />

              </div>
            </div>
            <div class="sm:col-span-1">
              <label
                for="email"
                class="block text-sm font-medium leading-6 text-gray-900"
              >
                {" "}
                Email{" "}
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                class="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=" "
                required
              />

            </div>

          </div>
          <div class="grid grid-cols-5 gap-1 ">
            <div class="sm:col-span-1">
              <label
                for="sitioWeb"
                class="block text-sm font-medium leading-6 text-gray-900"
              >
                {" "}
                Sitio web{" "}
              </label>
              <input
                type="url"
                name="sitioWeb"
                id="sitioWeb"
                value={formData.sitioWeb}
                onChange={handleChange}
                class="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=" "
                required
              />

            </div>
            <div class="sm:col-span-1">
              <label
                for="colorHex"
                class="block text-sm font-medium leading-6 text-gray-900"
              >
                {" "}
                Whatsapp URL{" "}
              </label>
              <input
                type="url"
                name="whatsapp"
                id="whatsapp"
                value={formData.whatsapp}
                onChange={handleChange}
                class="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="https://api.whatsapp.com/send?phone=()&text=()"
                required
              />
            </div>
            <div class="sm:col-span-1">
              <label
                for="sitioWeb"
                class="block text-sm font-medium leading-6 text-gray-900"
              >
                {" "}
                Instagram{" "}
              </label>
              <input
                type="url"
                name="instagram"
                id="instagram"
                value={formData.instagram}
                onChange={handleChange}
                class="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=" "
                required
              />

            </div>
            <div class="sm:col-span-1">
              <label
                for="facebook"
                class="block text-sm font-medium leading-6 text-gray-900"
              >
                {" "}
                Facebook{" "}
              </label>
              <input
                type="url"
                name="facebook"
                id="facebook"
                value={formData.facebook}
                onChange={handleChange}
                class="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=" "
                required
              />

            </div>
            <div class="sm:col-span-1">
              <label
                for="linkedin"
                class="block text-sm font-medium leading-6 text-gray-900"
              >
                {" "}
                Linkedin{" "}
              </label>
              <input
                type="url"
                name="linkedin"
                id="linkedin"
                value={formData.linkedin}
                onChange={handleChange}
                class="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=" "
                required
              />

            </div>
          </div>
          <div class="grid grid-cols-1 gap-1 mt-2 ">
            <div class="border-b border-gray-900/10 text-left">
              <h2 class="text-red-900 text-base font-semibold leading-7 text-gray-900">Recursos para portada</h2>
            </div>
          </div>
          <div class="grid grid-cols-5 gap-2 mt-5">
            <div class="sm:col-span-1">
              <label
                for="colorHex"
                class="block text-sm font-medium leading-6 text-gray-900"
              >
                {" "}
                Color Hex{" "}
              </label>
              <div class="relative z-0 w-full mb-5 group">
                <input
                  type="color"
                  name="colorHex"
                  id="colorHex"
                  value={formData.colorHex}
                  onChange={handleChange}
                  class="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder=" "
                  required
                />

              </div>
            </div>

            <div class="sm:col-span-2">
              <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" for="logo">Imagen de logo</label>
              <input onChange={handleFileChange} aria-describedby="logo" id="logo" name="logo" type="file" required class="block  w-full text-xs  text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" />
              <p class="mt-1 text-xs text-gray-500 mb-5 dark:text-gray-300" id="logo">.png, .jpg(MAX. 800x400px).</p>


              {/*<div class="mt-1 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-2 py-2">
                <div class="text-center">
                  <svg class="mx-auto h-12 w-12 text-gray-300" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z" clip-rule="evenodd" />
                  </svg>
                  <div class="mt-1 flex text-sm leading-6 text-gray-600">
                    <label for="logo" class="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
                      <span>Carga tu logo</span>
                      <input id="logo" name="logo" type="file" class="sr-only"  onChange={handleFileChange}></input>
                    </label>
                  </div>
                  <p class="text-xs leading-5 text-gray-600">.png, .jpg</p>
                </div>
              </div>*/}


            </div>
            <div class="sm:col-span-2">

              <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" for="portada">Imagen de portada</label>
              <input onChange={handleFileChange} aria-describedby="portada" id="portada" name="portada" type="file" required class="block w-full text-xs text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" />
              <p class="mt-1 text-xs text-gray-500 mb-5 dark:text-gray-300" id="portada">.png, .jpg(MAX. 800x400px).</p>
              {/*<div class="mt-1 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-2 py-2">
                <div class="text-center">
                  <svg class="mx-auto h-12 w-12 text-gray-300" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z" clip-rule="evenodd" />
                  </svg>
                  <div class="mt-1 flex text-sm leading-6 text-gray-600">
                    <label for="portada" class="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
                      <span>Carga tu portada</span>
                      <input id="portada" name="portada"  onChange={handleFileChange} type="file" class=""></input>
                    </label>
                  </div>
                  <p class="tex
                  t-xs leading-5 text-gray-600">.png, .jpg</p>
                </div>
              </div>*/}

            </div>
            <div class="sm:col-span-2">
              <label
                for="mision"
                class="block text-sm font-medium leading-6 text-gray-900"
              >
                {" "}
                Misión{" "}
              </label>
              <div>
                <textarea
                  type="text"
                  name="mision"
                  id="mision"
                  value={formData.mision}
                  onChange={handleChange}
                  class="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder=" "
                  required
                />
              </div>

            </div>
            <div class="sm:col-span-2">
              <label
                for="vision"
                class="block text-sm font-medium leading-6 text-gray-900"
              >
                {" "}
                Visión{" "}
              </label>
              <div>
                <textarea
                  type="text"
                  name="vision"
                  id="vision"
                  value={formData.vision}
                  onChange={handleChange}
                  class="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder=" "
                  required
                />
              </div>

            </div>
          </div>
          <div class="grid grid-cols-1  mt-5 mb-20 text-center">

            {patch === false ? (
              <button
                type="submit"
                className=" text-white cursor-point items-center  btn-rojo font-medium rounded-lg text-sm  px-5 py-1 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
              >
                <div className="flex justify-center items-center">
                  <box-icon name="save" color="white"></box-icon>
                  Registrar
                </div>
              </button>
            ) : (
              <button
                type="submit"
                className="text-white btn-rojo font-medium rounded-lg text-sm  px-5 py-1 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
              >
                <div className="flex justify-center items-center">
                  <box-icon name="save" color="white"></box-icon>
                  Actualizar
                </div>
              </button>
            )}
            <span className="text-sm text-red-700 mt-3">{permiso}</span>


          </div>

        </div>
      </form >

      <div className="fixed bottom-0 left-0   right-0">
        <Footer></Footer>
      </div>
    </div >
  );
}
