import { useEffect, useRef } from "react";

function Landing (){
 
 return(
    <>
    <section className="md:flex block md:m-0 m-4 md:mt-20 mt-20">
   <div className="md:w-2/3 w-full h-[50vh] m-2  flex items-center justify-center rounded-xl bg-red-800">
     <div>
       <h3 className="text-white text-3xl font-bold">La revolución de los enlaces industriales está aquí <br></br> ¡Te conectamos con la industria! <br></br> Somos más que networking </h3>
       <button className="bg-gray-200 px-10 py-2 mt-10 rounded-3xl"> Empezar ahora</button>
     </div>
   </div>
   <div className="md:w-1/3 w-full md:h-[50vh] h-[30vh] m-2  rounded-xl bg-gray-200">
   <video  className="w-full h-full object-cover rounded-xl" controls preload="auto" src="https://clubproveedoresdelaindustria.com/api/recursos/clubProveedores.mp4" type="video/mp4" 
    >	 
	</video>     
   </div>
 </section>
 <section className="md:flex block md:m-0 m-4">
   <div className="md:w-2/3 w-full h-[30vh] bg-red-300 m-2 flex items-center justify-start rounded-xl relative overflow-hidden">
     <div className="text-right z-10 w-full m-10 relative">
       <h3 className="text-white text-center text-1xl font-bold">Conectamos visiones empresariales, para forjar enlaces estratégicos con grandes industrias. Juntos construimos puentes de oportunidades que fomentan el crecimiento mutuo. ¡Únete a nuestra red y eleva tu potencial de negocios! </h3> {/* <button className="bg-gray-200 px-10 py-2 mt-5 rounded-3xl">Contactar</button>*/}
     </div>
     <img className="h-full w-full absolute object-cover rounded-xl" src="https://tradulingua.com/wp-content/uploads/2022/08/networking-que-es.jpg" />
     <div className="absolute inset-0 bg-red-800 bg-opacity-50"></div>
   </div>
   <div className="md:w-1/3 w-full h-[30vh] m-2 flex justify-center items-center rounded-xl bg-red-800">
     <h3 className="text-white  text-center text-2xl m-1 font-bold">Descarga nuestra presentación y conoce nuestro método </h3>
   </div>
 </section>
</>
 )
}
export default Landing;
