/*import React, {useEffect}from "react";*/
import Header from "../../Components/HeaderPublic";
import Landing from "../../Components/Landing";
import Clients from "../../Components/Clients";
import About from "../../Components/About";
import Contact from "../../Components/Contact";
import Button from "../../Components/ButtonWhatsapp";
import Footer from "../../Components/Footer";
/*import FaqAccordion from "../../Components/FaqAccordion";*/
/*import API_URL from "../../const"*/
import CustomizedAccordions from "../../Components/FaqAccordions";

export default function Home() {
  
  /*useEffect(() => {

    fetch(`${API_URL}conexion.php`)
      .then(response => response.json())
      .then(data => {
        console.log(data);
      })
      .catch(error => console.error('Error al realizar la solicitud:', error));
  }, []);*/

  return (
    <div className=" absolute left-0 right-0   h-screen justify-between">
    <Header/>
    <Landing/>
    <Clients/>
    <About/>
    <CustomizedAccordions />

    <Contact/>
    <Button/>
    <Footer/>
    </div>
  );
}
