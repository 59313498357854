import { AreaChart, BarChart } from "@tremor/react";
import FooterTransparent from "../../Components/Footer";
import API_URL from "../../const";
import { useEffect, useState } from "react";
import Headers from "../../Components/HeaderPublic";
import CreateNewsLetter from '../CreateNewsLetter/CreateNewsLetter.jsx';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

function TerminoPage() {


  const [terminoData, setTerminoData] = useState('');


  const getTermino = () => {


    fetch(`${API_URL}getTerminos.php`, {
      headers: {
        'Content-Type': 'application/json'
      }
    },)
      .then((response) => response.text())
      .then((text) => {
        const startIndex = text.indexOf("[{");
        const jsonResponse =
          startIndex !== -1 ? text.substring(startIndex) : text;

        try {
          const responseData = JSON.parse(jsonResponse);
          console.log("Respuesta del servidor:", jsonResponse);
          console.log("Respuesta del servidor:", responseData);

          setTerminoData(responseData);


        } catch (error) {
          console.error("Error al analizar JSON:", error);
        }
      })
      .catch((error) => {
        console.error("Error del servidor:", error);
      });
  };

  useEffect(() => {
    getTermino();

  }, [])

  return (

    <div className="w-full absolute left-0 right-0 bottom-0 top-0">
      <Headers></Headers>
      <div className="pb-10 pt-24 px-5 bg-gray-100">
        <div className="xl:flex block">
          <div className=" w-full bg-white border m-0 rounded-lg  dark:bg-gray-800 p-2 md:p-4">

            <div className="flex justify-center w-full tamanoConenedor scrollbar styleScrollbar margenResultado">

              {typeof terminoData.error === 'undefined' && terminoData.length !== 0 ? (

                terminoData.map((item) => (
                  <div className="w-full text-sm p-4 text-justify">{item.terminosCondiciones}</div>

                ))
              ) : (
                <div><h2>Sin Registros</h2></div>
              )}

            </div>
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 left-0 right-0">
        <FooterTransparent></FooterTransparent>
      </div>
    </div>
  );
}
export default TerminoPage;