import React, { useState, useEffect } from "react";
import "boxicons";
export default function Card({
  Buscar,
  searchCard,
  searchEstado,
  BuscarEstado,
  searchMunicipio,
  isAuthorized
}) {
  return (
    <>
    <div class="grid grid-cols-1 gap-8 mt-8 xl:mt-16 md:grid-cols-2 xl:grid-cols-4">
      {" "}
      {Array.isArray(searchCard) && searchCard.length > 0 ? (
        searchCard.map((user) => (
          <div
            key={user.id}
            onClick={() => {
              
              Buscar && isAuthorized && Buscar(user.uuid);
            }}
            className="flex flex-col items-center p-3 transition-colors duration-300 transform border cursor-pointer rounded-xl hover:border-transparent group hover:bg-gray-100 dark:border-gray-700 dark:hover:border-transparent"
          >
            {" "}
            <img
              class="object-cover w-32 h-32 rounded-full ring-4 ring-gray-300"
              src={user.logo}              
            />
            <h1 className="mt-4 text-2xl font-semibold text-gray-700 capitalize dark:text-white group-hover:text-black">
              {" "}
              {user.nombre}{" "}
            </h1>
            <p className="mt-2 text-gray-500 dark:text-gray-300 ">
              {" "}
              {user.giro}{" "}
            </p>
          </div>
        ))
      ) : (
        <p></p>
      )}

    </div>
     <div class="grid grid-cols-1 gap-8 mt-8 xl:mt-2 md:grid-cols-2 xl:grid-cols-4">
     {" "}
     {Array.isArray(searchEstado) && searchEstado.length > 0 ? (
       searchEstado.map((user) => (
         <div
           key={user.id}
           onClick={() => {
             
             Buscar && isAuthorized && Buscar(user.nombre);
           }}
           className="flex flex-col items-center p-8 transition-colors duration-300 transform border cursor-pointer rounded-xl hover:border-transparent group hover:bg-red-600 dark:border-gray-700 dark:hover:border-transparent"
         >
           {" "}
           <img
             class="object-cover w-32 h-32 rounded-full ring-4 ring-gray-300"
             src={user.logo}
             alt=""
           />
           <h1 className="mt-4 text-2xl font-semibold text-gray-700 capitalize dark:text-white group-hover:text-white">
             {" "}
             {user.nombre}{" "}
           </h1>
           <p className="mt-2 text-gray-500 dark:text-gray-300 group-hover:text-gray-300">
             {" "}
             {user.especialidad}{" "}
           </p>
         </div>
       ))
     ) : (
       <p></p>
     )}

   </div>
   <div class="grid grid-cols-1 gap-8 mt-8 xl:mt-2 md:grid-cols-2 xl:grid-cols-4">
     {" "}
     {Array.isArray(searchMunicipio) && searchMunicipio.length > 0 ? (
       searchMunicipio.map((user) => (
         <div
           key={user.id}
           onClick={() => {             
             Buscar && isAuthorized  && Buscar(user.nombre);
           }}
           className="flex flex-col items-center p-8 transition-colors duration-300 transform border cursor-pointer rounded-xl hover:border-transparent group hover:bg-red-600 dark:border-gray-700 dark:hover:border-transparent"
         >
           {" "}
           <img
             class="object-cover w-32 h-32 rounded-full ring-4 ring-gray-300"
             src={user.logo}
             alt=""
           />
           <h2 className="mt-4 text-2xl font-semibold text-gray-700 capitalize dark:text-white group-hover:text-white">
             {" "}
             {user.nombre}{" "}
           </h2>
           <h3 className="mt-2 text-gray-500 dark:text-gray-300 group-hover:text-gray-300">
             {" "}
             {user.especialidad}{" "}
           </h3>
         </div>
       ))
     ) : (
       <p></p>
     )}

   </div>
    </>

  );
}
