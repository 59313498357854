import FooterTransparent from "../../Components/footerTransparente";
import API_URL from "../../const";
import { useEffect, useState } from "react";
import Headers from "../../Components/HeaderPrivate";
import Toast from "../../Components/Toast/Toast";
import ToastError from "../../Components/Toast/ToastError";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Spinner from "../../Components/Spinner/Spinner";
import { type } from "@testing-library/user-event/dist/type";

function AnalyticsPage() {

  const [contactosData, setContactosData] = useState('');
  const [usuariosData, setUsuariosData] = useState('');
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [activeTab, setActiveTab] = useState(0);
  const [showToast, setShowToast] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [msg, setMsg] = useState("");
  const [error, setError] = useState(null);
  const [IsOpenSpinner, setIsOpenSpinner] = useState(false);
  const [newsletter, setNewsletter] = useState('');
  const [evento, setEvento] = useState('');
  const [formData, setFormData] = useState({
    id:'',
    nombre: '',
    descripcion: "",
    url_pdf: null, // Changed to null to hold the file object
    imagen: null, // Changed to null to hold the file object
    type:1
  });
  const [formEvento, setFormEvento] = useState({
    id:null,
    nombre: '',
    descripcion: "",
    fecha: "",
    hora: "",
    imagen: null,
    ubicacion: "",
    type:1
  });

  const handleInputChangeBoletin = (e) => {
    // Handling input changes for text fields
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleInputChangeEvento = (e) => {
    // Handling input changes for text fields
    const { name, value } = e.target;
    setFormEvento({ ...formEvento, [name]: value });
  };

  const handleFileChangeBoletin = (e) => {
    // Handling input changes for file inputs
    const { name, files } = e.target;
    setFormData({ ...formData, [name]: files[0] }); // Assuming only single file selection
  };
  const handleFileChangeEvento = (e) => {
    // Handling input changes for file inputs
    const { name, files } = e.target;
    setFormEvento({ ...formEvento, [name]: files[0] }); // Assuming only single file selection
  };

 
  const boletinGet = () => {
    fetch(`${API_URL}getBoletin.php`, {
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
        

      }
     
    })

    .then((response) => {
      if (response.status === 401) {
        // Si obtenemos un 401, redirigimos al login
        localStorage.removeItem("token");
        navigate('/login');

        return; // Salimos de la función para evitar procesar la respuesta
      }

      // Continuamos si el estado no es 401
      return response.text();
    })
      .then((text) => {
        const startIndex = text.indexOf("[{");
        const jsonResponse =
          startIndex !== -1 ? text.substring(startIndex) : text;

        try {
          const responseData = JSON.parse(jsonResponse);
          console.log("Respuesta del servidor:", responseData);

          setNewsletter(responseData);
          console.log(newsletter)
        } catch (error) {
          console.error("Error al analizar JSON:", error);


        }
      })
      .catch((error) => {
        console.error("Error del servidor:", error);

      });
  }

  const getEvento = () => {
    console.log(token)
    fetch(`${API_URL}getEventos.php`, {
      headers: {
     
        "Content-Type": "application/json",
        

      }
      
    })

    .then((response) => {
      if (response.status === 401) {
        // Si obtenemos un 401, redirigimos al login
        localStorage.removeItem("token");
        navigate('/login');

        return; // Salimos de la función para evitar procesar la respuesta
      }

      // Continuamos si el estado no es 401
      return response.text();
    })
      .then((text) => {
        const startIndex = text.indexOf("[{");
        const jsonResponse =
          startIndex !== -1 ? text.substring(startIndex) : text;

        try {
          const responseData = JSON.parse(jsonResponse);
          console.log("Respuesta del servidor:", responseData);
           if(responseData[0].codigo !== '404'){
            setEvento(responseData);
           }
        
          console.log(newsletter)
        } catch (error) {
          console.error("Error al analizar JSON:", error);


        }
      })
      .catch((error) => {
        console.error("Error del servidor:", error);

      });
  }
  const handleSubmitBoletin = async (e) => {
    e.preventDefault();
    setIsOpenSpinner(true);

    const formDataToSend = new FormData();
    formDataToSend.append('nombre', formData.nombre);
    formDataToSend.append('descripcion', formData.descripcion);
    formDataToSend.append('imagen', formData.imagen);
    formDataToSend.append('url_pdf', formData.url_pdf);
      console.log(formData)
    try {
      const response = await fetch(`${API_URL}insertBoletin.php`, {
        method: "POST",
        body: formDataToSend,
        headers: {
          "Authorization": `Bearer ${token}`
        
        },
         
      });
      
      if (response.status === 401) {
        // Si obtenemos un 401, redirigimos al login
        localStorage.removeItem("token");
        navigate('/login');

        return; // Salimos de la función para evitar procesar la respuesta
      }
      if (response.ok) {
        setIsOpenSpinner(false);
        setShowToast(true);
        boletinGet();
        setTimeout(() => {
          setShowToast(false);
        }, 5000);

        console.log("Boletín registrado exitosamente.");

        setFormData({
          nombre: '',
          descripcion: "",
          url_pdf: null,
          imagen: null,
        });
      } else {
        console.error("Error al registrar el boletín:", response.statusText);
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
    }
  };

  const handleSubmitEvento = async (e) => {
    e.preventDefault();
    setIsOpenSpinner(true);
 
    const formDataToSend2 = new FormData();
    formDataToSend2.append('nombre', formEvento.nombre);
    formDataToSend2.append('descripcion', formEvento.descripcion);
    formDataToSend2.append('fecha', formEvento.fecha);
    formDataToSend2.append('hora', formEvento.hora);
    formDataToSend2.append('imagen', formEvento.imagen);
    formDataToSend2.append('ubicacion', formEvento.ubicacion);
   console.log(formEvento)
    try {
      const response = await fetch(`${API_URL}insertEvento.php`, {
    
        method: "POST",
        body: formDataToSend2,
        headers: {
          "Authorization": `Bearer ${token}`,
         
        }
      });
      console.log(response)
      if (response.status === 401) {
        // Si obtenemos un 401, redirigimos al login
        localStorage.removeItem("token");
        navigate('/login');

        return; // Salimos de la función para evitar procesar la respuesta
      }
      if (response.ok) {
        setIsOpenSpinner(false);
        setShowToast(true);
        getEvento();
        setTimeout(() => {
          setShowToast(false);
        }, 5000);




        setFormEvento({
          nombre: '',
          descripcion: "",
          fecha: "",
          hora: "",
          ubicacion: ""
        });
      } else {
        console.error("Error al registrar el boletín:", response.statusText);
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
    }
  };


  const handleDeleteBoletin = (id) => {
    fetch(`${API_URL}deleteBoletin.php?id=${id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (response.status === 401) {
          // Si obtenemos un 401, redirigimos al login
          localStorage.removeItem("token");
          navigate('/login');
  
          return; // Salimos de la función para evitar procesar la respuesta
        }
        if (response.ok) {
          setIsOpenSpinner(false);
          setShowToast(true);
          setTimeout(() => {
            setShowToast(false);
          }, 5000);
          boletinGet();
          console.log('Boletín eliminado correctamente');
          return response.json();
        } else {
          throw new Error('Error al eliminar el boletín');
        }
      })
      .then(data => {
        console.log(data.message);

      })
      .catch(error => {
        console.log(error);

      });
  };
  const handleDeleteEvento = (id) => {
    fetch(`${API_URL}deleteEventos.php?id=${id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (response.status === 401) {
          // Si obtenemos un 401, redirigimos al login
          localStorage.removeItem("token");
          navigate('/login');
  
          return; // Salimos de la función para evitar procesar la respuesta
        }
        if (response.ok) {
          setIsOpenSpinner(false);
          setShowToast(true);
          getEvento();
          setTimeout(() => {
            setShowToast(false);
          }, 5000);
         
          console.log('Evento eliminado correctamente');
          return response.json();
        } else {
          throw new Error('Error al eliminar el evento');
        }
      })
      .then(data => {
        console.log(data.message);

      })
      .catch(error => {
        console.log(error);

      });
  };

  const getUsuarios = () => {
    console.log(`${API_URL}getContactanos.php`);

    fetch(`${API_URL}getUsuarios.php`, {
      headers: {
        
        'Content-Type': 'application/json'
      }
      

    },)
    .then((response) => {
      if (response.status === 401) {
        // Si obtenemos un 401, redirigimos al login
        localStorage.removeItem("token");
        navigate('/login');

        return; // Salimos de la función para evitar procesar la respuesta
      }

      // Continuamos si el estado no es 401
      return response.text();
    })
      .then((text) => {
        const startIndex = text.indexOf("[{");
        const jsonResponse =
          startIndex !== -1 ? text.substring(startIndex) : text;

        try {
          const responseData = JSON.parse(jsonResponse);

          /*console.log("Respuesta del servidor:", responseData);*/

          setUsuariosData(responseData);
          console.log(responseData)

        } catch (error) {
          console.error("Error al analizar JSON:", error);
        }
      })
      .catch((error) => {
        console.error("Error del servidor:", error);
      });
  };
  const handleUpdatePerson = async (id) => {
    

    setIsOpenSpinner(true);
    
    const formDataToSendEvent = new FormData();
    formDataToSendEvent.append('id', id);
    formDataToSendEvent.append('imagen', formEvento.imagen);
    formDataToSendEvent.append('nombre', formEvento.nombre);
    formDataToSendEvent.append('descripcion', formEvento.descripcion);
    formDataToSendEvent.append('fecha', formEvento.fecha);
    formDataToSendEvent.append('horario', formEvento.hora);
    formDataToSendEvent.append('ubicacion', formEvento.ubicacion);
   console.log(formDataToSendEvent)
   console.log(id)
    try {
      const response = await fetch(`${API_URL}updateEvento.php`, {
        method: "POST",
        body: formDataToSendEvent,

      });
      
      if (response.status === 401) {
        // Si obtenemos un 401, redirigimos al login
        localStorage.removeItem("token");
        navigate('/login');

        return; // Salimos de la función para evitar procesar la respuesta
      }
      if (response.ok) {
        setIsOpenSpinner(false);
        setShowToast(true);
        getEvento();
        setTimeout(() => {
          setShowToast(false);
        }, 5000);
        setFormEvento({
         
          nombre: '',
          descripcion: '',
          fecha: '',
          hora: '',
          imagen: '',
          ubicacion: ''
          
        });
     
        // Handle success
       //console.log("Personal registrado exitosamente.");
      } else {
        // Handle error
        console.error("Error al registrar el newsletter:");
        setMsg("error");
        setIsOpenSpinner(false);
        setShowToastError(true);
        setTimeout(() => {
          setShowToastError(false);
        }, 5000);
    
      }
    } catch (error) {
      console.error("Error en la solicitud:", error.message);

      setMsg(`Error: ${error.message}`);
      setIsOpenSpinner(false);
      setShowToastError(true);
      setTimeout(() => {
        setShowToastError(false);
      }, 5000);
     
    }

  };
  const handleUpdateBoletin = async () => {
    
    
    setIsOpenSpinner(true);
    
    const formDataToSendBoletin = new FormData();
    formDataToSendBoletin.append('id', formData.id);
    
    formDataToSendBoletin.append('imagen', formData.imagen);
    formDataToSendBoletin.append('url_pdf', formData.url_pdf);
    formDataToSendBoletin.append('descripcion', formData.descripcion);
    formDataToSendBoletin.append('nombre', formData.nombre);
   

    try {
      const response = await fetch(`${API_URL}updateBoletin.php`, {
        method: "POST",
        body: formDataToSendBoletin,

      });
      //console.log(response)
      if (response.status === 401) {
        // Si obtenemos un 401, redirigimos al login
        localStorage.removeItem("token");
        navigate('/login');

        return; // Salimos de la función para evitar procesar la respuesta
      }
      if (response.ok) {
        setIsOpenSpinner(false);
        setShowToast(true);
        boletinGet();
        setTimeout(() => {
          setShowToast(false);
        }, 5000);
        setFormEvento({
         
          nombre: '',
          descripcion: '',
          fecha: '',
          hora: '',
          imagen: '',
          ubicacion: ''
          
        });
     
        // Handle success
        console.log("Personal registrado exitosamente.");
      } else {
        // Handle error
        console.error("Error al registrar el newsletter:");
        setMsg("error");
        setIsOpenSpinner(false);
        setShowToastError(true);
        setTimeout(() => {
          setShowToastError(false);
        }, 5000);
    
      }
    } catch (error) {
      console.error("Error en la solicitud:", error.message);

      setMsg(`Error: ${error.message}`);
      setIsOpenSpinner(false);
      setShowToastError(true);
      setTimeout(() => {
        setShowToastError(false);
      }, 5000);
     
    }

  };
  const updateStatusUsuario = (idrol, uuid) => {
    console.log("updateStatusUsuario: " + idrol);
     
    fetch(`${API_URL}updateStatusUsuario.php`, {
      method: "POST", // Método POST
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`, // Token de autorización
      },
      body: JSON.stringify({
        uuid: uuid,
        idRolFk: idrol
      })
    })
    .then((response) => {
      if (response.status === 401) {
        // Si obtenemos un 401, redirigimos al login
        localStorage.removeItem("token");
        navigate('/login');

        return; // Salimos de la función para evitar procesar la respuesta
      }

      // Continuamos si el estado no es 401
      return response.text();
    })
      .then((text) => {
        const startIndex = text.indexOf("[{");
        const jsonResponse =
          startIndex !== -1 ? text.substring(startIndex) : text;
  
        try {
          const responseData = JSON.parse(jsonResponse);
          setMsg("Usuario actualizado correctamente");
          setShowToast(true);
          console.log(responseData)
          getUsuarios();
          setTimeout(() => {
            setShowToast(false);
          }, 5000);
        } catch (error) {
          setError("Se ha producido un error, favor de intentar más tarde");
          console.error("Error al analizar JSON:", error);
        }
      })
      .catch((error) => {
        setError("Se ha producido un error, favor de intentar más tarde");
        console.error("Error del servidor:", error);
      });
  };
  
  const getContactanos = () => {
    console.log(`${API_URL}getContactanos.php`);

    fetch(`${API_URL}getContactanos.php`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    },)
    .then((response) => {
      if (response.status === 401) {
        // Si obtenemos un 401, redirigimos al login
        localStorage.removeItem("token");
        navigate('/login');

        return; // Salimos de la función para evitar procesar la respuesta
      }

      // Continuamos si el estado no es 401
      return response.text();
    })
      .then((text) => {
        const startIndex = text.indexOf("[{");
        const jsonResponse =
          startIndex !== -1 ? text.substring(startIndex) : text;

        try {
          const responseData = JSON.parse(jsonResponse);

          console.log(responseData);

          setContactosData(responseData);


        } catch (error) {
          console.error("Error al analizar JSON:", error);
        }
      })
      .catch((error) => {
        console.error("Error del servidor:", error);
      });
  };
  const valueFormatter = function (number) {
    return '$ ' + new Intl.NumberFormat('mx').format(number).toString();
  };

  const handleRecoveryPassword = async (email) => {
    setIsOpenSpinner(true);
    
    const requestBody = `email=${encodeURIComponent(email)}`;


    try {
      const response = await fetch(`${API_URL}updateContrasenia.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "POST",
          "Access-Control-Allow-Credentials": true
        },
        body: requestBody,
      });

      const text = await response.text();
      const startIndex = text.indexOf("[{");
      const jsonResponse = (startIndex !== -1) ? text.substring(startIndex) : text;


      const responseData = JSON.parse(jsonResponse);


      if (response.ok) {
        setMsg("Se ha enviado a su email su nueva contraseña");
        setShowToast(true);                
        setIsOpenSpinner(false);
        setTimeout(() => {
          setShowToast(false);
        }, 5000);
      } else {
        setMsg("Ha ocurrido un error, email, no encontrado en base de datos");
        setError(response.statusText || "Error desconocido");
        setIsOpenSpinner(false);
      }

    } catch (error) {
      console.error("Error en la solicitud:", error);
      setMsg("Se ha producido un error, intente más tarde");
      setError("Error de red: " + error.message);
      setIsOpenSpinner(false);
    }
  };
  const handleRoleChange = (idUsuario, newRole) => {
  setUsuariosData((prevData) =>
    prevData.map((item) =>
      item.id_usuario === idUsuario
        ? { ...item, idRolFk: newRole }
        : item
    )
  );
}

  useEffect(() => {
    if (token == null || token == undefined || token == "") {
      navigate("/login");
    } else {
      getContactanos();
      getUsuarios();
      boletinGet();
      getEvento();
    }
  }, [])

  return (

    <div className="w-full absolute left-0 right-0 bottom-0 top-0">
      <Spinner isOpen={IsOpenSpinner}></Spinner>{" "}
      {showToast && (
        <Toast isOpen={true} title="Éxito" text={msg} />
      )}{" "}
      {showToastError && <ToastError isOpen={true} title="Error" text={msg} />}{" "}

      <Headers></Headers>
      <div className="pb-10 pt-24 px-5 bg-gray-100">
        <div className="xl:flex block">
          <div className=" w-full bg-white border m-0 rounded-lg  dark:bg-gray-800 p-2 md:p-4">

            <Tabs onSelect={(index) => {

              setActiveTab(index)
            }} selectedIndex={activeTab}  >
              <TabList style={{ display: "flex", justifyContent: "center", listStyle: "none" }}>
                <Tab style={{ width: "50%", textAlign: "center", cursor: "pointer" }} >
                  <label class="justify-center cursor-pointer items-center flex">
                    <box-icon type="solid" name="user-check" size="sm" ></box-icon>&nbsp;Nuevos usuarios
                  </label>
                </Tab>
                <Tab style={{ width: "50%", textAlign: "center", cursor: "pointer" }}>
                  <label class="justify-center cursor-pointer items-center flex">
                    <box-icon type="solid" name="envelope" size="sm"></box-icon>&nbsp;Registro de contactanos
                  </label>
                </Tab>
                <Tab style={{ width: "50%", textAlign: "center", cursor: "pointer" }}>
                  <label class="justify-center cursor-pointer items-center flex">
                    <box-icon type="solid" name="purchase-tag-alt" size="sm" ></box-icon>&nbsp;Boletines
                  </label>
                </Tab>
                <Tab style={{ width: "50%", textAlign: "center", cursor: "pointer" }}>
                  <label class="justify-center cursor-pointer items-center flex">
                    <box-icon type="solid" name="map-pin" size="sm"></box-icon>&nbsp;Eventos
                  </label>
                </Tab>
              </TabList>
              <TabPanel>
                <div className="flex justify-center w-full tamanoConenedor scrollbar styleScrollbar margenResultado">

                  <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">

                    <thead class="fondo-gris text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" class="px-6 py-2">
                          Id
                        </th>
                        <th scope="col" class="px-6 py-1">
                          Nombre
                        </th>
                        <th scope="col" class="px-6 py-2">
                          Email
                        </th>
                        <th scope="col" class="px-6 py-2">
                          Estatus Empresa
                        </th>
                        <th scope="col" class="px-6 py-2">
                          Empresa
                        </th>
                        <th scope="col" class="px-6 py-2">
                          Fecha
                        </th>
                        <th scope="col" class="px-6 py-2">
                          Acciones
                        </th>
                      </tr>
                    </thead>

                    {typeof usuariosData.error === 'undefined' && usuariosData.length !== 0 ? (
                      <tbody>
                        {usuariosData.map((item) => (
                          <tr key={item.id} class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                            <td scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                              {item.id_usuario}
                            </td>
                            <td class="px-6 py-4">
                              {item.nombreCompleto}
                            </td>
                            <td class="px-6 py-4">
                              {item.email}
                            </td>
                            <td class="px-6 py-4">
                              {item.validaEmpresa}
                            </td>
                            <td class="px-6 py-4">
                              {item.empresa}
                            </td>
                            <td class="px-6 py-4">
                              {item.fechaRegistro}
                            </td>
                            <td class="px-6 flex py-4">
                              <button onClick={() => handleRecoveryPassword(item.email)} className="btns btn-azul text-[10px] flex items-center py-2 px-2">   
                                Recuperar                         
                                <box-icon type="solid" name="universal-access" color="white" size="size-0.8" ></box-icon></button>                              
                            <div className="flex items-center">
                             <select
                             className="h-10 border border-gray-100 text-sm rounded-md"
                              value={item.idRolFk}
                              onChange={(e) => handleRoleChange(item.id_usuario, e.target.value)}
                              >
                              <option value={"1"}>
                                Administrador
                              </option>
                                <option value={"2"}>
                                Socio
                              </option>
                              <option value={"3"}>
                                Por validar
                              </option>
                              <option value={"4"}>
                                Industrias
                              </option>
                             </select>
                                 <button onClick={() => updateStatusUsuario(item.idRolFk,item.uuid)} className="btns bg-green-500 rounded-md py-1 px-2 ml-1 "><box-icon type="solid" name="user-check" color="white" size="sm"></box-icon></button>

                           </div>
                              
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody class="w-full text-center"><tr><td colSpan={8}><h2>Sin Registros</h2></td></tr></tbody>
                    )}
                  </table>

                </div>
              </TabPanel>
              <TabPanel>
                <div className="flex justify-center w-full ">
                  <div className="flex justify-center w-full tamanoConenedor scrollbar styleScrollbar margenResultado">

                    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                      {/*<caption class="caption-top mb-2">Por contactar</caption>*/}
                      <thead class="fondo-gris text-xs text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" class="px-6 py-2">
                            Id
                          </th>
                          <th scope="col" class="px-6 py-1">
                            Nombre
                          </th>
                          <th scope="col" class="px-6 py-2">
                            Telefono
                          </th>
                          <th scope="col" class="px-6 py-2">
                            Email
                          </th>
                          <th scope="col" class="px-6 py-2">
                            Empresa / Puesto
                          </th>

                          <th scope="col" class="px-6 py-2">
                            Mensaje
                          </th>
                          <th scope="col" class="px-6 py-2">
                            Fecha
                          </th>

                        </tr>
                      </thead>

                      {typeof contactosData.error === 'undefined' && contactosData.length !== 0 ? (
                        <tbody>
                          {contactosData.map((item) => (
                            <tr key={item.id} class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                              <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                {item.id}
                              </th>
                              <td class="px-6 py-4">
                                {item.nombre}
                              </td>
                              <td class="px-6 py-4">
                                {item.numero}
                              </td>
                              <td class="px-6 py-4">
                                {item.email}
                              </td>
                              <td class="px-6 py-4">
                                {item.empresa}
                              </td>

                              <td class="px-6 py-4">
                                {item.mensaje}
                              </td>
                              <td class="px-6 py-4">
                                {item.fechaRegistro}
                              </td>
                            </tr>
                          ))}

                        </tbody>
                      ) : (
                        <tbody class="w-full text-center"><tr><td colSpan={7}><h2>Sin Registros</h2></td></tr></tbody>
                      )}

                    </table>

                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-1 mt-5">
                  <div className="sm:col-span-1 flex justify-center w-full tamanoConenedor scrollbar styleScrollbar margenResultado">
                    <form onSubmit={(e)=>{
                      e.preventDefault();
                      if(formData.type === 1){
                        handleSubmitBoletin(e)
                      } else{
                        handleUpdateBoletin()
                      } }}  className="md:max-w-md max-w-xs mx-auto mt-[20px] border-red-900">
                      <div className="">
                        <label className="md:text-2xl text-2xl justify-center items-center font-semibold text-red-900 flex">
                          <box-icon type="solid" name="bookmark-plus" color="#7F1D1D"></box-icon> Registro de Newsletter
                        </label>
                        <div className="relative mt-10 z-0 w-full mb-5 group">
                          <input
                            type="text"
                            name="nombre"
                            value={formData.nombre}
                            onChange={handleInputChangeBoletin}
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-[1px] border-red-900 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-red-500 focus:outline-none focus:ring-0 focus:border-red-600 peer"
                            placeholder="Nombre"
                            required
                          />


                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                          <input
                            type="file"
                            name="url_pdf"
                            accept="application/pdf"
                            onChange={handleFileChangeBoletin}
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-[1px] border-red-900 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-red-500 focus:outline-none focus:ring-0 focus:border-red-600 peer"
                            placeholder="PDF"
                            required={formData.type === 1 ? true : false}
                          />
                          <label
                            htmlFor="url_pdf"
                            className="peer-focus:font-medium flex absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-red-600 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                          >
                            Archivo PDF
                          </label>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                          <input
                            type="file"
                            name="imagen"
                            accept="image/png, image/jpeg, image/jpg"
                            onChange={handleFileChangeBoletin}
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-[1px] border-red-900 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-red-500 focus:outline-none focus:ring-0 focus:border-red-600 peer"
                            placeholder="Imagen"
                            required={formData.type === 1 ? true : false}
                          />
                          <label
                            htmlFor="imagen"
                            className="peer-focus:font-medium flex absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-red-600 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                          >
                            Imagen
                          </label>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                          <textarea
                            name="descripcion"
                            value={formData.descripcion}
                            onChange={handleInputChangeBoletin}
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-[1px] border-red-900 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-red-500 focus:outline-none focus:ring-0 focus:border-red-600 peer"
                            placeholder="Descripción"
                            required
                          />

                          <button type="button" className="absolute right-0 top-3 px-2">
                            <box-icon type="solid" color="gray"></box-icon>
                          </button>
                        </div>
                        {formData.type === 1 ?(
                        <button
                          type="submit"
                          className="text-white w-full bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                        >
                          <div className="flex justify-center items-center w-full">
                            <box-icon name="save" color="white"></box-icon> Registrar
                          </div>
                        </button>
                        ):(
                          <button
                           type="submit"
                          className="text-white w-full bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                        >
                          <div className="flex justify-center items-center w-full">
                            <box-icon name="save" color="white"></box-icon> Actualizar
                          </div>
                        </button>
                        )}
                      </div>
                    </form>
                  </div>
                  <div className="sm:col-span-1 flex justify-center w-full tamanoConenedor scrollbar styleScrollbar margenResultado">
                    <div className="flex justify-center w-full ">
                      <table class="min-w-[98%] mt-2 bg-white shadow-md rounded">
                        <thead class="fondo-gris text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                            <th class="w-1/8 text-center font-semibold px-6 py-2">#</th>
                            <th class="w-1/4 text-center font-semibold px-6 py-2">Nombre</th>
                            <th class="w-1/4 text-center font-semibold px-6 py-2">Descripcion</th>
                            <th class="w-1/3 text-center font-semibold px-6 py-2">Fecha registro</th>
                            <th class=" text-center font-semibold px-6 py-2">Acciones</th>
                          </tr>
                        </thead>
                        <tbody class="text-gray-700">
                          {typeof newsletter !== 'undefined' && newsletter.length !== 0 && newsletter.map((item) => (
                            <tr className="text-sm" key={item.id}>
                              <td class="text-center py-3 px-4 ">{item.id}</td>
                              <td class="text-center py-3 px-4">{item.nombre}</td>
                              <td class="text-center py-3 px-4">{item.descripcion}</td>
                              <td class="text-center py-3 px-4">{item.fechaRegistro}</td>
                              <td class="text-center py-3 px-4 "><div>
                                {item.estatus === "1" ? (
                                  <div className="flex">
                                  <button onClick={() => handleDeleteBoletin(item.id)} className="bg-red-700 rounded-md flex items-center justify-center text-white p-2 w-10 h-10"><i class='bx bx-trash text-xl'></i></button>
                                  <button onClick={() => setFormData({
                                    id:item.id,
                                   nombre:item.nombre,
                                   descripcion:item.descripcion,
                                   type:2
                                
                                


                                })} className="bg-yellow-400 rounded-md flex items-center ml-1 justify-center text-white p-2 w-10 h-10"><i class='bx bxs-edit text-xl'></i></button>
                                  </div>
                                ) : (
                                  <button className="bg-[#f0fdf9] rounded-md flex items-center justify-center text-black p-2 w-10 h-10"><i class='bx bx-minus-circle text-xl'></i></button>
                                )}
                              </div></td>
                            </tr>
                          ))}

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>

                <div class="grid grid-cols-1 xl:grid-cols-2 gap-1 mt-5">
                  <div className="sm:col-span-1 flex justify-center w-full tamanoConenedor scrollbar styleScrollbar margenResultado">
                    <form onSubmit={(e)=>{
                      e.preventDefault();
                      if(formEvento.type === 1){
                        handleSubmitEvento(e)
                      } else{
                        handleUpdatePerson(formEvento.id)
                      } }} className="md:max-w-md max-w-xs mx-auto mt-[20px] border-red-900">
                      <div className="">
                        <label className="md:text-2xl text-2xl justify-center items-center font-semibold text-red-900 flex">
                          <box-icon type="solid" name="map-pin" color="#7F1D1D"></box-icon> Registro de Eventos
                        </label>
                        <div className="relative mt-5 z-0 w-full mb-5 group">
                          <input
                            type="text"
                            name="nombre"
                            value={formEvento.nombre}
                            onChange={handleInputChangeEvento}
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-[1px] border-red-900 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-red-500 focus:outline-none focus:ring-0 focus:border-red-600 peer"
                            placeholder="Nombre"
                            required
                          />
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                          <input
                            type="file"
                            name="imagen"
                            onChange={handleFileChangeEvento}
                            accept="image/png, image/jpeg, image/jpg"
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-[1px] border-red-900 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-red-500 focus:outline-none focus:ring-0 focus:border-red-600 peer"
                            placeholder="Imagen"
                            required={formEvento.type === 1 ? true : false}
                          />
                          <label
                            htmlFor="imagen"
                            className="peer-focus:font-medium flex absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-red-600 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                          >
                            Imagen
                          </label>
                        </div>

                        <div className="relative mt-5 z-0 w-full mb-5 group">
                          <input
                            type="date"
                            name="fecha"
                            value={formEvento.fecha}
                            onChange={handleInputChangeEvento}
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-[1px] border-red-900 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-red-500 focus:outline-none focus:ring-0 focus:border-red-600 peer"
                            placeholder="Fecha"
                            required
                          />
                        </div>
                        <div className="relative mt-5 z-0 w-full mb-5 group">
                          <input
                            type="time"
                            name="hora"
                            value={formEvento.hora}
                            onChange={handleInputChangeEvento}
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-[1px] border-red-900 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-red-500 focus:outline-none focus:ring-0 focus:border-red-600 peer"
                            placeholder="Hora"
                            required
                          />
                        </div>
                        <div className="relative mt-5 z-0 w-full mb-5 group">
                          <input
                            type="text"
                            name="ubicacion"
                            value={formEvento.ubicacion}
                            onChange={handleInputChangeEvento}
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-[1px] border-red-900 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-red-500 focus:outline-none focus:ring-0 focus:border-red-600 peer"
                            placeholder="Ubicacion"
                            required
                          />
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                          <textarea
                            name="descripcion"
                            value={formEvento.descripcion}
                            onChange={handleInputChangeEvento}
                            className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-[1px] border-red-900 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-red-500 focus:outline-none focus:ring-0 focus:border-red-600 peer"
                            placeholder="Descripcion"
                            required
                          />

                          <button type="button" className="absolute right-0 top-3 px-2">
                            <box-icon type="solid" color="gray"></box-icon>
                          </button>
                        </div>
                        {formEvento.type === 1 ?(

                      
                        <button
                          type="submit"
                          className="text-white w-full bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                        >
                          <div className="flex justify-center items-center w-full">
                            <box-icon name="save" color="white"></box-icon> Registrar
                          </div>
                        </button>
                          ):(

                        <button
                        type="submit"
                        className="text-white w-full bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                      >
                        <div className="flex justify-center items-center w-full">
                          <box-icon name="save" color="white"></box-icon> Actualizar
                        </div>
                      </button>

                          )}
                      </div>
                    </form>
                  </div>
                  <div className="sm:col-span-1 flex justify-center w-full tamanoConenedor scrollbar styleScrollbar margenResultado">
                    <div className="flex justify-center w-full ">
                      <table class="min-w-[98%] mt-2 bg-white shadow-md rounded">
                        <thead class="fondo-gris text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                            <th class="w-1/8 text-center font-semibold px-6 py-2">#</th>
                            <th class="w-1/4 text-center font-semibold px-6 py-2">Nombre</th>
                            <th class="w-1/4 text-center font-semibold px-6 py-2">Fecha</th>
                            <th class="w-1/3 text-center font-semibold px-6 py-2">Ubicacion</th>
                            <th class="w-10 text-center font-semibold px-6 py-2">Acciones</th>
                          </tr>
                        </thead>
                        <tbody class="text-gray-700">
                          {typeof evento !== 'undefined' && evento.length !== 0 && evento.map((item) => (
                            <tr className="text-sm" key={item.id}>
                              <td class="text-center ">{item.id}</td>
                              <td class="text-center">{item.nombre}</td>
                              <td class="text-center">{item.fecha}</td>
                              <td class="text-center ">{item.ubicacion}</td>
                              <td class="text-center "><div className="flex">

                              <button onClick={() => handleDeleteEvento(item.id)} className="bg-red-700 rounded-md flex items-center justify-center text-white p-2 w-10 h-10"><i class='bx bx-trash text-xl'></i></button>
                              <button onClick={() => setFormEvento({
                                id:item.id,
                                nombre:item.nombre,
                                descripcion:item.descripcion,
                                fecha:item.fecha,
                                ubicacion:item.ubicacion,
                                hora:item.horario,
                                type:2


                                })} className="bg-yellow-400 rounded-md ml-1 flex items-center justify-center text-white p-2 w-10 h-10"><i class='bx bxs-edit text-xl'></i></button>
                           
                              </div></td>
                            </tr>
                          ))}

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 left-0 right-0">
        <FooterTransparent></FooterTransparent>
      </div>
    </div>
  );
}
export default AnalyticsPage;