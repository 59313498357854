
import { useState } from "react";
import logo from "../assets/img/logotipo.png";
import profile from "../assets/img/profile.png";
import { useNavigate } from "react-router-dom";





function Navbar (){
    const navigate = useNavigate();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(true);

    const toggleMobileMenu = () => {
      setIsMobileMenuOpen(!isMobileMenuOpen);
    };


 return(

   <header className="header fixed top-0 shadow-lg bg-white z-40 w-full  flex items-center transition">
   <div className="w-full">
     <div className="flex items-center justify-between relative">
       <div className="px-4">
         <a href="index.html" className="header-logo w-full block py-5">
           <img src={logo} alt="logo" className="w-[4rem] py4" />
         </a>
       </div>

       <div className="flex px-4 justify-between items-center w-full">
         <div>
           <button onClick={toggleMobileMenu} id="navbarToggler" className=" absolute  right-4 top-1/2 translate-y-[-50%] lg:hidden focus:ring-2 ring-primary  px-3 py-[6px] rounded-lg flex items-center">
           <i className='bx bx-menu-alt-right text-3xl'></i>
           </button>


         </div>
         <div className="md:flex items-center cursor-pointer hidden  flex-wrap justify-end  pr-16 lg:pr-0">
           <a className="text-base font-semibold text-body-color hover:text-primary py-3 px-2 sm:px-3 sm:py-1 transition">Hector Hernandez</a>

           <img src={profile} className="h-12 border-2 border-red-800 p-1  rounded-3xl"></img>
         </div>
       </div>
     </div>
   </div>
 </header>

 );
}
export default Navbar;
