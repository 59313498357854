import React, { useState, useEffect } from "react";
import Headers from "../../Components/HeaderPublic";
import Footer from "../../Components/footerTransparente";
import Spinner from "../../Components/Spinner/Spinner";
import Toast from "../../Components/Toast/Toast";
import API_URL from "../../const";
import { useNavigate } from "react-router-dom";
import ToastError from "../../Components/Toast/ToastError";

export default function FormUsuarios() {
  const [confirmPassword, setConfirmPassword] = useState("");
 
  const [searchCard, setSearchCard] = useState([]);
  const [password, setPassword] = useState("");
  const [showToastError, setShowToastError] = useState(false);
  const [msg, setMsg] = useState('Error en la peticio');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [IsOpenSpinner, setIsOpenSpinner] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [error, setError] = useState(null);
  const [nuevaEmpresa, setNuevaEmpresa] = useState("");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [contactInfo, setContactInfo] = useState({
    nombre: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    numTelefonico: "",
    email: "",
    empresa: "",
    contrasenia: "",
    confirmPassword: "",
    id_empUserNuevo: 199,
    cargo: "",
  });

  useEffect(() => {
    setPasswordsMatch(contactInfo.contrasenia === contactInfo.confirmPassword);
  }, [contactInfo]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setContactInfo((prevState) => ({
      ...prevState,
      [name]: value
    }));

    BuscarSearch();

  };
  const handleInputContactChange = (event) => {
    const { name, value } = event.target;

    setContactInfo((prevState) => ({
      ...prevState,
      [name]: value
    }));
    
    BuscarSearch();



  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const handleSubmit = async (e) => {
    console.log(token)
    e.preventDefault();
    if (!passwordsMatch) {
      console.error("Las contraseñas no coinciden");
      return;
    }
    {/*const idEmpresaToSend = isNaN(contactInfo.id_empresa) || contactInfo.id_empresa === null ? 0 : parseInt(contactInfo.id_empresa);*/ }

    setIsOpenSpinner(true);
    try { 
      const response = await fetch(`${API_URL}insertUserNoValid.php`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${token}`,
         
        

        },
        body: new URLSearchParams({ ...contactInfo }),
      });
      console.log(response)
      const text = await response.text();
      console.log("Respuesta del servidor (texto):", text);
      console.log(JSON.stringify(contactInfo))
      const startIndex = text.indexOf("[{");
      const jsonResponse =
        startIndex !== -1 ? text.substring(startIndex) : text;

      try {
        const responseData = JSON.parse(jsonResponse);
        console.log("Respuesta del servidor:", responseData);
        setSuccessMessage(responseData.mensaje);
        if (response.status === 401) {
          // Si obtenemos un 401, redirigimos al login
          localStorage.removeItem("token");
          navigate('/login');
  
          return; // Salimos de la función para evitar procesar la respuesta
        }
        if (response.ok) {
          /*console.log(response);*/
          setIsOpenSpinner(false);
          setContactInfo({
            nombre: "",
            apellidoPaterno: "",
            apellidoMaterno: "",
            numTelefonico: "",
            email: "",
            mensaje: "",
            empresa: "",
            cargo: "",
            giro: "",
            puesto: "",
            contrasenia: "",
            confirmPassword: "",
          });
          setShowToast(true);

          setTimeout(() => {
            setShowToast(false);
          }, 5000);
        } else {
          console.error("Error del servidor:", response.statusText);
          setError(response.statusText || "Error desconocido");
          setIsOpenSpinner(false);
     
          setMsg(responseData[0].error)
          setShowToastError(true);
          
          setTimeout(() => {
            setShowToastError(false);
          }, 5000);
        }
      } catch (error) {
        console.error("Error al analizar JSON:", error);
        setError("Error de formato en la respuesta del servidor");
        setIsOpenSpinner(false);
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
      setError("Error de red: " + error.message);
    }
  };
  const BuscarSearch = () => {
    /*console.log(empresaData);
    console.log("Nombre de la empresa recibido en CatalogoLogin:", searchValue);*/
    
    // Construir la URL `con los parámetros que tienen valores
    let url = `${API_URL}/getEmpresasSearch.php?`;
    if (contactInfo.empresa) url += `nombre=${contactInfo.empresa}`;
    

    // Eliminar el último '&' si existe
    url = url.replace(/&$/, '');

    fetch(url)
      .then((response) => response.text())
      .then((text) => {
        const startIndex = text.indexOf("[{");
        const jsonResponse =
          startIndex !== -1 ? text.substring(startIndex) : text;

        try {
          const responseData = JSON.parse(jsonResponse);

          /*console.log("Respuesta del servidor:", responseData);*/
          setSuccessMessage(responseData.mensaje);
          if(responseData[0].nombre !== ''){
            setSearchCard(responseData);
          }
         
       
        
        } catch (error) {
          console.log("Error al analizar JSON:", error);
          setError("Error de formato en la respuesta del servidor");

          setIsOpenSpinner(false);
        }
      })
      .catch((error) => {
        console.error("Error del servidor:", error);
        setError(error.statusText || "Error desconocido");
        setShowToast(true);
      });
  };



  const [empresas, setEmpresas] = useState([]);

  useEffect(() => {

    fetch(`${API_URL}getEmpresas.php`)
      .then((response) => response.text())
      .then((text) => {
        const startIndex = text.indexOf("[{");
        const jsonResponse =
          startIndex !== -1 ? text.substring(startIndex) : text;

        try {
          const responseData = JSON.parse(jsonResponse);

          setSuccessMessage(responseData.mensaje);

          setEmpresas(responseData);
        } catch (error) {
          console.error("Error al analizar JSON:", error);
          setError("Error de formato en la respuesta del servidor");

        }
      })
      .catch((error) => {
        console.error("Error del servidor:", error);
        setError(error.statusText || "Error desconocido");
      });
  }, []);
  const [showNewCompanyInput, setShowNewCompanyInput] = useState(false);
  return (
    <div>
      <div className="absolute w-full left-0 right-0 h-screen justify-between" onSubmit={handleSubmit}>
        {" "}
        {showToast && (
          <Toast isOpen={true} title="Éxito" text="Usuario registrado" />
        )}{" "}
          {showToastError && <ToastError isOpen={true} title="Error" text={msg} />}{" "}
    
        <Spinner isOpen={IsOpenSpinner}></Spinner>
        <Headers></Headers>
        <form class="md:max-w-md max-w-xs mx-auto pt-20 border-red-900 mb-3" autoComplete="off">
          <div class="">
            <label class="md:text-3xl text-2xl justify-center items-center font-semibold text-red-900 flex ">
              <box-icon type="solid" name="user-account" color="#7F1D1D"></box-icon>{" "}
              Regístrate
            </label>
            <div class="relative mt-3 z-0 w-full mb-6 group">
              <label for="nombre" class="eer-focus:font-medium flex absolute text-sm font-medium  text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:start-1 rtl:peer-focus:translate-x-1/4 peer-focus:text-red-600 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8">
                {" "} Nombre{" "}
              </label>
              <input type="text" name="nombre" id="nombre" value={contactInfo.nombre}
                onChange={handleInputChange}
                className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:focus:border-red-500 focus:outline-none focus:ring-1 focus:border-red-600 peer"
                placeholder=" " required />

            </div>
            <div class="grid md:grid-cols-2 md:gap-3">
              <div class="relative z-0 w-full mb-5 group">
                <label
                  for="apellidoPaterno"
                  class="peer-focus:font-medium flex absolute text-sm font-medium text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:start-1 rtl:peer-focus:translate-x-1/4 peer-focus:text-red-600 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
                >
                  {" "}
                  Apellido paterno{" "}
                </label>
                <input
                  type="text"
                  name="apellidoPaterno"
                  id="apellidoPaterno"
                  value={contactInfo.apellidoPaterno}
                  onChange={handleInputChange}
                  class="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:focus:border-red-500 focus:outline-none focus:ring-1 focus:border-red-600 peer"
                  placeholder=" "
                  required
                />

              </div>
              <div class="relative z-0 w-full mb-5 group">
                <label
                  for="apellidoMaterno"
                  class="peer-focus:font-medium flex absolute text-sm font-medium  text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:start-1 rtl:peer-focus:translate-x-1/4 peer-focus:text-red-600 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
                >
                  {" "}
                  Apellido materno{" "}
                </label>
                <input
                  type="text"
                  name="apellidoMaterno"
                  id="apellidoMaterno"
                  value={contactInfo.apellidoMaterno}
                  onChange={handleInputChange}
                  class="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:focus:border-red-500 focus:outline-none focus:ring-1 focus:border-red-600 peer"
                  placeholder=" "
                  required
                />

              </div>
            </div>
            <div class="relative z-0 w-full mb-5 group">

              <label
                for="email"
                class="peer-focus:font-medium flex absolute text-sm font-medium  text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:start-1 rtl:peer-focus:translate-x-1/4 peer-focus:text-red-600 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
              >
                {" "}
                Email{" "}
              </label>
              <input
                type="Email"
                name="email"
                id="email"
                value={contactInfo.email}
                onChange={handleInputChange}
                class="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:focus:border-red-500 focus:outline-none focus:ring-1 focus:border-red-600 peer"
                placeholder=" "
                required
              />

            </div>
            <div className="relative z-0 w-full mb-5 group">
              <label
                htmlFor="numTelefonico"
                className="peer-focus:font-medium flex absolute text-sm font-medium  text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:start-1 rtl:peer-focus:translate-x-1/4 peer-focus:text-red-600 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
              >
                {" "}
                Número telefonico{" "}
              </label>

              <input
                type="text"
                name="numTelefonico"
                id="numTelefonico"
                value={contactInfo.numTelefonico}
                onChange={handleInputChange}
                defaultValue="+52"
                class="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:focus:border-red-500 focus:outline-none focus:ring-1 focus:border-red-600 peer"
                placeholder=" "
                required
              />

            </div>
            <div className="relative z-0 w-full mb-5 group">

              <label
                htmlFor="contrasenia"
                className="peer-focus:font-medium flex absolute text-sm font-medium  text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:start-1 rtl:peer-focus:translate-x-1/4 peer-focus:text-red-600 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
              >
                {" "}
                Contraseña{" "}
              </label>
              <input
                type={showPassword ? "text" : "password"}
                name="contrasenia"
                id="contrasenia"
                minLength={8}
                maxLength={12}
                value={contactInfo.contrasenia}
                onChange={handleInputChange}
                className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:focus:border-red-500 focus:outline-none focus:ring-1 focus:border-red-600 peer"
                placeholder=" "
                required
              />{" "}

              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute right-0 top-3 px-2"
              >
                <box-icon
                  type="solid"
                  name={showPassword ? "show" : "hide"}
                  color="gray"
                ></box-icon>
              </button>
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <label
                htmlFor="confirmPassword"
                className="peer-focus:font-medium flex absolute text-sm font-medium text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:start-1 rtl:peer-focus:translate-x-1/4 peer-focus:text-red-600 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
              >
                {" "}
                Confirmar contraseña{" "}
              </label>
              <input
                type={showPassword ? "text" : "password"}
                name="confirmPassword"
                id="confirmPassword"
                minLength={8}
                maxLength={12}
                value={contactInfo.confirmPassword}
                onChange={handleInputChange}
                className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:focus:border-red-500 focus:outline-none focus:ring-1 focus:border-red-600 peer"
                placeholder=" "
                required
              />{" "}

              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute right-0 top-3 px-2"
              >
                <box-icon
                  type="solid"
                  name={showPassword ? "show" : "hide"}
                  color="gray"
                ></box-icon>
              </button>

              {" "}
              {!passwordsMatch && (
                <span className="text-red-500 text-xs">* Las contraseñas no coinciden, minimo 8 y maximo 12 caracteres</span>
              )}{" "}

            </div>
            <div className="relative z-0 w-full mb-5 group">
              <label
                htmlFor="cargo"
                className="peer-focus:font-medium flex absolute text-sm font-medium  text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:start-1 rtl:peer-focus:translate-x-1/4 peer-focus:text-red-600 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
              >
                {" "}
                Cargo que desempeñas{" "}
              </label>
              <input
                type="text"
                name="cargo"
                id="cargo"
                value={contactInfo.cargo}
                onChange={handleInputChange}
                class="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:focus:border-red-500 focus:outline-none focus:ring-1 focus:border-red-600 peer"
                placeholder=" "
                required
              />

            </div>
            <div className="relative z-0 w-full mb-3 group">
    <label
      htmlFor="empresa"
      className="peer-focus:font-medium flex absolute text-sm font-medium  text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:start-1 rtl:peer-focus:translate-x-1/4 peer-focus:text-red-600 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
    >
      Registra tu empresa
    </label>
    <input
      type="text"
      name="empresa"
      id="empresa"
      value={contactInfo.empresa}
     
      onInput={handleInputContactChange}
      className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:focus:border-red-500 focus:outline-none focus:ring-1 focus:border-red-600 peer"
      list="companies"
      placeholder=" "
      required
    />
    <datalist id="companies">
      {searchCard.map((company, index) => (
        <option key={index} value={company.nombre} >
     
        </option>
      ))}
    </datalist>
  </div>
            <div className="relative z-0 w-full mb-20 group">
              <button
                type="submit"
                class="mb-40 text-white btn-rojo focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-md text-sm sm:w-auto px-5 py-1.5 text-center"
              >
                <div className="flex justify-center items-center">
                  <box-icon name="send" color="white"></box-icon>&nbsp;Enviar
                </div>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="fixed bottom-0 left-0 right-0 pt-5">
        <Footer></Footer>
      </div>
    </div>

  );
}