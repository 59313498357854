import { AreaChart, BarChart } from "@tremor/react";
import FooterTransparent from "../Components/footerTransparente";
import API_URL from "../const";
import { useEffect, useState } from "react";
import Headers from "../Components/HeaderPrivate";


function AnalyticsPage() {

  const [contactosData, setContactosData] = useState('');
  const token = localStorage.getItem("token");
  const getContactanos = () => {


    fetch(`${API_URL}getContactanos.php`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    },)
      .then((response) => response.text())
      .then((text) => {
        const startIndex = text.indexOf("[{");
        const jsonResponse =
          startIndex !== -1 ? text.substring(startIndex) : text;

        try {
          const responseData = JSON.parse(jsonResponse);

          /*console.log("Respuesta del servidor:", responseData);*/

          setContactosData(responseData);


        } catch (error) {
          console.error("Error al analizar JSON:", error);
        }
      })
      .catch((error) => {
        console.error("Error del servidor:", error);
      });
  };

  const chartdata = [
    {
      date: 'Jan 22',
      Conexiones: 2890,
      'Empresas': 2338,
    },
    {
      date: 'Feb 22',
      Conexiones: 2756,
      'Empresas': 2103,
    },
    {
      date: 'Mar 22',
      Conexiones: 3322,
      'Empresas': 2194,
    },
    {
      date: 'Apr 22',
      Conexiones: 3470,
      'Empresas': 2108,
    },
    {
      date: 'May 22',
      Conexiones: 3475,
      'Empresas': 1812,
    },
    {
      date: 'Jun 22',
      Conexiones: 3129,
      'Empresas': 1726,
    },
    {
      date: 'Jul 22',
      Conexiones: 3490,
      'Empresas': 1982,
    },
    {
      date: 'Aug 22',
      Conexiones: 2903,
      'Empresas': 2012,
    },
    {
      date: 'Sep 22',
      Conexiones: 2643,
      'Empresas': 2342,
    },
    {
      date: 'Oct 22',
      Conexiones: 2837,
      'Empresas': 2473,
    },
    {
      date: 'Nov 22',
      Conexiones: 2954,
      'Empresas': 3848,
    },
    {
      date: 'Dec 22',
      Conexiones: 3239,
      'Empresas': 3736,
    },
  ];

  const valueFormatter = function (number) {
    return '$ ' + new Intl.NumberFormat('mx').format(number).toString();
  };

  useEffect(() => {
    getContactanos();
  }, [])

  return (
    <div className=" w-full absolute left-0 right-0 bottom-0 top-0">
      <Headers></Headers>
      <div className="pb-10 pt-24 px-5 bg-gray-100">
        <div className="xl:flex block">
          <div className=" w-full bg-white border m-2 rounded-lg  dark:bg-gray-800 p-4 md:p-6">


            <div class="relative overflow-x-auto ">
              <div className="text-left p-2">
                <h1 className="font-semibold my-2"><i class='bx bxl-book-reader text-2xl'></i> Seguimiento a solicitudes de contacto</h1>
              </div>
              {typeof contactosData.error === 'undefined' && contactosData.length !== 0 ? (
                <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" class="px-6 py-3">
                        Id
                      </th>

                      <th scope="col" class="px-6 py-3">
                        Nombre
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Apellidos
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Email
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Mensaje
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Fecha
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {contactosData.map((item) => (
                      <tr key={item.id} class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          {item.id}
                        </th>
                        <td class="px-6 py-4">
                          {item.nombre}
                        </td>
                        <td class="px-6 py-4">
                          {item.apellidos}
                        </td>
                        <td class="px-6 py-4">
                          {item.email}
                        </td>
                        <td class="px-6 py-4">
                          {item.mensaje}
                        </td>
                        <td class="px-6 py-4">
                          {item.fechaRegistro}
                        </td>
                      </tr>
                    ))}

                  </tbody>
                </table>
              ) : (
                <div>Sin registros</div>
              )}
            </div>

          </div>

        </div>
        <div className=" md:flex block">
          <div class=" w-full bg-white border m-2 rounded-lg  dark:bg-gray-800 p-4 md:p-6">
            <h3 className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Empresas registradas</h3>
            <p className="text-tremor-metric text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">34</p>
            <AreaChart
              className="mt-4 h-72"
              data={chartdata}
              index="date"
              yAxisWidth={65}
              categories={['Empresas', 'Conexiones']}
              colors={['indigo', 'cyan']}
              valueFormatter={valueFormatter}
            />

          </div>
          <div class="w-full bg-white border m-2 rounded-lg  dark:bg-gray-800 p-4 md:p-6">
            <h3 className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Empresas conectadas</h3>
            <p className="text-tremor-metric text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">345</p>
            <AreaChart
              className="mt-4 h-72"
              data={chartdata}
              index="date"
              yAxisWidth={65}
              categories={['Empresas', 'Conexiones']}
              colors={['indigo', 'cyan']}
              valueFormatter={valueFormatter}
            />

          </div>

        </div>
      </div>
      <div className="fixed bottom-0 left-0 right-0">
        <FooterTransparent></FooterTransparent>
      </div>
    </div>
  );
}
export default AnalyticsPage;