
'use client';

import { Button, Modal } from 'flowbite-react';

export default function Modalcomponent({openModal,setOpenModal,pdf}) {
  
  return (
    <>
      <Modal show={openModal} size='7xl' onClose={() => setOpenModal(false)}>
        <Modal.Header> <box-icon name='universal-access' ></box-icon> Vista previa</Modal.Header>
        <Modal.Body style={{ padding: 0 }}>
          <div className='bg-black w-full '>
           <iframe className='w-full h-screen' src={pdf}></iframe>
          </div>
        </Modal.Body>
       
      </Modal>
    </>
  );
}
