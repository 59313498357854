import React, { useState, useEffect } from "react";
import Headers from "../../Components/HeaderPrivate";
import Button from "../../Components/ButtonWhatsapp";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";
import Cards from "./Cards";
import "boxicons";

export default function Information({ empresaData }) {
  const [empresas, setEmpresas] = useState(empresaData);


  useEffect(() => {
    setEmpresas(empresaData);
  }, [empresaData]);

  return (
    <div className="absolute left-0 right-0 h-screen justify-between">
      <Headers />
      {Array.isArray(empresas) && empresas.length > 0 ? (
        empresas.map((user, index) => (
          <section key={index} className={`bg-white dark:bg-gray-900`}>
            <div className="container px-6 -mt-[55px] py-10 mx-auto">
              <div style={{background: user.colorHex}} className={`text-2xl h-14 mt-2 items-center font-semibold text-white flex justify-center capitalize lg:text-3xl dark:text-white`}>
                {user.nombre}
              </div>
              <div className="mt-8 xl:mt-12 lg:flex lg:items-center">
                <div className="grid w-full grid-cols-1 gap-8 lg:w-1/2 xl:gap-16 md:grid-cols-2">
                  <div className="space-y-3 ">
                    <span style={{background: user.colorHex}} className={`inline-block h-12 p-3 text-[${user.colorHex}]  rounded-xl dark:text-white dark:bg-[${user.colorHex}]`}>
                      <box-icon name="phone" color="white"></box-icon>
                    </span>
                    <h1 className="text-xl font-semibold text-gray-700 capitalize dark:text-white">Telefono</h1>
                    <p className="text-gray-500 dark:text-gray-300">{user.telefono}</p>
                  </div>
                  <div className="space-y-3">
                    <span style={{background: user.colorHex}} className={`inline-block h-12 p-3 text-[${user.colorHex}] bg-[${user.colorHex}] rounded-xl dark:text-white dark:bg-[${user.colorHex}]`}>
                      <box-icon name="envelope" color="white"></box-icon>
                    </span>
                    <h1 className="text-xl font-semibold text-gray-700 capitalize dark:text-white">Email</h1>
                    <p className="text-gray-500 dark:text-gray-300">{user.email}</p>
                  </div>
                  <div className="space-y-3">
                    <span style={{background: user.colorHex}} className={`inline-block h-12 p-3 text-[${user.colorHex}] bg-[${user.colorHex}] rounded-xl dark:text-white dark:bg-[${user.colorHex}]`}>
                      <box-icon name="world" color="white"></box-icon>
                    </span>
                    <h1 className="text-xl font-semibold text-gray-700 capitalize dark:text-white">Sitio web</h1>
                    <p className="text-gray-500 dark:text-gray-300">
                      <a href={user.sitioWeb} target="_blank" rel="noopener noreferrer">{user.sitioWeb}</a>
                    </p>
                  </div>
                  <div className="space-y-3">
                    <span style={{background: user.colorHex}} className={`inline-block h-12 p-3 text-[${user.colorHex}] bg-[${user.colorHex}] rounded-xl dark:text-white dark:bg-[${user.colorHex}]`}>
                      <box-icon name="code-alt" color="white"></box-icon>
                    </span>
                    <h1 className="text-xl font-semibold text-gray-700 capitalize dark:text-white">Especialidad</h1>
                    <p className="text-gray-500  dark:text-gray-300">{user.especialidad}</p>
                  </div>

                </div>
                <div className="hidden lg:flex lg:w-1/2 lg:justify-center">
                  <img className="w-[12rem] h-[12rem] flex-shrink-0 object-cover xl:w-[16rem] xl:h-[16rem] rounded-ss" src={user.logo} alt="" />
                </div>
              </div>
            </div>
          </section>
        ))
      ) : (
        <div></div>
      )}
      <Button></Button>
      <Footer></Footer>
    </div>
  );
}
