import { useEffect, useState } from "react";
import Headers from "../../Components/HeaderPrivate";
import Image from "./image.png";
import Logo from "../../assets/img/logotipo.png";
import FooterTransparent from "../../Components/footerTransparente";
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'; // Estilos predeterminados de la capa de anotaciones
import Modalcomponent from "./Modal";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import API_URL from "../../const";
import { useNavigate } from "react-router-dom";


function Newsletter() {
  const [openModal, setOpenModal] = useState(false);
  const [url, setUrl] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [newsletter, setNewsletter] = useState('');
  const [evento, setEvento] = useState('');
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const handleDownload = (nombre, url) => {
    const link = document.createElement('a');
    link.href = `${API_URL}${url}`;
    link.download = `${nombre}.pdf`;
    link.click();
  };
 
  const handleDownloadDB = () => {
    const link = document.createElement('a');
    link.href = `${API_URL}/boletin/base/30Mayo.xlsx`;
    link.download = `Base_contactos_30_mayo_2024.xlsx`;
    link.click();
  };
  


  const getBoletin = () => {
    fetch(`${API_URL}getBoletin.php`, {
      headers: {
        "Authorization": `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          // Si obtenemos un 401, redirigimos al login
          localStorage.removeItem("token");
          navigate('/login');

          return; // Salimos de la función para evitar procesar la respuesta
        }
  
        // Continuamos si el estado no es 401
        return response.text();
      })
      .then((text) => {
        if (text) {
          const startIndex = text.indexOf("[{");
          const jsonResponse = startIndex !== -1 ? text.substring(startIndex) : text;
  
          try {
            const responseData = JSON.parse(jsonResponse);
            console.log("Respuesta del servidor:", responseData);
  
            setNewsletter(responseData);
          } catch (error) {
            console.error("Error al analizar JSON:", error);
          }
        }
      })
      .catch((error) => {
        console.error("Error del servidor:", error);
      });
  };
  

  const getEvento = () => {
    fetch(`${API_URL}getEventos.php`, {
      headers: {
        "Authorization": `Bearer ${token}`,
      

      }
    })

      .then((response) => {
        if (response.status === 401) {
          // Si obtenemos un 401, redirigimos al login
          localStorage.removeItem("token");
          navigate('/login');
        
          return; // Salimos de la función para evitar procesar la respuesta
        }
  
        // Continuamos si el estado no es 401
        return response.text();
      })
      .then((text) => {
        const startIndex = text.indexOf("[{");
        const jsonResponse =
          startIndex !== -1 ? text.substring(startIndex) : text;

        try {
          const responseData = JSON.parse(jsonResponse);
          console.log("Respuesta del servidor:", responseData);

          setEvento(responseData);
          console.log(newsletter)
        } catch (error) {
          console.error("Error al analizar JSON:", error);


        }
      })
      .catch((error) => {
        console.error("Error del servidor:", error);

      });
  }

  useEffect(() => {

    if (token == null || token == undefined || token == "") {
      navigate("/login");
    } else {
      getEvento();
      getBoletin();
    }
  }, [])

  return (
    <section className=" w-full absolute left-0 right-0 bg-gray-100 bottom-0 top-0">
      <Headers />
      <Modalcomponent pdf={url} openModal={openModal} setOpenModal={setOpenModal} />
      <div className="pb-10 pt-24 px-5 ">
        <div className="xl:flex block">
          <div className=" w-full bg-white border m-0 rounded-lg  dark:bg-gray-800 p-2 md:p-4">

            <Tabs onSelect={(index) => {
              setActiveTab(index)
            }} selectedIndex={activeTab}  >
              <TabList style={{ display: "flex", justifyContent: "center", listStyle: "none" }}>
                <Tab style={{ width: "50%", textAlign: "center", cursor: "pointer" }}>
                  <label class="justify-center cursor-pointer items-center flex">
                    <box-icon type="solid" name="user-check" size="sm" ></box-icon> Boletines
                  </label>
                </Tab>
                <Tab style={{ width: "50%", textAlign: "center", cursor: "pointer" }}>
                  <label class="justify-center cursor-pointer items-center flex">
                    <box-icon type="solid" name="timer" size="sm" ></box-icon> Próximos eventos
                  </label>
                </Tab>
                <Tab style={{ width: "50%", textAlign: "center", cursor: "pointer" }}>
                  <label class="justify-center cursor-pointer items-center flex">
                    <box-icon type="solid" name="timer" size="sm" ></box-icon> Bases de datos
                  </label>
                </Tab>
              </TabList>
              <TabPanel>
                <div className="w-full pl-2 pr-2">
                <div className="flex  justify-center w-full ">
                <div className="flex flex-wrap justify-center mt-5">
                  {typeof newsletter !== 'undefined' && newsletter.length !== 0 && newsletter.map((item) => (
                <>
               <div class="md:w-[20rem] flex-col justify-between flex w-full m-1 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
    <a className="" href="#">
        <div class="relative w-full h-60 bg-gray-100 rounded-t-lg">
            <img class="absolute inset-0 w-full h-full object-contain" src={`${API_URL}${item.imagen}`} alt="" />
        </div>
    </a>
    <div class="p-5">
        <a href="#">
            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{item.nombre}</h5>
        </a>
        <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">{item.descripcion}</p>
        <div className="flex justify-center md:justify-end mt-2 md:mt-5">
            <button onClick={()=>handleDownload(item.nombre,item.url_pdf)} className="bg-red-700 mt-2 mx-1 p-1 text-white rounded-md text-sm md:w-full w-1/3 flex items-center justify-center">
                <i className='bx bx-cloud-download text-xl md:mr-2 mr-0'></i>
                <p className="md:flex hidden">Descargar</p>
            </button>
            <button onClick={() => {setOpenModal(true); setUrl(`${API_URL}${item.url_pdf}`)}} className="bg-red-700 mt-2 mx-1 p-1 text-white rounded-md md:w-full w-1/3 text-sm flex items-center justify-center">
                <i className='bx bxs-file-pdf text-xl md:mr-2 mr-0'></i>
                <p className="md:flex hidden">Visualizar</p>
            </button>
        </div>
    </div>
</div>

                
                 </>
                 

                    
                  ))}
                    </div>
                    
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="bg-white">
                  <div className="mx-auto max-w-7xl px-6 lg:px-8">

                    <div className="mx-auto  grid max-w-2xl grid-cols-1 gap-x-5 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                      {typeof evento !== 'undefined' && evento.length !== 0 ? (evento.map((post) => (
                        <article key={post.id} className="flex max-w-xl mt-5 flex-col items-start justify-between border-l-2 border-red-500 pl-5 shadow-xl rounded-md p-2 bg-stone-50">
                          <div className="group relative">
                            <h3 className="mt-2 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                              <a href="#">
                                <span className="absolute inset-0" />
                                {post.nombre}
                              </a>
                            </h3>
                            <p className="mt-2 line-clamp-3 justify text-xs leading-6 text-gray-600">{post.descripcion}</p>
                          </div>
                          <div className="px-2 py-2 w-full">
                            {post.imagen !== null ?(
                              
                          <img className="rounded-md w-full" src={`${API_URL}eventos/image/${post.imagen}`} alt={`imagen-${post.id}`}  ></img>
                        ):(
                          <div className="w-full h-36 bg-gray-300 rounded-md flex items-center justify-center">
                          <i className="bx text-4xl bx-photo-album text-gray-800"></i>
                        </div>
                        )}
                         
                        </div>
                          <div className="group relative">
                            <h4 className="mt-4 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                              Ubicación
                            </h4>
                            <p className="mt-4 line-clamp-3 text-sm leading-6 text-gray-600">{post.ubicacion}</p>
                            <p className="mt-4 line-clamp-3 text-sm leading-6 text-gray-600">
                              <time dateTime={post.fecha} className="text-gray-500">
                               Horario {post.fecha} {post.horario}
                              </time>                             
                            </p>
                            
                          </div>
                          <div className="relative mt-5 flex items-center gap-x-4">
                            <img src="https://www.segurilatam.com/wp-content/uploads/sites/5/2021/07/silverio-bazan-ff-videosistemas-900x600.jpg" alt="" className="h-10 w-10 rounded-full bg-gray-50" />
                            <div className="text-sm leading-6">
                              <p className="font-semibold text-gray-800">
                                <a href="#">
                                  <span className="absolute inset-0" />
                                  {post.author}
                                </a>
                              </p>
                              <p className="text-gray-600">{post.role}</p>
                            </div>
                          </div>
                          <div className="relative mt-5 flex items-center gap-x-5">
                          <a
                                href="#"
                                className="relative z-10 rounded-full px-3 py-1 text-xs bg-red-500 text-gray-50"
                              >
                                {post.category}
                              </a>
                          </div>
                        </article>
                      ))) : (
                       <div>
                        No hay resultados
                        </div>
                      
                      )}
                    </div>
                  </div>
                </div>





                {/*<div className="flex justify-center w-full tamanoConenedor scrollbar styleScrollbar">
                  {typeof evento !== 'undefined' && evento.length !== 0 && evento.map((item) => (

                    <div class="bg-white py-2">
                      <div class="mx-auto max-w-7xl px-1 lg:px-8">
                        <div class="mx-auto mt-1 grid max-w-2xl grid-cols-1 gap-x-5 gap-y-5 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                          <article class="flex max-w-xl flex-col items-start justify-between border border-gray-200 p-5 shadow-lg">
                            <div class="flex items-center gap-x-4 text-xs">
                              <time datetime={item.fecha} class="text-gray-500">{item.fecha} - {item.horario}</time>
                              <a href="#" class="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100">Presentación</a>
                            </div>
                            <div class="group relative">
                              <h3 class="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                                <span class="absolute inset-0"></span>
                                {item.nombre}
                              </h3>
                              <p class="mt-5 line-clamp-3 text-sm leading-6 text-justify text-gray-600">{item.descripcion}</p>
                            </div>
                            <div class="relative mt-8 flex items-center gap-x-4">
                              <img src={Logo} alt="" class="h-12 w-15 rounded-full bg-gray-50" />
                              <div class="text-sm leading-6">
                                <p class="font-semibold text-gray-900">
                                  <span class="absolute inset-0"></span>
                                  Ubicación
                                </p>
                                <p class="text-gray-600">{item.ubicacion}</p>
                              </div>
                            </div>
                          </article>
                        </div>
                      </div>
                    </div>

                  ))}
                </div>*/}
              </TabPanel>
              <TabPanel>
              <div className="w-full pl-2 pr-2 ">                
                    <div className="flex justify-center w-full  tamanoConenedor scrollbar styleScrollbar">
                      <div className="flex flex-wrap justify-center mt-5 w-full">
                     

<div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
    <a href="#">
        <img class="rounded-t-lg h-60 w-sm object-cover" src={Logo} alt="" />
    </a>
    <div class="p-5">
        <a href="#">
            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Base de datos</h5>
        </a>
        <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Registro de contactos</p>
        <button onClick={handleDownloadDB} className="bg-red-700 mt-2 mx-1 p-1  text-white rounded-md text-sm md:w-full w-1/4 items-center flex justify-center"><i class='bx bx-cloud-download text-xl md:mr-2 mr-0'></i><p className="md:flex hidden ">Descargar</p></button>                              
                         
    </div>
</div>

                
                       
                        
                        
                        
                       
                       
                      </div>
                    </div>                  
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 left-0 right-0">
        <FooterTransparent></FooterTransparent>
      </div>
    </section>
  );
}
export default Newsletter;