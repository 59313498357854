import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <section id="accordion-flush" className='flex justify-center mt-20'>

    <div className='w-[90%] '>
    <div className="flex flex-wrap mx-[-16px]">
    <div className="w-full px-4">
      <div className="mx-auto max-w-[570px] text-center mb-12 wow fadeInUp" data-wow-delay=".1s"> {/* <span className="text-lg font-semibold text-primary mb-2 block"> Quieres contratar? </span>*/} <h2 className="text-red-700 font-bold text-3xl sm:text-4xl md:text-[45px] mb-4">Preguntas frecuentes</h2> {/* <p className="text-body-color text-base md:text-lg leading-relaxed md:leading-relaxed"> En este apartado encontraras preguntas frecuentes que nos hacen a la hora de ver el club de proovedores </p>*/} </div>
    </div>
  </div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>¿Cuánto tiempo lleva Club de Proveedores de la industria en el Mercado?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <p class="mb-2 text-gray-500 dark:text-gray-400"> Club de proveedores de la industria lleva más de 15 años realizando eventos de enlaces empresariales, iniciamos operaciones en la ciudad de Quer&eacute;taro, en el 2022 expandimos operaciones a Naucalpan y la ciudad de México. En el 2024 esperamos la apertura de la unidad de Toluca. </p>

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>¿El ser miembro del club tiene algún costo?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <p class="mb-2 text-gray-500 dark:text-gray-400">Si, nosotros operamos bajo un sistema de membresías, te recomendamos contactar con tu representante local de ventas para conocer los costos y beneficios de ser miembro del <b>Club de Proveedores de la Industria</b>.</p>
          
           </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>¿Yo asistí a muchos eventos de network y no me convencieron, ustedes son solo Networking?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div class="py-5 border-b border-gray-200 dark:border-gray-700">
      <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">No somos solo Networking, utilizamos estas herramientas como punto de encuentro para desarrollar relaciones, que generan alto valor como:</p>
      <ul class="ps-5 text-gray-500 list-disc text-left dark:text-gray-400">
        <li>
          <a class="text-gray-500 ">Generamos contactos directos con los compradores de la industria</a>
        </li>
        <li>
          <a rel="nofollow" class="text-gray-500">Realizamos 4 reuniones mensuales para generar contactos entre los miembros e invitados</a>
        </li>
        <li>
          <a rel="nofollow" class="text-gray-500">Otorgamos Exclusividad de giro (a los miembros que ya están inscritos en el club)</a>
        </li>
        <li>
          <a rel="nofollow" class="text-gray-500">Ambiente 100 % industrial (enfoque de industria)</a>
        </li>
        <li>
          <a rel="nofollow" class="text-gray-500">No contamos con empresas de retail o venta minorista</a>
        </li>
        <li>
          <a rel="nofollow" class="text-gray-500">Manejamos restricciones de entrada para cuidar la calidad de las empresas, ya que nosotros los enlazamos con otras empresas</a>
        </li>
        <li>
          <a rel="nofollow" class="text-gray-500">El retorno de inversión depende del giro, pero tenemos experiencias de socios que en menos de 3 meses logran retornar la inversión de la membresía</a>
        </li>
        <li>
          <a rel="nofollow" class="text-gray-500">Nuestra metodología de reuniones presenciales y virtuales te permite tener un foro de alto impacto.</a>
        </li>
      </ul>
    </div>
            </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>¿Cuántas reuniones mensuales tienen?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">Realizamos 4 reuniones, 2 virtuales y 2 presenciales, de las cuales 2 son con miembros y 2 con invitados.</p>

            </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>¿Cuál es la diferencia entre las reuniones de miembros e invitados?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">Las reuniones de miembros son exclusivas de los socios actuales del club en donde incrementamos los lazos de negocios. Las reuniones de invitados son abiertas para socios e invitados, en estas reuniones se invitan a grandes empresas a exponer sus necesidades de procura.</p>

            </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>¿Me dan la base de los asistentes al final de la reunión?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">Sí, al final mandamos un PDF con la lista de los asistentes</p>

            </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>¿Ya que asistí al desayuno, puedo seguir asistiendo a los eventos futuros?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">El motivo de asistir como nuevo participante es que puedas vivir la experiencia de nuestro modelo de Networking para que después puedas conocer la metodología de ingreso.</p>

            </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>¿Y cuál es la finalidad del network? </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">Nuestro método es poder compartir las empresas, pero sobre todo los contactos de las empresas, por ejemplo: Tú quieres venderle a Samsung, durante el evento conectas con la persona que le vende a Samsung y él te da el contacto y la referencia de la persona que quieres contactar. En el caso que nadie te dé los contactos o no trabaje con la empresa que tú quieres, el comité de vinculación te ayuda a enlazar con los contactos. </p>

            </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>¿Qué necesito llevar? </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">Es muy importante llevar tarjetas de presentación o código QR para compartir tus datos de contacto Libreta para realizar notas Alguna ayuda visual que te ayude durante el proceso de tu presentación recuerda que vienes a hacer contactos no a vender. </p>

            </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>¿Cuál es el código de vestimenta? </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">Business casual</p>

            </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>¿Yo tengo un negocio de textiles (Ejemplo) van a estar empresas del gremio? </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <p class="mb-2 text-gray-500 dark:text-gray-400 text-left">Nuestros eventos no son sectoriales, nos reunimos empresarios de distintos rubros, y hacemos dinámicas de enlace, es muy probable que interactúes con una empresa que de inicio pienses que no tienes nada que ver. Aquí está la esencia del networking, conocer a más personas que te pueden llevar a tu destino, aquí aplicamos la teoría de los seis grados de separación. </p>

            </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
    </section>
  );
}
