import React, { useState, useEffect } from "react";
import "./Recovery.css";
import imagen from "../../assets/img/logotipo.png";
import achurado from "../../assets/img/achurado-inferior.png";
import superior from "../../assets/img/achurado-superior.png";
import Spinner from "../../Components/Spinner/Spinner";
import API_URL from "../../const";
import { Link } from "react-router-dom";

function Login() {
  const [email, setEmail] = useState("");

  const [error, setError] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [message, setMessage] = useState("");

  const [IsOpenSpinner, setIsOpenSpinner] = useState(false);
  const [idRol, setIdRol] = useState(null);
  const validateForm = (e) => {
    e.preventDefault();
    if (email === "") {
      setShowErrors(true);
    }
  };

  const handleSubmit = async (e) => {
    setIsOpenSpinner(true);
    e.preventDefault();

    if (email === "") {
      setShowErrors(true);

    } else {


      const requestBody = `email=${encodeURIComponent(email)}`;


      try {
        const response = await fetch(`${API_URL}updateContrasenia.php`, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "POST",
            "Access-Control-Allow-Credentials": true
          },
          body: requestBody,
        });

        const text = await response.text();
        const startIndex = text.indexOf("[{");
        const jsonResponse = (startIndex !== -1) ? text.substring(startIndex) : text;


        const responseData = JSON.parse(jsonResponse);


        if (response.ok) {
          setError(true);
          setMessage("Se ha enviado a su email su nueva contraseña");
          setIsOpenSpinner(false);

        } else {
          setError(true);
          setMessage("Ha ocurrido un error, su email no existe.");
          setIsOpenSpinner(false);
        }

      } catch (error) {
        console.error("Error en la solicitud:", error);
        setMessage("Se ha producido un error, intente más tarde");
        setError("Error de red: " + error.message);
        setIsOpenSpinner(false);
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "email") {
      setEmail(value);
    }
  };
  return (
    <>
      <React.Fragment>
        <Spinner isOpen={IsOpenSpinner}></Spinner>{" "}
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "start",
              justifyContent: "end",
              position: "fixed",
              top: 0,
            }}
          >
            <img src={superior} style={{ width: "30%" }}></img>
          </div>
          <div className="flex h-full absolute top-0 left-0 right-0 items-center justify-center">
            <div className="w-full card-login rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:border-gray-700 max-w-[70%] z-10">
              <a href="/login" className="flex justify-center items-center ">
                <img className="w-60 h-50 " src={imagen} alt="logo" />
              </a>
              <div className="ml-5 mr-5 mb-5">
                <form onSubmit={validateForm}>
                  <div>
                    <h1
                      htmlFor=""
                      className="my-5 block mb-1 font-bold text-gray-500 text text-lg dark:text-white text-center"
                    >
                      {" "}
                      Recupera tu contraseña{" "}
                    </h1>

                    <label
                      htmlFor="email"
                      className="block mb-1 text-gray-500 text-sm font-medium dark:text-white text-left"
                    >
                      {" "}
                      Correo{" "}
                    </label>
                    <input
                      value={email}
                      onChange={handleChange}
                      type="email"
                      name="email"
                      id="email"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-md focus:ring-primary-600 focus:border-primary-600 block w-full  p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />{" "}
                    {showErrors && email === "" && (
                      <p className="text-red-500 text-xs">
                        {" "}
                        El campo es requerido{" "}
                      </p>
                    )}
                  </div>


                  {error && (
                    <p className="text-red-500 text-sm mt-2 mb-2">
                      {message}
                    </p>
                  )}{" "}
                  <button
                    onClick={handleSubmit}
                    type="submit"
                    className="w-full mt-2 text-white bg-[#494544] hover:bg-[#494544] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                  >
                    {" "}
                    Recuperar{" "}
                  </button>

                  <Link
                    to="/login"
                    className="text-sm text-black hover:text-red-800 group-hover:text-primary py-1 lg:py-2 lg:inline-flex lg:px-0 flex mx-1 lg:mr-0 mt-2"
                  >
                    {" "}
                    Autenticarme{" "}
                  </Link>
                </form>
              </div>
            </div>
          </div>
          <div
            className="bg-white"
            style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "space-between",
              position: "fixed",
              bottom: 0,
              left: "0",
              right: "0",
            }}
          >
            <img src={achurado} style={{ width: "30%" }}></img>
            <h6 style={{ fontSize: "12px", color: "#6C757D", margin: "10px" }}>
              {" "}
              Club Proveedores de la Industria Estado de México{" "}
            </h6>
            <div style={{ width: "30%" }}></div>
          </div>
        </div>
      </React.Fragment>
    </>
  );
}


export default Login;
