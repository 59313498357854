import React, { useState } from "react";

export default function Filter({ especialidad, setEspecialidad, estado, setEstado, municipio, setMunicipio }) {

  const handleEstadoChange = (event) => {
    const selectedEstado = event.target.value;
    setEstado(selectedEstado);

  };
  const handleMunicipioChange = (event) => {
    const selectedMunicipio = event.target.value;
    setMunicipio(selectedMunicipio);
  };
  const handleGiroChange = (event) => {
    const selectedEspecialidad = event.target.value;
    setEspecialidad(selectedEspecialidad);
  };


  return (
    <div className="block md:flex w-full  ">
      <select
        class="block w-full py-2.5 px-2 xl:m-1 mr-1 h-10 text-gray-700 border-gray-300 border bg-gray-50 rounded-md shadow-sm  focus:outline-none focus:ring-red-100 focus:border-red-100"
        name="giro"
        value={especialidad}
        onChange={handleGiroChange}
      >
        <option value="">Giro</option>
        <option value="tecnologia">Tecnología</option>
        <option value="industrial">Industrial</option>
        <option value="alimentos">Alimentos</option>
        <option value="servicios">Servicios</option>
        <option value="construccion">Construcción</option>
        <option value="educacion">Educación</option>
        <option value="salud">Salud</option>
        <option value="entretenimiento">Entretenimiento</option>
        <option value="comercio">Comercio</option>
      </select>

      <select
        className="block xl:m-1 mr-1 py-2.5 px-2 text-gray-700 border-gray-300 border bg-gray-50 h-10 rounded-md shadow-sm  w-full  focus:outline-none focus:ring-red-100 focus:border-red-100"
        name="estado"
        value={estado}
        onChange={handleEstadoChange}
      >
        <option value="">Estados</option>
        <option value="Aguascalientes">Aguascalientes</option>
        <option value="Baja California">Baja California</option>
        <option value="Baja California Sur">Baja California Sur</option>
        <option value="Campeche">Campeche</option>
        <option value="Chiapas">Chiapas</option>
        <option value="Chihuahua">Chihuahua</option>
        <option value="Coahuila">Coahuila</option>
        <option value="Colima">Colima</option>
        <option value="Durango">Durango</option>
        <option value="Guanajuato">Guanajuato</option>
        <option value="Guerrero">Guerrero</option>
        <option value="Hidalgo">Hidalgo</option>
        <option value="Jalisco">Jalisco</option>
        <option value="México">México</option>
        <option value="Michoacán">Michoacán</option>
        <option value="Morelos">Morelos</option>
        <option value="Nayarit">Nayarit</option>
        <option value="Nuevo León">Nuevo León</option>
        <option value="Oaxaca">Oaxaca</option>
        <option value="Puebla">Puebla</option>
        <option value="Querétaro">Querétaro</option>
        <option value="Quintana Roo">Quintana Roo</option>
        <option value="San Luis Potosí">San Luis Potosí</option>
        <option value="Sinaloa">Sinaloa</option>
        <option value="Sonora">Sonora</option>
        <option value="Tabasco">Tabasco</option>
        <option value="Tamaulipas">Tamaulipas</option>
        <option value="Tlaxcala">Tlaxcala</option>
        <option value="Veracruz">Veracruz</option>
        <option value="Yucatán">Yucatán</option>
        <option value="Zacatecas">Zacatecas</option>
      </select>



      <div className=" xl:m-1 mr-1 flex w-full items-center  ">
        <input

          className="block w-full py-2.5 px-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg h-10 bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white focus:ring-red-100 focus:border-red-100"
          name="municipio"
          placeholder="Municipio"
          value={municipio}
          onChange={handleMunicipioChange}
        />

      </div>
    </div>
  );
}
