import presco from "../assets/img/presco1.png"
import comex from "../assets/img/comex.jpeg"
import siemens from "../assets/img/siemens1.png"
import oxxogas from "../assets/img/oxxogas.png"
import eps1 from "../assets/img/eps1.png"
function Clients (){
    return(
       <>
       <section id="clients" className="mt-10">
 <div  className="w-full  p-2">
   <p className="font-bold text-2xl text-black text-center">Empresas que confían en nuestros miembros para obtener proveeduría 
</p>
  <div className="md:flex text-center justify-evenly mt-10">
    <div className="flex justify-center items-center">
    <img className="w-40" src={presco}></img>
    </div>
    <div className="flex  justify-center  items-center">
    <img className="w-40" src={comex}></img>
    </div>
    <div className="flex  justify-center  items-center">
    <img className="w-40" src={siemens}></img>
    </div>
    <div className="flex  justify-center  items-center">
    <img className="w-20" src={eps1}></img>
    </div>
    <div className="flex  justify-center  items-center">
    <img className="w-50" src={oxxogas}></img>
    </div>
  </div>
  </div>
</section>
   </>
    )
   }
   export default Clients;