import Navbar from "../../Components/Navbar";

function Dashboard(){
return(
<div className=" absolute left-0 right-0   h-screen justify-between">
<Navbar></Navbar>
<div
  class="
    flex
    items-center
    justify-center
    w-screen
    h-screen
    bg-gradient-to-r
    from-red-600
    to-red-400
  "
>
  <div class="px-40 py-20 bg-white rounded-md shadow-xl">
    <div class="flex flex-col items-center">
      <h1 class="font-bold text-red-600 text-9xl">403</h1>

      <h6 class="mb-2 text-2xl font-bold text-center text-gray-800 md:text-3xl">
        <span class="text-red-500">Oops! </span>Permiso denegado
      </h6>

      <p class="mb-8 text-center text-gray-500 md:text-lg">
       No has accedido al sistema
      </p>

      <a
        href="/"
        class="px-6 py-2 text-sm font-semibold text-red-800 bg-red-100"
        >Volver al inicio</a
      >
    </div>
  </div>
</div>

</div>);
}
export default Dashboard;