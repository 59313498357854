import { useEffect, useState } from "react";
import Headers from "../../Components/HeaderPrivate";
import FooterTransparent from "../../Components/footerTransparente";
import Spinner from "../../Components/Spinner/Spinner";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import API_URL from "../../const";
import Toast from "../../Components/Toast/Toast";
import ToastError from "../../Components/Toast/ToastError";

function Newsletter() {

  const [activeTab, setActiveTab] = useState(0);  
  const navigate = useNavigate();
  const token = localStorage.getItem("token");    
  const [error, setError] = useState(null);
  const [IsOpenSpinner, setIsOpenSpinner] = useState(false);  
  const [showPassword, setShowPassword] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const [msg, setMsg] = useState("");
  const [contactInfo, setContactInfo] = useState({
    token: "",
    contrasenia: "",
    confirmPassword: "",

  });
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const validateForm = (e) => {
    e.preventDefault();
    setPasswordsMatch(contactInfo.contrasenia === contactInfo.confirmPassword); 
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    
    setContactInfo((prevContactInfo) => ({ ...prevContactInfo, [name]: value}))  
    setPasswordsMatch(contactInfo.contrasenia === contactInfo.confirmPassword);    
  };



  const handleSubmit = async (e) => {
    setIsOpenSpinner(true);
    e.preventDefault();

    const formDataToSend = new FormData();
      formDataToSend.append('token', token);
      formDataToSend.append('contrasenia', contactInfo.contrasenia);
    
    /*console.log("Cuerpo de la solicitud:", requestBody);*/

    try {
      const response = await fetch(`${API_URL}updatePasswordUsario.php`, {
        method: "POST",
        headers: {
          
          "Authorization": `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "POST",
          "Access-Control-Allow-Credentials": true
        },
        body: formDataToSend,
      });

      const text = await response.text();
      const startIndex = text.indexOf("[{");
      const jsonResponse = (startIndex !== -1) ? text.substring(startIndex) : text;
      const responseData = JSON.parse(jsonResponse);
      if (response.status === 401) {
        // Si obtenemos un 401, redirigimos al login
        localStorage.removeItem("token");
        navigate('/login');

        return; // Salimos de la función para evitar procesar la respuesta
      }

      if (response.ok) {
        
        msg("Usuario actualizado correctamente");
        setShowToast(true);
        setIsOpenSpinner(false);

        setTimeout(() => {
          setShowToast(false);
        }, 5000);

        
        
      } else {
        console.error("Error del servidor:", response.statusText);
        setError(response.statusText || "Error desconocido");
        setIsOpenSpinner(false);
      }

    } catch (error) {
      console.error("Error en la solicitud:", error.message);
      setError("Error de red: " + error.message);
      setIsOpenSpinner(false);
    }
  };

  useEffect(() => {

    if(token==null || token == undefined || token == ""){
      navigate("/login");
    }
    
  }, [])

  return (
    <section className=" w-full absolute left-0 right-0 bottom-0 top-0">
      <Headers />
      <Spinner isOpen={IsOpenSpinner}></Spinner>{" "}
      {showToast && (
        <Toast isOpen={true} title="Éxito" text={msg} />
      )}{" "}
      {showToastError && <ToastError isOpen={true} title="Error" text={msg} />}{" "}
      <div className="pb-10 pt-24 px-5 bg-gray-100">
        <div className="xl:flex block">
          <div className=" w-full bg-white border m-0 rounded-lg  dark:bg-gray-800 p-2 md:p-4">

            <Tabs onSelect={(index) => {
              setActiveTab(index)
            }} selectedIndex={activeTab}  >
              <TabList style={{ display: "flex", justifyContent: "center", listStyle: "none" }}>
                <Tab style={{ width: "50%", textAlign: "center", cursor: "pointer" }}>
                  <label class="justify-center cursor-pointer items-center flex">
                    <box-icon type="solid" name="lock" size="sm" ></box-icon> Cambiar mi contraseña
                  </label>
                </Tab>

              </TabList>
              <TabPanel>


                <div className="w-full pl-2 pr-2">
                  <div className="flex justify-center mt-10 w-full tamanoConenedor scrollbar styleScrollbar">
                    <div className="w-full card-login  rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0  dark:border-gray-700 max-w-[70%] z-10">
                      <div className="ml-5 mr-5 mb-5 mt-2 pt-5">
                        <form onSubmit={validateForm}>


                          <div className="relative z-0 w-full mb-5 group">
                            <label
                              htmlFor="contrasenia"
                              className="peer-focus:font-medium flex absolute text-sm font-medium  text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:start-1 rtl:peer-focus:translate-x-1/4 peer-focus:text-red-600 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
                            >{" "}Contraseña{" "}
                            </label>
                            <input
                              type={showPassword ? "text" : "password"}
                              name="contrasenia"
                              id="contrasenia"
                              minLength={8}
                              maxLength={12}
                              value={contactInfo.contrasenia}
                              onChange={handleChange}
                              className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:focus:border-red-500 focus:outline-none focus:ring-1 focus:border-red-600 peer"
                              placeholder=" "
                              required
                            />{" "}

                            <button
                              type="button"
                              onClick={togglePasswordVisibility}
                              className="absolute right-0 top-3 px-2"
                            >
                              <box-icon
                                type="solid"
                                name={showPassword ? "show" : "hide"}
                                color="gray"
                              ></box-icon>
                            </button>
                          </div>
                          <div className="relative z-0 w-full mb-5 group">
                            <label
                              htmlFor="confirmPassword"
                              className="peer-focus:font-medium flex absolute text-sm font-medium text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:start-1 rtl:peer-focus:translate-x-1/4 peer-focus:text-red-600 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
                            >
                              {" "}Confirmar contraseña{" "}
                            </label>
                            <input
                              type={showPassword ? "text" : "password"}
                              name="confirmPassword"
                              id="confirmPassword"
                              minLength={8}
                              maxLength={12}
                              value={contactInfo.confirmPassword}
                              onChange={handleChange}
                              className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:focus:border-red-500 focus:outline-none focus:ring-1 focus:border-red-600 peer"
                              placeholder=" "
                              required
                            />{" "}

                            <button
                              type="button"
                              onClick={togglePasswordVisibility}
                              className="absolute right-0 top-3 px-2"
                            >
                              <box-icon
                                type="solid"
                                name={showPassword ? "show" : "hide"}
                                color="gray"
                              ></box-icon>
                            </button>

                            {" "}
                            {passwordsMatch && (
                              <span className="text-red-500 text-xs">* Las contraseñas no coinciden, minimo 8 y maximo 12 caracteres</span>
                            )}{" "}

                          </div>

                          <button
                            onClick={handleSubmit}
                            type="submit"
                            className="w-full mt-2 text-white btn-rojo
                             hover:bg-[#494544] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                          >
                            {" "}
                            Guardar{" "}
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 left-0 right-0">
        <FooterTransparent></FooterTransparent>
      </div>
    </section>
  );
}
export default Newsletter;